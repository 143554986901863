import { Action } from 'redux';
import { Input } from '../utils/formHelpers';
import { validators } from '../utils/validators';

interface ITermState {
    read: boolean;
    confirm: Input<boolean>;
}

interface IState {
    term1: ITermState;
    term2: ITermState;
    term3: ITermState;
    term4: ITermState;
}

export const initialState: IState = {
  term1: { read: true, confirm: validators.term(false, false) },
  term2: { read: true, confirm: validators.term(true, true) },
  term3: { read: true, confirm: validators.term(true, true) },
  term4: { read: true, confirm: validators.term(true, true) },
};

const CONFIRM = 'TERMS/CONFIRM';
const READ = 'TERMS/READ';

interface IConfirmAction extends Action {
    type: typeof CONFIRM;
    payload: {
        key: keyof IState;
        checked: boolean;
    };
}

interface IReadAction extends Action {
    type: typeof READ;
    payload: {
        key: keyof IState;
    };
}

export type TermsActions = IConfirmAction
    | IReadAction;

export default function reducer(state: IState = initialState, action: TermsActions): IState {
  switch (action.type) {
    case CONFIRM: {
      const { key, checked } = action.payload;
      return {
        ...state,
        [key]: {
          ...state[key],
          confirm: validators.term(checked, true),
        },
      };
    }

    case READ: {
      const { key } = action.payload;
      return {
        ...state,
        [key]: {
          ...state[key],
          read: true,
        },
      };
    }

    default:
      return state;
  }
}
