import React, { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  label: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 1),
  },
}));

export interface DropdownProps {
  label: string;
  options: string[];
  value: string;
  onChange: (val: string) => void;
  hasError: boolean;
  errors: string[];
  disabled?: boolean;
}

export default function Dropdown({
  label,
  options,
  value,
  onChange,
  hasError,
  errors,
  disabled = false,
}: DropdownProps) {
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string);
  };

  return (
    <FormControl
      variant="outlined"
      error={hasError}
      disabled={disabled}
      fullWidth
    >
      <InputLabel
        shrink
        required
        className={classes.label}
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        displayEmpty
      >
        <MenuItem value="" disabled>選択してください</MenuItem>
        {options.map((val) => (
          <MenuItem key={val} value={val}>{val}</MenuItem>
        ))}
      </Select>
      {hasError && (
        <FormHelperText>{errors[0]}</FormHelperText>
      )}
    </FormControl>
  );
}
