import { Action } from 'redux';
import { Input } from '../utils/formHelpers';
import { validators } from '../utils/validators';

interface IState {
    number: Input<string>;
    name: Input<string>;
    expiry: Input<string>;
    cvc: Input<string>;
}

export type CardKeys = keyof IState;

export const initialState: IState = {
  number: validators.cardNumber('', false),
  name: validators.cardName('', false),
  expiry: validators.cardExpiry('', false),
  cvc: validators.cardCvc('', false),
};

export const SET_CARD = 'CARD/SET_CARD';
export const REGISTER = 'CARD/REGISTER';

interface ISetCardAction extends Action {
    type: typeof SET_CARD;
    payload: Partial<{
        [T in keyof IState]: IState[T]['value'];
    }>;
}

interface IRegisterAction extends Action {
  type: typeof REGISTER;
}

export type CardActions = ISetCardAction | IRegisterAction;

export default function reducer(state: IState = initialState, action: CardActions): IState {
  switch (action.type) {
    case SET_CARD: {
      const {
        number,
        name,
        expiry,
        cvc,
      } = action.payload;

      return {
        ...state,

        number: number !== undefined
          ? validators.cardNumber(number, true) : state.number,

        name: name !== undefined
          ? validators.cardName(name, true) : state.name,

        expiry: expiry !== undefined
          ? validators.cardExpiry(expiry, true) : state.expiry,

        cvc: cvc !== undefined
          ? validators.cardCvc(cvc, true) : state.cvc,
      };
    }

    default:
      return state;
  }
}
