import { put, select } from 'redux-saga/effects';
import { AppState } from '../reducers';
import { PaymentMethod } from '../reducers/form';
import { ICardTokenParams, MultipaymentCallback } from '../utils/gmo';

function getCardTokenParams({ card }: AppState): ICardTokenParams {
  const {
    number: { value: cardno },
    name: { value: holdername },
    expiry: { value: expire },
    cvc: { value: securitycode },
  } = card;

  return {
    cardno,
    holdername,
    securitycode,
    expire: `20${expire.slice(3, 5)}${expire.slice(0, 2)}`,
  };
}

// NOTE: Multipayment.getToken のコールバックはグローバルに設定する
// productionでは関数名は圧縮されてしまうため、動的に設定している。
window[MultipaymentCallback.name as any] = MultipaymentCallback as any;

// eslint-disable-next-line import/prefer-default-export
export function* registerGMO() {
  const paymentMethod: PaymentMethod = yield select((state: AppState) => (
    state.form.paymentMethod
  ));

  if (paymentMethod === PaymentMethod.CreditCard) {
    yield put({ type: 'LOADING/START' });

    const params: ICardTokenParams = yield select(getCardTokenParams);
    window.Multipayment.getToken(params, MultipaymentCallback);
  } else {
    yield put({ type: 'LOADING/START' });
    yield put({ type: 'REGISTRATION/START_COMPLETION', payload: null });
  }
}
