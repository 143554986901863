import React, { useState, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import format from 'date-fns/format';
import TextInput from './TextInput';
import { Input, FormFilter, dateFilter } from '../utils/formHelpers';
import { isDate, toDate } from '../utils/date';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  textContainer: {
    flexGrow: 1,
  },
  iconButton: {
    flexGrow: 0,
  },
  calenderButton: {
    color: theme.palette.secondary.main,
  },
}));

export interface DateFormProps {
    date: Input<string>;
    label: string;
    placeholder: string;
    validated: boolean;
    onChange: (val: string) => void;
    name: string;
    pattern: DatePickerProps['format'];
    disableFuture?: DatePickerProps['disableFuture'];
    disablePast?: DatePickerProps['disablePast'];
    views?: DatePickerProps['views'];
    filter?: FormFilter;
    defaultDate?: Date;
}

export default function DateForm({
  date,
  label,
  placeholder,
  validated,
  onChange,
  name,
  pattern,
  disableFuture = false,
  disablePast = false,
  views = ['year', 'month', 'date'],
  filter = dateFilter,
  defaultDate = new Date(),
}: DateFormProps) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const hasError = (date.dirty || validated) && date.errors.length > 0;
  const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    onChange(filter(value));
  };
  const handleChangeCalender = (value: Date | null) => {
    const strVal = value === null ? '' : format(value, pattern || 'yyyy/M/d');
    onChange(strVal);
  };

  const handleOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <TextInput
          required
          fullWidth
          label={label}
          placeholder={placeholder}
          value={date.value}
          onChange={handleChangeText}
          name={name}
          error={hasError}
          helperText={hasError ? date.errors[0] : '右ボタンからでも設定可'}
        />
      </div>
      <IconButton
        className={classes.iconButton}
        aria-label="calender"
        onClick={handleOpen}
      >
        <DateRangeIcon
          className={classes.calenderButton}
          fontSize="large"
        />
      </IconButton>
      <DatePicker
        open={open}
        onClose={handleClose}
        disableFuture={disableFuture}
        disablePast={disablePast}
        openTo="year"
        views={views}
        autoOk
        cancelLabel="キャンセル"
        value={isDate(date.value) ? toDate(date.value) : defaultDate}
        onChange={handleChangeCalender}
        TextFieldComponent={() => null}
      />
    </div>
  );
}
