import { combineReducers } from 'redux';
import modeReducer, { ModeActions, initialState as modeInitState } from './mode';
import stepsReducer, { StepsActions, initialState as stepsInitState } from './steps';
import termsReducer, { TermsActions, initialState as termsInitState } from './terms';
import formReducer, { FormActions, initialState as formInitState } from './form';
import cardReducer, { CardActions, initialState as cardInitState } from './card';
import alertReducer, { AlertActions, initialState as alertInitState } from './alert';
import registrationReducer, {
  RegistrationActions,
  initialState as registrationInitState,
} from './registration';
import loadingReducer, { LoadingActions } from './loading';
import addressesReducer, {
  AddressesActions,
  initialState as addressesInitState,
} from './addresses';
import emailConfirmationReducer, {
  EmailConfirmationActions,
  initialState as emailConfirmationInitState,
} from './emailConfirmation';

export const rootReducer = combineReducers({
  mode: modeReducer,
  steps: stepsReducer,
  terms: termsReducer,
  form: formReducer,
  card: cardReducer,
  loading: loadingReducer,
  alert: alertReducer,
  registration: registrationReducer,
  addresses: addressesReducer,
  emailConfirmation: emailConfirmationReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppActions = ModeActions
  | StepsActions
  | TermsActions
  | FormActions
  | CardActions
  | RegistrationActions
  | LoadingActions
  | AlertActions
  | AddressesActions
  | EmailConfirmationActions;

export type AppAction<T, S> = T extends { type: S } ? T : never;

export const initialState: AppState = {
  mode: modeInitState,
  steps: stepsInitState,
  terms: termsInitState,
  form: formInitState,
  card: cardInitState,
  loading: false,
  alert: alertInitState,
  registration: registrationInitState,
  addresses: addressesInitState,
  emailConfirmation: emailConfirmationInitState,
};
