import { AppState } from '../reducers';
import { IRegistrationParams } from '../reducers/registration';
import { formFormat } from './date';

// eslint-disable-next-line import/prefer-default-export
export function mapInputsToParams({
  form: {
    holder,
    agent,
    device,
    plan,
    announce,
  },
}: AppState): IRegistrationParams {
  return {
    holder: {
      firstName: holder.firstName.value,
      lastName: holder.lastName.value,
      zipcode: holder.zipcode.value,
      prefecture: holder.prefecture.value,
      address1: holder.address1.value,
      address2: holder.address2.value,
      phone1: holder.phone1.value,
      phone2: holder.phone2.value,
      email: holder.email.value,
      emailConfirmation: holder.emailConfirmation.value,
      kanaFirstName: holder.kanaFirstName.value,
      kanaLastName: holder.kanaLastName.value,
      gender: holder.gender.value,
      birthday: formFormat(holder.birthday.value),
    },
    agent: {
      agencyCode: agent.agencyCode.value,
      agentCode: agent.agentCode.value,
    },
    device: {
      maker: device.maker.value,
      brand: device.brand.value,
      model: device.model.value,
      carrier: device.carrier.value,
      imei: device.imei.value,
      price: device.price.value,
      imageFront: device.imageFront.value,
      imageBack: device.imageBack.value,
    },
    plan,
    announce: {
      work: announce.work.value,
      damaged: announce.damaged.value,
      remark: announce.remark.value,
    },
  };
}
