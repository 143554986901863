import {
  createStore,
  compose,
  StoreEnhancer,
  applyMiddleware,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { registerGMOModule } from './utils/gmo';
import { rootSaga } from './sagas';
import {
  rootReducer,
  AppState,
  AppActions,
  initialState,
} from './reducers';

export function configureStore<T = {}, S = {}>(
  initState: AppState = initialState,
  enhancers: StoreEnhancer[] = [],
) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [applyMiddleware(sagaMiddleware), ...enhancers];

  const store = createStore<AppState, AppActions, T, S>(
    rootReducer,
    initState,
    compose(...middlewares),
  );

  sagaMiddleware.run(rootSaga);

  registerGMOModule(store);

  return store;
}

export type StoreExtension<T> = T extends StoreEnhancer<infer U, {}> ? U : never;
