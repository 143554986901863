import React, { useState, useEffect, ChangeEvent } from 'react';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from './Checkbox';
import TextInput from './TextInput';
import { AppState, AppActions } from '../reducers';
import { IAnnouce } from '../reducers/form';

export type AnnouncePayload = Partial<{
  [T in keyof IAnnouce]: IAnnouce[T]['value'];
}>;

export type MapStateToProps = AppState['form']['announce'];

export type MapDispatchToProps = {
  setAnnounce: (params: AnnouncePayload) => AppActions;
};

export interface AnnounceFormProps extends MapStateToProps, MapDispatchToProps {
  validated: boolean;
}

export default function AnnounceForm({
  work,
  damaged,
  remark,
  validated,
  setAnnounce,
}: AnnounceFormProps) {
  const [remarkHasError, setRemarkHasError] = useState(false);
  const [remarkErrors, setRemarkErrors] = useState<string[]>([]);
  const handleCheckWork = (checked: boolean) => {
    setAnnounce({ work: checked });
  };

  const handleCheckDamaged = (checked: boolean) => {
    setAnnounce({ damaged: checked });
  };

  const handleChangeRemark = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setAnnounce({ remark: event.target.value });
  };

  useEffect(() => {
    if (validated) {
      const errors = !damaged.value && remark.value.trim().length === 0
        ? ['損傷の詳細を記載してください', ...remark.errors] : remark.errors;

      setRemarkHasError(errors.length > 0);
      setRemarkErrors(errors);
    }
  }, [validated, damaged, remark]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Checkbox
          label="端末は正常に動作します"
          name="device-work"
          checked={work.value}
          onChecked={handleCheckWork}
          validated={validated}
          errors={work.errors}
        />
      </Grid>
      <Grid item xs={12}>
        <Checkbox
          label="端末に損傷はありません"
          name="device-damaged"
          checked={damaged.value}
          onChecked={handleCheckDamaged}
          validated={validated}
          errors={damaged.errors}
        />
      </Grid>
      <Grid item xs={12}>
        <Collapse in={!damaged.value}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                required
                label="端末の状態"
                fullWidth
                multiline
                value={remark.value}
                onChange={handleChangeRemark}
                helperText={remarkHasError ? remarkErrors[0] : undefined}
                error={remarkHasError}
              />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
}
