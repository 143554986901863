import React, { useState, useEffect, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextInput from '../components/TextInput';
import Instruction from '../components/Instruction';
import StepButtons from '../components/StepButtons';
import { AppState, AppActions } from '../reducers';
import { StepBase } from './common';

const useStyles = makeStyles((theme) => ({
  pragraph: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
}));

export type MapStateToProps = {
  loading: AppState['loading'];
  email: string;
  confirmed: string;
};

export type MapDispatchToProps = {
  back: () => AppActions;
  next: () => AppActions;
  sendEmail: () => AppActions;
  confirm: (code: string) => AppActions;
};

export interface ConfirmationProps extends MapStateToProps, MapDispatchToProps, StepBase {}

export default function Confirmation({
  email,
  confirmed,
  firstStep,
  lastStep,
  loading,
  back,
  next,
  sendEmail,
  confirm,
}: ConfirmationProps) {
  const classes = useStyles();
  const [code, setCode] = useState('');
  const [validated, setValidated] = useState(false);
  const handleChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const hasError = validated && code.length === 0;

  const handleClickNext = () => {
    setValidated(true);

    if (email === confirmed) {
      next();
    } else if (code.length !== 0) {
      confirm(code);
    }
  };

  useEffect(() => {
    if (email !== confirmed) {
      sendEmail();
    }
  }, [email, confirmed, sendEmail]);

  return (
    <>
      <Grid container spacing={3}>
        {email === confirmed ? (
          <Grid item xs={12}>
            <Instruction>
              次のステップへお進みください
            </Instruction>
            <Typography
              className={classes.pragraph}
              component="p"
              variant="body2"
              color="textSecondary"
            >
              ご入力いただいたメールアドレスの確認は完了しております。
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Instruction>
              メールに含まれる確認コードを入力してください
            </Instruction>
            <Typography
              className={classes.pragraph}
              component="p"
              variant="body2"
              color="textSecondary"
            >
              ご入力いただいたメールアドレスに確認コードを含むメールを送信しました。メールに記載されている確認コードを入力してください。
            </Typography>
            <Typography paragraph align="center">
              メールアドレス:&nbsp;
              <Typography gutterBottom component="em">
                {email}
              </Typography>
            </Typography>
            <Grid item container xs={12} alignItems="center" justify="center">
              <Grid item xs={6} md={4}>
                <TextInput
                  required
                  label="確認コード"
                  fullWidth
                  value={code}
                  onChange={handleChangeCode}
                  disabled={loading}
                  error={hasError}
                  helperText={hasError ? '入力してください' : undefined}
                />
              </Grid>
            </Grid>
            <Typography
              className={classes.pragraph}
              component="p"
              variant="body2"
              color="textSecondary"
            >
              メールが届かない場合、または確認コードの有効期限が切れている場合は以下のボタンからメールを再送してください。
            </Typography>
            <Typography paragraph align="center">
              <Button
                variant="outlined"
                size="large"
                onClick={sendEmail}
                disabled={loading}
              >
                メールを再送する
              </Button>
            </Typography>
          </Grid>
        )}
      </Grid>
      <StepButtons
        onClickBack={back}
        onClickNext={handleClickNext}
        firstStep={firstStep}
        lastStep={lastStep}
        disableBack={loading}
        disableNext={loading}
      />
    </>
  );
}
