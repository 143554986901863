import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  title: {
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    color: theme.palette.primary.main,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontWeight: 'bold',
  },
}));

interface IProps {
    children: String | JSX.Element | JSX.Element[];
}

export default function Instruction({ children }: IProps) {
  const classes = useStyles();

  return (
    <Typography
      className={classes.title}
      component="h3"
      variant="h6"
      gutterBottom
    >
      {children}
    </Typography>
  );
}
