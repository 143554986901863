import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export interface StepButtonsProps {
    onClickBack: () => void;
    onClickNext: () => void;
    firstStep?: boolean;
    lastStep?: boolean;
    disableBack?: boolean;
    disableNext?: boolean;
}

export default function StepButtons({
  onClickBack,
  onClickNext,
  firstStep = false,
  lastStep = false,
  disableBack = false,
  disableNext = false,
}: StepButtonsProps) {
  const classes = useStyles();

  return (
    <div className={classes.buttons}>
      {!firstStep && (
        <Button
          onClick={onClickBack}
          variant="outlined"
          size="large"
          disabled={disableBack}
          className={classes.button}
        >
          戻る
        </Button>
      )}
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={onClickNext}
        disabled={disableNext}
        className={classes.button}
      >
        {lastStep ? '申込む' : '次へ'}
      </Button>
    </div>
  );
}
