import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextInput from './TextInput';
import {
  Input,
  FormFilter,
  OnFormChanged,
  hankakuFilter,
  handleChangeFactory,
} from '../utils/formHelpers';

export interface MailFormProps {
    email: Input<string>;
    emailConfirmation: Input<string>;
    validated: boolean;
    namePrefix: string;
    onChange: OnFormChanged;
    filter?: FormFilter;
    onChangeFactory?: typeof handleChangeFactory;
}

export default function MailForm({
  email,
  emailConfirmation,
  onChange,
  validated,
  namePrefix,
  filter = hankakuFilter,
  onChangeFactory = handleChangeFactory,
}: MailFormProps) {
  const handleChange = onChangeFactory(onChange);
  const hasError: (input: Input<string>) => boolean = (input) => (
    (input.dirty || validated) && input.errors.length > 0
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TextInput
          type="email"
          required
          label="メールアドレス"
          fullWidth
          name={`${namePrefix}-email`}
          value={email.value}
          onChange={handleChange('email', filter)}
          error={hasError(email)}
          helperText={hasError(email) ? email.errors[0] : undefined}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          type="email"
          required
          label="メールアドレス（確認）"
          fullWidth
          name={`${namePrefix}-emailConfirmation`}
          value={emailConfirmation.value}
          onChange={handleChange('emailConfirmation', filter)}
          error={hasError(emailConfirmation)}
          helperText={hasError(emailConfirmation)
            ? emailConfirmation.errors[0] : undefined}
        />
      </Grid>
    </Grid>
  );
}
