import React, { useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Link from '@material-ui/core/Link';
import Affiliate from '../../containers/components/Affiliate';
import { sendAffiliate } from '../../components/Affiliate';
import { HOME_SITE } from '../../constants';
import { StepContainerProps } from '../common';
import { a8Send } from '../../utils/a8';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  contractNo: {
    fontWeight: 'bold',
  },
  homeLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function MobileSteps({
  activeStep,
  contractNo,
  plan,
  steps,
  stepContent,
}: StepContainerProps) {
  const classes = useStyles();

  const isLastStep = useCallback(
    () => activeStep === steps.length,
    [activeStep, steps],
  );

  useEffect(() => {
    if (contractNo && isLastStep()) {
      a8Send(plan, contractNo);
    }
  }, [isLastStep, contractNo, plan]);

  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent
              TransitionProps={{
                timeout: 300,
                onEntering: () => {
                  const anchor = document.querySelectorAll('.MuiStepLabel-root')[index - 1];
                  if (anchor) {
                    anchor.scrollIntoView({ block: 'center' });
                  }
                },
              }}
            >
              {stepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {isLastStep() && (
      <Container>
        <Typography
          variant="h5"
          paragraph
          align="center"
          gutterBottom
          className={classes.title}
          id="complete-registration"
        >
          申込み完了
        </Typography>
        <Typography paragraph>
          お申込みいただきありがとうございました。ただ今より審査を開始いたします。
        </Typography>
        <Typography paragraph>
          審査終了後、結果を保険契約者様のメールアドレス宛にお知らせいたします。
          3営業日を経過しても連絡がない場合やご不明な点がございましたら、お手数ですが弊社HPよりお問い合わせください。
        </Typography>
        <Typography paragraph>
          ご連絡の際は以下のお申込番号をお伝えください。
        </Typography>
        <Typography paragraph align="center">
          お申込番号:&nbsp;
          <Typography gutterBottom component="em" className={classes.contractNo}>
            {contractNo}
          </Typography>
        </Typography>
        <Typography paragraph align="center">
          <Link
            href={HOME_SITE.TOP}
            rel="noreferrer"
            variant="body1"
            className={classes.homeLink}
          >
            <ArrowForwardIosIcon />
            スマホの保険証トップページへ
          </Link>
        </Typography>
        {sendAffiliate && contractNo && <Affiliate contractNo={contractNo} />}
      </Container>
      )}
    </>
  );
}
