import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

export default function TextInput(props: TextFieldProps) {
  return (
    <TextField
      {...props}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
    />
  );
}
