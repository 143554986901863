import React, { useState, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Dropdown from './Dropdown';
import TextInput from './TextInput';
import UploadForm from './UploadForm';
import { AppState, AppActions } from '../reducers';
import {
  carriers,
  deviceMakers,
  deviceBrands,
  deviceModels,
} from '../gear';
import {
  Input,
  deviceIMEIFilter,
  devicePriceFilter,
} from '../utils/formHelpers';

const useStyles = makeStyles((theme) => ({
  priceLabel: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 1),
  },
  fileInput: {
    display: 'none',
  },
  image: {
    maxWidth: '100%',
    display: 'block',
  },
}));

type IDevice = AppState['form']['device'];

type SetDevicePayload = Partial<{
    [T in keyof IDevice]: IDevice[T]['value'];
}>;

export type MapStateToProps = IDevice;

export type MapDispatchToProps = {
  setDevice: (payload: SetDevicePayload) => AppActions;
  showErrAlert: (title: string, messages: string[]) => AppActions;
};

export interface DeviceFormProps extends MapStateToProps, MapDispatchToProps {
  direct: boolean;
  validated: boolean;
}

const TEN_MEGA_BYTES = 10 * 1024 * 1024;

export default function DeviceForm({
  maker,
  brand,
  model,
  carrier,
  price,
  imei,
  imageFront,
  imageBack,
  setDevice,
  direct,
  validated,
  showErrAlert,
}: DeviceFormProps) {
  const classes = useStyles();
  const makers = deviceMakers;

  const hasError: (input: Input<string>) => boolean = (input) => (
    (input.dirty || validated) && input.errors.length > 0
  );

  const defaultBrands = maker.value !== '' && makers.includes(maker.value)
    ? deviceBrands(maker.value) : [];

  const defaultModels = brand.value !== '' && defaultBrands.includes(brand.value)
    ? deviceModels(maker.value, brand.value) : [];

  const [brands, setBrands] = useState<string[]>(defaultBrands);
  const [models, setModels] = useState<string[]>(defaultModels);

  const handleChangeMaker = (selected: string) => {
    if (selected !== maker.value) {
      // MEMO: optionを更新
      setBrands(deviceBrands(selected));
      setModels([]);

      // MEMO: stateはリセット
      setDevice({ maker: selected, brand: '', model: '' });
    }
  };

  const handleChangeBrand = (selected: string) => {
    if (selected !== brand.value) {
      // MEMO: optionを更新
      setModels(deviceModels(maker.value, selected));

      // MEMO: stateはリセット
      setDevice({ brand: selected, model: '' });
    }
  };

  const handleChangeModel = (selected: string) => {
    setDevice({ model: selected });
  };

  const handleChangeCarrier = (selected: string) => {
    setDevice({ carrier: selected });
  };

  const handleChangePrice = (event: ChangeEvent<{ value: unknown }>) => {
    const value = devicePriceFilter(event.target.value as string);
    if (value === '') {
      setDevice({ price: '' });
      return;
    }

    const newPrice = Number.parseInt(value, 10);
    if (!Number.isNaN(newPrice)) {
      setDevice({ price: newPrice.toString() });
    }
  };

  const handleChangeIMEI = (event: ChangeEvent<{ value: unknown }>) => {
    const newIMEI = deviceIMEIFilter(event.target.value as string);
    setDevice({ imei: newIMEI });
  };

  const handleChangeImageFront = (key: string) => {
    setDevice({ imageFront: key });
  };

  const handleChangeImageBack = (key: string) => {
    setDevice({ imageBack: key });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Dropdown
          label="メーカー"
          options={makers}
          value={maker.value}
          onChange={handleChangeMaker}
          hasError={maker.errors.length > 0 && validated}
          errors={maker.errors}
          disabled={false}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Dropdown
          label="ブランド"
          options={brands}
          value={brand.value}
          onChange={handleChangeBrand}
          hasError={brand.errors.length > 0 && validated}
          errors={brand.errors}
          disabled={brands.length === 0}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Dropdown
          label="機種名"
          options={models}
          value={model.value}
          onChange={handleChangeModel}
          hasError={model.errors.length > 0 && validated}
          errors={model.errors}
          disabled={models.length === 0}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Dropdown
          label="キャリア"
          options={carriers}
          value={carrier.value}
          onChange={handleChangeCarrier}
          hasError={carrier.errors.length > 0 && validated}
          errors={carrier.errors}
          disabled={false}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          required
          label="IMEI番号"
          placeholder="123456789012345"
          fullWidth
          value={imei.value}
          onChange={handleChangeIMEI}
          helperText={hasError(imei) ? imei.errors[0] : undefined}
          error={hasError(imei)}
          inputProps={{ inputMode: 'numeric' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          fullWidth
          error={hasError(price)}
          variant="outlined"
        >
          <InputLabel
            shrink
            className={classes.priceLabel}
          >
            購入金額
          </InputLabel>
          <OutlinedInput
            fullWidth
            placeholder="10000"
            value={price.value}
            onChange={handleChangePrice}
            endAdornment={<InputAdornment position="end">円</InputAdornment>}
            error={hasError(price)}
            inputProps={{ style: { textAlign: 'right' }, inputMode: 'numeric' }}
          />
          {hasError(price) && (
            <FormHelperText>{price.errors[0]}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      {direct && (
        <>
          <Grid item xs={12}>
            <Typography variant="body1">
              審査に必要となりますので、登録端末のIMEI番号を画面に映して画像のアップロードをお願いします
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <UploadForm
              label="端末画像（表）をアップロード"
              htmlFor="device-image-front"
              imageKey={imageFront.value}
              limitMB={TEN_MEGA_BYTES}
              setImageKey={handleChangeImageFront}
              showErrAlert={showErrAlert}
              validated={validated}
              errors={imageFront.errors}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <UploadForm
              label="端末画像（裏）をアップロード"
              htmlFor="device-image-back"
              imageKey={imageBack.value}
              limitMB={TEN_MEGA_BYTES}
              setImageKey={handleChangeImageBack}
              showErrAlert={showErrAlert}
              validated={validated}
              errors={imageBack.errors}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
