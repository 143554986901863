import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Loading from './containers/components/Loading';
import Toast from './containers/components/Toast';
import ErrorBoundary from './ErrorBoundary';
import Routes from './Routes';
import withLayout from './hocs/withLayout';
import Maintenance from './pages/Maintenance';

const MaintenancePage = withLayout(Maintenance);

export default function App() {
  return (
    <ErrorBoundary>
      <CssBaseline />
      {process.env.REACT_APP_MAINTENANCE_MODE === 'ON' ? (
        <MaintenancePage />
      ) : (
        <Routes />
      )}
      <Loading />
      <Toast />
    </ErrorBoundary>
  );
}
