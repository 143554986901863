import { put, call, select } from 'redux-saga/effects';
import { API } from 'aws-amplify';
import { AppState, AppActions, AppAction } from '../reducers';
import { Severity } from '../reducers/alert';

function sendEmail(email: string): Promise<void> {
  const apiName = 'GyaraEntry';
  const path = '/sendEmail';
  const init = {
    headers: { 'Content-Type': 'application/json' },
    body: { email },
  };

  return API.post(apiName, path, init);
}

function confirmEmail(email: string, code: string): Promise<void> {
  const apiName = 'GyaraEntry';
  const path = '/confirmEmail';
  const init = {
    headers: { 'Content-Type': 'application/json' },
    body: { email, code },
  };

  return API.post(apiName, path, init);
}

function holderEmail({ form }: AppState): string {
  const { holder: { email: { value } } } = form;
  return value;
}

export function* sendConfirmationEmail() {
  const email: string = yield select(holderEmail);

  yield put({ type: 'LOADING/START' });

  try {
    yield call(sendEmail, email);
  } catch (err) {
    yield put({
      type: 'ALERT/SHOW',
      payload: {
        severity: Severity.Error,
        title: 'メール送信に失敗しました。',
        messages: ['入力されたメールアドレスをご確認ください。'],
      },
    });
  } finally {
    yield put({ type: 'LOADING/FINISH' });
  }
}

type ConfirmEmailParams = AppAction<AppActions, 'EMAIL_CONFIRMATION/CONFIRM_EMAIL'>;

export function* confirm(params: ConfirmEmailParams) {
  const { payload: code } = params;
  const email: string = yield select(holderEmail);

  yield put({ type: 'LOADING/START' });

  try {
    yield call(confirmEmail, email, code);
    yield put({ type: 'EMAIL_CONFIRMATION/SET_VALUE', payload: email });
    yield put({ type: 'STEPS/NEXT' });
  } catch (err: any) {
    const messages = err.response?.data?.messages
      || ['入力された内容をご確認のうえ、やり直してください。'];

    yield put({
      type: 'ALERT/SHOW',
      payload: {
        severity: Severity.Error,
        title: 'メールアドレスの確認に失敗しました。',
        messages,
      },
    });
  } finally {
    yield put({ type: 'LOADING/FINISH' });
  }
}
