import React, { ChangeEvent } from 'react';
import Grid from '@material-ui/core/Grid';
import TextInput from './TextInput';
import Dateform from './DateForm';
import { AppState } from '../reducers';
import {
  Input,
  FormFilter,
  cardNumberFilter,
  cardNameFilter,
  cardCvcFilter,
} from '../utils/formHelpers';

type CardState = AppState['card'];
type CreditCardKeys = keyof CardState;

export interface CardFormProps extends CardState {
    numberFilter?: FormFilter;
    nameFilter?: FormFilter;
    cvcFilter?: FormFilter;
    onChangeText: (key: CreditCardKeys, value: string) => void;
    onFocus: (key: CreditCardKeys) => void;
    validated: boolean;
}

export default function CardForm({
  number,
  name,
  expiry,
  cvc,
  onChangeText,
  onFocus,
  validated,
  numberFilter = cardNumberFilter,
  nameFilter = cardNameFilter,
  cvcFilter = cardCvcFilter,
}: CardFormProps) {
  const handleChangeText = (key: CreditCardKeys, filter: FormFilter) => (
    (event: ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = event;
      onChangeText(key, filter(value));
    }
  );
  const handleChangeExpiry = (val: string) => {
    onChangeText('expiry', val);
  };

  const handleChangeFocus = (key: CreditCardKeys) => () => {
    onFocus(key);
  };

  const hasError: (input: Input<string>) => boolean = (input) => (
    (input.dirty || validated) && input.errors.length > 0
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextInput
          required
          label="カード番号"
          fullWidth
          placeholder="1234123412341234"
          inputProps={{
            type: 'tel',
            inputmode: 'numberic',
            pattern: '[0-9\\s]{13,19}',
            autocomplete: 'cc-number',
            maxlength: '19',
          }}
          value={number.value}
          onChange={handleChangeText('number', numberFilter)}
          onFocus={handleChangeFocus('number')}
          error={hasError(number)}
          helperText={hasError(number) ? number.errors[0] : undefined}
          name="creditcard-number"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          required
          label="カード名義人"
          fullWidth
          placeholder="HAGURUMA TARO"
          value={name.value}
          onChange={handleChangeText('name', nameFilter)}
          onFocus={handleChangeFocus('name')}
          error={hasError(name)}
          helperText={hasError(name) ? name.errors[0] : undefined}
          name="creditcard-name"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Dateform
          date={expiry}
          label="有効期限"
          placeholder="MM/YY"
          validated={validated}
          onChange={handleChangeExpiry}
          pattern="MM/yy"
          disablePast
          views={['year', 'month']}
          name="creditcard-expiry"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          required
          label="CVC"
          fullWidth
          placeholder="XXX"
          value={cvc.value}
          onChange={handleChangeText('cvc', cvcFilter)}
          onFocus={handleChangeFocus('cvc')}
          error={hasError(cvc)}
          helperText={hasError(cvc) ? cvc.errors[0] : undefined}
          name="creditcard-cvc"
        />
      </Grid>
    </Grid>
  );
}
