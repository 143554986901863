import React, { SyntheticEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';
import { AppState, AppActions } from '../reducers';

const useStyles = makeStyles((theme) => ({
  toast: {
    minWidth: '50vw',
  },
  message: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export type MapStateToProps = AppState['alert'];

export type MapDispatchToProps = {
    hide: () => AppActions;
};

export interface ToastProps extends MapStateToProps, MapDispatchToProps {}

export default function Toast({
  show,
  severity,
  title,
  messages,
  hide,
}: ToastProps) {
  const classes = useStyles();

  const handleClose = (_event?: SyntheticEvent, reason?: string) => {
    if (reason !== 'clickaway') {
      hide();
    }
  };

  return (
    <Snackbar
      open={show}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={7000}
      onClose={handleClose}
    >
      <div className={classes.toast}>
        <Alert severity={severity || 'error'} onClose={hide}>
          <AlertTitle>{title}</AlertTitle>
          {messages.map((msg) => (
            <Typography key={msg} className={classes.message} variant="body2">
              {msg}
            </Typography>
          ))}
        </Alert>
      </div>
    </Snackbar>
  );
}
