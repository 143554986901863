import { useLocation } from 'react-router-dom';
import qs from 'qs';

export interface AgentCodes {
  agencyCode: string;
  agentCode: string;
}

export type Location = ReturnType<typeof useLocation>;

// eslint-disable-next-line import/prefer-default-export
export function getAgentCodes(location: Location): AgentCodes {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const codes = {
    agencyCode: '',
    agentCode: '',
  };
  if ('agencyCode' in query && typeof query.agencyCode === 'string') {
    codes.agencyCode = query.agencyCode;
  }
  if ('agentCode' in query && typeof query.agentCode === 'string') {
    codes.agentCode = query.agentCode;
  }
  return codes;
}
