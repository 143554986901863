import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { HOME_SITE } from '../constants';

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100vw',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginTop: theme.spacing(5),
    padding: theme.spacing(2, 0, 2),
    flexGrow: 0,
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Typography variant="body2" color="inherit" align="center">
        Copyright ©
        {' '}
        <Link
          color="inherit"
          href={HOME_SITE.TOP}
          target="_blank"
          rel="noreferrer"
        >
          スマホの保険証
        </Link>
        {' '}
        {new Date().getFullYear()}
        {' '}
        All Rights Reserved.
      </Typography>
    </footer>
  );
}
