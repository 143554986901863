import React from 'react';
import Grid from '@material-ui/core/Grid';
import AddressForm from './AddressForm';
import NameForm from './NameForm';
import DateForm from './DateForm';
import GenderForm from './GenderForm';
import MailForm from './MailForm';
import PhoneForm from './PhoneForm';
import { OnFormChanged } from '../utils/formHelpers';
import { AppState, AppActions } from '../reducers';
import { Gender } from '../reducers/form';
import { defaultBirthday } from '../utils/date';

type IHolder = AppState['form']['holder'];

type SetHolderPayload = Partial<{
    [T in keyof IHolder]: IHolder[T]['value'];
}>;

export type MapStateToProps = IHolder & {
    loading: AppState['loading'],
};

export type MapDispatchToProps = {
    fetchAddress: (zipcode: string) => AppActions;
    setHolder: (payload: SetHolderPayload) => AppActions;
};

export interface HolderFormProps extends MapStateToProps, MapDispatchToProps {
  validated: boolean;
}

export default function HolderForm({
  firstName,
  lastName,
  kanaFirstName,
  kanaLastName,
  zipcode,
  prefecture,
  address1,
  address2,
  gender,
  birthday,
  phone1,
  phone2,
  email,
  emailConfirmation,
  fetchAddress,
  setHolder,
  loading,
  validated,
}: HolderFormProps) {
  const handleChangeText: OnFormChanged = (key, val) => {
    setHolder({ [key]: val });
  };
  const handleChangeBirthday = (val: string) => {
    setHolder({ birthday: val });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <NameForm
          firstName={firstName}
          lastName={lastName}
          kanaFirstName={kanaFirstName}
          kanaLastName={kanaLastName}
          onChange={handleChangeText}
          validated={validated}
          namePrefix="holder"
        />
      </Grid>
      <Grid item sm={12} md={5}>
        <DateForm
          date={birthday}
          disableFuture
          label="生年月日"
          placeholder="1990/1/1"
          pattern="yyyy/M/d"
          validated={validated}
          onChange={handleChangeBirthday}
          name="holder-birthday"
          defaultDate={defaultBirthday()}
        />
      </Grid>
      <Grid item sm={12} md={7}>
        <GenderForm
          genders={[Gender.Male, Gender.FeMale, Gender.Other]}
          gender={gender}
          validated={validated}
          onChange={handleChangeText}
          name="holder-gender"
        />
      </Grid>
      <Grid item xs={12}>
        <AddressForm
          zipcode={zipcode}
          prefecture={prefecture}
          address1={address1}
          address2={address2}
          onChange={handleChangeText}
          fetchAddress={fetchAddress}
          disabled={loading}
          validated={validated}
          namePrefix="holder"
        />
      </Grid>
      <Grid item xs={12}>
        <MailForm
          email={email}
          emailConfirmation={emailConfirmation}
          validated={validated}
          onChange={handleChangeText}
          namePrefix="holder"
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneForm
          phone1={phone1}
          phone2={phone2}
          validated={validated}
          onChange={handleChangeText}
          namePrefix="holder"
        />
      </Grid>
    </Grid>
  );
}
