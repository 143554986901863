import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Link from '@material-ui/core/Link';
import RouterLink from '../components/RouterLink';
import { HOME_SITE } from '../constants';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(5, 0),
    color: theme.palette.grey['900'],
    fontWeight: 'bold',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function NotFound() {
  const classes = useStyles();

  return (
    <Container>
      <Typography
        variant="h5"
        paragraph
        gutterBottom
        className={classes.title}
      >
        お探しのページは見つかりませんでした。
      </Typography>
      <Typography paragraph>
        以下のページをお探しでしょうか？
      </Typography>
      <Typography paragraph>
        <Link
          href={HOME_SITE.TOP}
          rel="noreferrer"
          className={classes.link}
        >
          <ArrowForwardIosIcon />
          スマホの保険証ホームページはこちら
        </Link>
      </Typography>
      <Typography paragraph>
        <Link
          component={RouterLink}
          className={classes.link}
          to="/"
        >
          <ArrowForwardIosIcon />
          スマホの保険証の申し込み手続きはこちら
        </Link>
      </Typography>
    </Container>
  );
}
