import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import indigo from '@material-ui/core/colors/indigo';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import jaLocale from 'date-fns/locale/ja';
import Amplify, { Storage } from 'aws-amplify';
import awsconfig from './aws-exports';
import App from './App';
import JaLocaleUtils from './locale';
import { configureStore } from './store';
import * as serviceWorker from './serviceWorker';

Amplify.configure(awsconfig);
Storage.configure({
  customPrefix: {
    public: `${process.env.REACT_APP_DOCUMENT_PATH}/`,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      ...indigo,
      main: '#006acc',
    },
    secondary: {
      ...blue,
      main: '#01c9f9',
    },
  },
  typography: {
    fontSize: 12,
  },
});

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={JaLocaleUtils} locale={jaLocale}>
            <App />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
