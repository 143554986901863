import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import Person from '../../components/SummaryPerson';

const mapStateToProps = ({ form: { holder } }: AppState) => {
  const {
    firstName: { value: firstName },
    lastName: { value: lastName },
    zipcode: { value: zipcode },
    prefecture: { value: prefecture },
    address1: { value: address1 },
    address2: { value: address2 },
    phone1: { value: phone1 },
    phone2: { value: phone2 },
    gender: { value: gender },
    birthday: { value: birthday },
    kanaFirstName: { value: kanaFirstName },
    kanaLastName: { value: kanaLastName },
    email: { value: email },
  } = holder;

  return {
    firstName,
    lastName,
    zipcode,
    prefecture,
    address1,
    address2,
    phone1,
    phone2,
    gender,
    birthday,
    kanaFirstName,
    kanaLastName,
    email,
  };
};

export default connect(mapStateToProps)(Person);
