export const carriers = [
  'Softbank',
  'docomo',
  'au',
  'Y!mobile',
  'UQmobile',
  'HISMobile',
  '楽天モバイル',
  'ahamo',
  'povo',
  'LINEMO',
  'その他',
  '回線なし',
];

interface Devices {
  [key: string]: {
    [key: string]: string[];
  };
}

const devices: Devices = {
  Apple: {
    iPhone: [
      'iPhone 14',
      'iPhone 14 Plus',
      'iPhone 14 Pro',
      'iPhone 14 Pro Max',
      'iPhone 13',
      'iPhone 13 Pro',
      'iPhone 13 Pro Max',
      'iPhone 13 mini',
      'iPhone 12',
      'iPhone 12 Pro',
      'iPhone 12 Pro Max',
      'iPhone 12 mini',
      'iPhone SE 第3世代',
      'iPhone SE 第2世代',
      'iPhone 11',
      'iPhone 11 Pro',
      'iPhone 11 Pro Max',
      'iPhone XR',
      'iPhone XS',
      'iPhone XS Max',
      'iPhone X',
      'iPhone 8',
      'iPhone 8 Plus',
      'iPhone 7',
      'iPhone 7 Plus',
      'iPhone SE',
      'iPhone 6s',
      'iPhone 6s Plus',
      'iPhone 6',
      'iPhone 6 Plus',
      'iPhone 5c',
      'iPhone 5s',
      'iPhone 5',
      'iPhone 4S',
      'iPhone 4',
      'iPhone 3GS',
      'iPhone 3G',
    ],
  },
  Acer: {
    Liquid: [
      'Liquid Jade Primo',
      'Liquid Z200',
      'Liquid Z330',
      'Liquid Z530',
    ],
  },
  ASUS: {
    ZenFone: [
      'ASUS Fonepad Note 6',
      'ZenFone',
      'ZenFone 2',
      'ZenFone 2 Laser',
      'ZenFone 2 Laser ZE601KL',
      'ZenFone 3',
      'ZenFone 3 Deluxe',
      'ZenFone 3 Deluxe ZS570KL',
      'ZenFone 3 Laser',
      'ZenFone 3 Max',
      'ZenFone 3 Max ZC553KL',
      'ZenFone 3 Ultra',
      'ZenFone 3 ZE552KL',
      'ZenFone 4',
      'ZenFone 4 Max',
      'ZenFone 4 Pro',
      'ZenFone 4 Selfie',
      'ZenFone 4 Selfie Pro',
      'ZenFone 4 カスタマイズモデル',
      'ZenFone 5',
      'ZenFone 5Q',
      'ZenFone 5Z',
      'ZenFone 6',
      'ZenFone 6 Edition 30',
      'ZenFone 7',
      'Zenfone 8',
      'Zenfone 8 Flip',
      'ZenFone AR',
      'ZenFone Go',
      'ZenFone Live',
      'ZenFone Live (L1)',
      'ZenFone Max',
      'ZenFone Max (M1)',
      'ZenFone Max (M2)',
      'ZenFone Max Plus (M1)',
      'ZenFone Max Pro (M1)',
      'ZenFone Max Pro (M2)',
      'ZenFone Selfie',
      'ZenFone Zoom',
      'ZenFone Zoom S',
      'ZenFone Zoom プレミアムレザー',
    ],
    その他: [
      'ROGPhone5sPro',
      'ROGPhone5s',
      'ROG Phone II',
      'ROG Phone',
    ],
  },
  'Black Shark': {
    'Black Shark': [
      'Black Shark 5 Pro',
      'Black Shark 5',
      'Black Shark 4',
      'Black Shark2',
    ],
  },
  BALMUDA: {
    'BALMUDA Phone': [
      'BALMUDA Phone',
    ],
  },
  BlackBerry: {
    BlackBerry: [
      'BlackBerry 8707h',
      'BlackBerry Bold',
      'BlackBerry Bold 9700',
      'BlackBerry Bold 9780',
      'BlackBerry Bold 9900',
      'BlackBerry Classic',
      'BlackBerry Curve 9300',
      'BlackBerry KEY2',
      'BlackBerry KEY2 Last Edition',
      'BlackBerry KEY2 RED EDITION',
      'BlackBerry KEYone',
      'BlackBerry Passport',
      'BlackBerry Priv',
    ],
  },
  BLU: {
    GRAND: [
      'GRAND M',
      'GRAND X LTE',
    ],
  },
  BLUEDOT: {
    BLUEDOT: [
      'BNP-500K',
    ],
  },
  CASIO: {
    "G'zOne": [
      "G'zOne TYPE-XX",
      "G'zOne TYPE-L",
      "G'zOne",
    ],
  },
  Caterpillar: {
    Sシリーズ: [
      'CAT S62Pro',
      'CAT S61',
      'CAT S60',
      'CAT S41',
      'CAT S40',
    ],
  },
  'Cathay Tri-Tech': {
    arp: [
      'arp AS01M',
      'arp XC01Q',
    ],
  },
  Covia: {
    FLEAZ: [
      'FLEAZ BEAT',
      'FLEAZ F4s',
      'FLEAZ F4s+',
      'FLEAZ F5',
      'FLEAZ NEO',
      'FLEAZ POP',
      'FLEAZ Que',
    ],
    gシリーズ: [
      'g07',
      'g07+',
      'g07++',
    ],
    その他: [
      'BREEZ X5',
      'FleaPhone CP-D02',
      'FleaPhone CP-F03a',
      'i-dio Phone',
      'Omni K5',
    ],
  },
  Crosslink: {
    gシリーズ: [
      'g06',
      'g06+',
    ],
  },
  DELL: {
    Streak: [
      'DELL Streak',
      'Dell Streak Pro',
    ],
  },
  Essential: {
    Essential: [
      'Essential Phone',
    ],
  },
  'F(x)tec': {
    'F(x)tec': [
      'F(x)tec Pro1',
    ],
  },
  FRONTIER: {
    FRONTIER: [
      'FR7101AK',
    ],
  },
  FCNT: {
    arrows: [
      'arrows We',
      'arrows 5G',
      'ARROWS A 101F',
      'ARROWS A 201F',
      'ARROWS A 202F',
      'ARROWS A 301F',
      'arrows Be F-04K',
      'arrows Be F-05J',
      'arrows Be3',
      'arrows Be4',
      'arrows Be4 Plus',
      'ARROWS ef',
      'ARROWS ES',
      'arrows Fit',
      'arrows J',
      'ARROWS Kiss F-03D',
      'ARROWS Kiss F-03E',
      'arrows M01',
      'arrows M02',
      'arrows M03',
      'arrows M04',
      'arrows M04 PREMIUM',
      'arrows M05',
      'ARROWS Me',
      'ARROWS NX F-01F',
      'arrows NX F-01J',
      'arrows NX F-01K',
      'ARROWS NX F-02G',
      'arrows NX F-02H',
      'ARROWS NX F-04G',
      'ARROWS NX F-05F',
      'ARROWS NX F-06E',
      'ARROWS NX9',
      'arrows RM02',
      'arrows RX',
      'ARROWS S EM01F',
      'arrows SV',
      'arrows U',
      'ARROWS V F-04E',
      'ARROWS X F-02E',
      'ARROWS X F-10D',
      'ARROWS X LTE',
      'ARROWS Z',
      'ARROWS Z FJL22',
      'ARROWS Z ISW11F',
      'ARROWS Z ISW13F',
      'ARROWS μ',
    ],
    'Disney Mobile': [
      'Disney Mobile F-03F',
      'Disney Mobile F-07E',
      'Disney Mobile F-08D',
    ],
    'REGZA Phone': [
      'REGZA Phone IS04',
      'REGZA Phone IS11T',
      'REGZA Phone T-01C',
      'REGZA Phone T-01D',
      'REGZA Phone T-02D',
    ],
    その他: [
      "F-03D Girls'",
      'F-09D ANTEPRIMA',
      'F-12C',
      'FOMA F1100',
      'Windows Phone IS12T',
      'ビジネススマートフォン F-04F',
      'らくらくスマートフォン F-52B',
      'らくらくスマートフォン F-12D',
      'らくらくスマートフォン F-42A',
      'らくらくスマートフォン me F-01L',
      'らくらくスマートフォン me F-03K',
      'らくらくスマートフォン プレミアム',
      'らくらくスマートフォン2',
      'らくらくスマートフォン3',
      'らくらくスマートフォン4',
    ],
  },
  geanee: {
    GMシリーズ: [
      'GM-01A',
    ],
    イオンスマホ: [
      'イオンスマホ FXC-5A',
    ],
    その他: [
      'ADP-503G',
      'WPJ40-10',
    ],
  },
  Google: {
    Nexus: [
      'Nexus 4',
      'Nexus 5',
      'Nexus 5X',
      'Nexus 6',
      'Nexus 6P',
    ],
    Pixel: [
      'Pixel 7 Pro',
      'Pixel 7',
      'Pixel 6a',
      'Pixel 6 Pro',
      'Pixel 6',
      'Pixel 5a 5G',
      'Pixel 5',
      'Pixel 3',
      'Pixel 3 XL',
      'Pixel 3a',
      'Pixel 3a XL',
      'Pixel 4',
      'Pixel 4 XL',
      'Pixel 4a',
      'Pixel 4a 5G',
    ],
  },
  HP: {
    Elite: [
      'Elite x3',
    ],
  },
  HTC: {
    'Android One': [
      'Android One X2',
    ],
    Desire: [
      'Desire22pro',
      'HTC Desire 626',
      'HTC Desire EYE',
      'HTC Desire HD',
      'HTC Desire X06HT',
      'HTC Desire X06HTII',
    ],
    Diamond: [
      'Dual Diamond',
      'Touch Diamond S21HT',
      'Touch Diamond X04HT',
    ],
    EVO: [
      'HTC EVO 3D',
      'HTC EVO WiMAX',
    ],
    Jシリーズ: [
      'HTC J',
      'HTC J butterfly HTL21',
      'HTC J butterfly HTL23',
      'HTC J butterfly HTV31',
      'HTC J One',
    ],
    'U11/10': [
      'HTC 10',
      'HTC U11',
      'HTC U11 life',
      'HTC U12+',
    ],
    Xシリーズ: [
      'X01HT',
      'X02HT',
      'X03HT',
    ],
    その他: [
      'Aria S31HT',
      'E30HT',
      'EMONSTER lite',
      'FOMA HT1100',
      'HT-01A',
      'HT-02A',
      'HT-03A',
      'hTc Z',
      'INFOBAR A02',
      'S11HT EMONSTER',
      'Touch Pro X05HT',
    ],
  },
  HUAWEI: {
    Ascend: [
      'Ascend',
      'Ascend D2',
      'Ascend G6',
      'Ascend G620S',
      'Ascend Mate7',
      'Ascend P7',
    ],
    honor: [
      'honor 8',
      'honor 9',
      'honor6 Plus',
    ],
    Mate: [
      'Mate 10 lite',
      'Mate 10 Pro',
      'Mate 20 lite',
      'Mate 20 Pro',
      'Mate 30 Pro 5G',
      'Mate 9',
      'Mate S',
    ],
    nova: [
      'nova',
      'nova 2',
      'nova 3',
      'nova 5T',
      'nova lite',
      'nova lite 2',
      'nova lite 3',
      'nova lite 3+',
    ],
    Pシリーズ: [
      'P50 Series',
      'P40 Pro 5G',
      'P40 lite 5G',
      'P40 lite E',
      'P30 Pro',
      'P30 lite Premium',
      'P30 lite',
      'P30',
      'P20 Pro',
      'P20 lite',
      'P20',
      'P10 Plus',
      'P10 lite',
      'P10',
      'P9 lite PREMIUM',
      'P9 lite',
      'P9',
      'P8 lite',
      'P8 max',
    ],
    その他: [
      'GR5',
      'GS02',
      'GS03',
      'LUMIERE 503HW',
      'Pocket WiFi S',
      'Pocket WiFi S II',
      'smart bar S42HW',
      'STREAM 201HW',
      'STREAM S',
      'STREAM X',
      'Vision',
      'Y6',
      'あんしんファミリーケータイ',
    ],
  },
  KYOCERA: {
    'Android One': [
      'Android One S2',
      'Android One S4',
      'Android One S6',
      'Android One S8',
      'Android One S9',
      'Android One X3',
    ],
    DIGNO: [
      'DIGNO A',
      'DIGNO BX',
      'DIGNO C',
      'DIGNO DUAL',
      'DIGNO DUAL 2',
      'DIGNO E',
      'DIGNO F',
      'DIGNO G',
      'DIGNO ISW11K',
      'DIGNO J',
      'DIGNO L',
      'DIGNO M',
      'DIGNO R',
      'DIGNO rafre KYV36',
      'DIGNO S',
      'DIGNO T',
      'DIGNO U',
      'DIGNO V',
    ],
    TORQUE: [
      'TORQUE 5G',
      'TORQUE 5G Colman LIMITED',
      'TORQUE G01',
      'TORQUE G02',
      'TORQUE G03',
      'TORQUE G03 HELLY HANSEN LIMITED',
      'TORQUE G04',
      'TORQUE SKT01',
    ],
    URBANO: [
      'URBANO L01',
      'URBANO L02',
      'URBANO L03',
      'URBANO PROGRESSO',
      'URBANO V01',
      'URBANO V02',
      'URBANO V03',
      'URBANO V04',
    ],
    その他: [
      'mamorino 5',
      'BASIO',
      'BASIO3',
      'BASIO4',
      'DM015K',
      'DM015K ディズニー・モバイル',
      'DURA FORCE PRO',
      'DuraForce PRO 2',
      'GRATINA KYV48',
      'HONEY BEE 101K',
      'HONEY BEE 201K',
      'HONEY BEE WX06K',
      'INFOBAR A03',
      'KC-01',
      'LUCE',
      'miraie',
      'miraie f',
      'Qua phone',
      'Qua phone QX',
      'Qua phone QZ',
      'rafre',
      'rafre KYV40',
      'S301',
      'おてがるスマホ01',
      'かんたんスマホ 705KC',
      'かんたんスマホ2',
      'かんたんスマホ2＋',
      'あんしんスマホ KY-51B',
    ],
  },
  LEICA: {
    LEITZ: [
      'LEITZ PHONE 1',
    ],
  },
  Lenovo: {
    '503シリーズ': [
      '503LV',
    ],
    その他: [
      'PHAB2 Pro',
    ],
  },
  LG: {
    'Android One': [
      'Android One X5',
    ],
    'Disney Mobile': [
      'Disney Mobile DM-01G',
      'Disney Mobile DM-01K',
      'Disney Mobile DM-02H',
    ],
    Gシリーズ: [
      'G2',
      'G2 mini',
      'G3 Beat',
    ],
    isai: [
      'isai',
      'isai Beat',
      'isai FL',
      'isai V30+',
      'isai vivid',
      'isai VL',
    ],
    Optimus: [
      'Optimus bright',
      'Optimus chat',
      'Optimus G',
      'Optimus G Pro',
      'Optimus it L-05D',
      'Optimus it L-05E',
      'Optimus LIFE',
      'Optimus LTE',
      'Optimus Vu',
      'Optimus X',
    ],
    Vシリーズ: [
      'V20 PRO',
      'V30+',
      'V60 ThinQ 5G',
    ],
    その他: [
      'LG V60 ThinQ 5G',
      'LG Q Stylus',
      'JOJO L-02K',
      'VELVET',
      'Fx0 LGL25',
      'G Flex LGL23',
      'G2 L-01F',
      'it',
      'JOJO L-02K',
      'K50',
      'L-06D JOJO',
      'LG G8X ThinQ',
      'LG Q Stylus',
      'LG style',
      'LG style2',
      'LG style3',
      'PRADA phone by LG',
      'Qua phone PX',
      'Spray',
    ],
  },
  'MAYA SYSTEM': {
    jetfon: [
      'jetfon',
    ],
    KATANA: [
      'KATANA 01',
      'KATANA 02',
    ],
    Priori: [
      'priori',
      'Priori 4',
      'Priori 5',
      'Priori2',
      'priori2 LTE',
      'Priori3 LTE',
      'Priori3S LTE',
      'PrioriS3 LTE',
    ],
    SAMURAI: [
      'SAMURAI KIWAMI',
      'SAMURAI KIWAMI 2',
      'SAMURAI MIYABI',
      'SAMURAI REI',
    ],
    その他: [
      'jetfon P6',
      'LTE XM',
      'MUSASHI',
      'nico',
      'RAIJIN',
      'REI 2 Dual',
    ],
  },
  MOTOROLA: {
    Gシリーズ: [
      'moto e5',
      'Moto G 第3世代',
      'Moto G4 Plus',
      'Moto G5',
      'Moto G5 Plus',
      'Moto G5s',
      'Moto G5s Plus',
      'moto g6',
      'moto g6 play',
      'moto g6 plus',
      'moto g7',
      'moto g7 plus',
      'moto g7 power',
      'moto g8',
      'moto g8 plus',
      'moto g8 power',
      'moto g8 power lite',
      'moto g52j 5G',
      'moto g31',
      'moto G50 5G',
      'moto g100',
      'moto g30',
    ],
    その他: [
      'MOTOROLA razr 5G',
      'M1000',
      'moto e32s',
      'moto e6s',
      'Moto X Play',
      'Moto X4',
      'Moto Z',
      'Moto Z Play',
      'Moto Z2 Play',
      'moto z3 play',
      'MOTOROLA PHOTON',
      'MOTOROLA RAZR',
      'MOTOROLA RAZR M',
      'motorola edge 20',
      'Motorola edge 20 fusion',
      'motorola edge 30 pro',
    ],
  },
  'mouse computer': {
    MADOSMA: [
      'Q501-WH',
      'MADOSMA Q601',
      'MADOSMA Q501AO',
      'MADOSMA Q501A',
      'MADOSMA Q501',
    ],
  },
  NEC: {
    'Disney Mobile': [
      'Disney Mobile N-03E',
    ],
    MEDIAS: [
      'MEDIAS',
      'MEDIAS BR',
      'MEDIAS CH',
      'MEDIAS ES',
      'MEDIAS LTE',
      'MEDIAS PP',
      'MEDIAS U',
      'MEDIAS W',
      'MEDIAS WP',
      'MEDIAS X',
      'MEDIAS X N-04E',
      'MEDIAS X N-06E',
    ],
    その他: [
      'ONE PIECE N-02E',
    ],
  },
  NOKIA: {
    Xシリーズ: [
      'X01NK',
      'X02NK',
    ],
    その他: [
      'C21 plus',
      'C21',
      'C2 2nd Edition',
      'E61',
      'N82',
    ],
  },
  'Nothing Phone': {
    その他: [
      'Nothing Phone(1)',
    ],
  },
  'Nubia Technology': {
    RedMagic: [
      'RedMagic 7',
      'RedMagic 6 Pro',
      'RedMagic 6',
      'RedMagic 5',
    ],
  },
  ONKYO: {
    GRANBEAT: [
      'GRANBEAT DP-CMX1',
    ],
  },
  OPPO: {
    OPPO: [
      'OPPO A77',
      'OPPO Find X5 Pro',
      'OPPO Find X3 Pro',
      'OPPO A55s 5G',
    ],
    RENO: [
      'RENO 10x ZOOM',
      'Reno A',
      'Reno3 5G',
      'Reno3 A',
      'Reno5 A',
      'Reno7 A',
    ],
    その他: [
      'A54 5G',
      'A5 2020',
      'A73',
      'AX73',
      'AX7',
      'Find X',
      'Find X2 Pro',
      'Find X3 Pro',
      'R11s',
      'R15 Neo',
      'R15 Pro',
      'R17 Neo',
      'R17 Pro',
    ],
  },
  PALM: {
    'Palm Phone': [
      'Palm Phone',
    ],
  },
  Panasonic: {
    'Disney Mobile': [
      'Disney Mobile P-05D',
    ],
    ELUGA: [
      'ELUGA P',
      'ELUGA power',
      'ELUGA V',
      'ELUGA X',
    ],
    Pシリーズ: [
      'P-01D',
      'P-04D',
      'P-07C',
    ],
    その他: [
      '102P',
      'LUMIX Phone',
      'Sweety 003P',
      'TOUGHBOOK P-01K',
    ],
  },
  PANTECH: {
    VEGA: [
      'VEGA',
    ],
    その他: [
      'EIS01PT',
      'MIRACH',
      'SIRIUS α',
    ],
  },
  Polaroid: {
    LINEAGE: [
      'LINEAGE',
    ],
    pigu: [
      'pigu',
    ],
    PolaSma: [
      'PolaSma',
    ],
  },
  Samsung: {
    'Galaxy Note': [
      'GALAXY Note20 Ultra 5G',
      'GALAXY Note',
      'GALAXY Note 3',
      'GALAXY Note Edge',
      'GALAXY Note II',
      'Galaxy Note10+',
      'Galaxy Note10+ Star Wars Special Edition',
      'Galaxy Note8',
      'Galaxy Note9',
    ],
    'Galaxy Sシリーズ': [
      'GALAXY NEXUS',
      'GALAXY S',
      'GALAXY S II',
      'GALAXY S II LTE',
      'GALAXY S II WiMAX',
      'GALAXY S III',
      'GALAXY S III Progre',
      'GALAXY S III α',
      'Galaxy S10',
      'Galaxy S10+',
      'Galaxy S10+ Olympic Games Edition',
      'Galaxy S20 5G',
      'Galaxy S20 Ultra 5G',
      'Galaxy S20+ 5G',
      'Galaxy S20+ 5G BTS Edition',
      'Galaxy S21 5G',
      'Galaxy S21 +5G',
      'Galaxy S21 5G Olympic Games Edition SC',
      'Galaxy S21 Ultra5G',
      'Galaxy S22',
      'Galaxy S22 Ultra',
      'GALAXY S4',
      'GALAXY S5',
      'GALAXY S5 ACTIVE',
      'Galaxy S6',
      'Galaxy S6 edge',
      'Galaxy S7 edge',
      'Galaxy S7 edge Injustice Edition',
      'Galaxy S7 edge Olympic Games Edition',
      'Galaxy S8',
      'Galaxy S8+',
      'Galaxy S9',
      'Galaxy S9+',
    ],
    'Galaxy Zシリーズ': [
      'Galaxy Z Flip4',
      'Galaxy Z Fold4',
      'Galaxy Z Flip3 5G',
      'Galaxy Z Fold3 5G',
      'Galaxy Z Flip 5G',
      'Galaxy Z Fold2 5G',
      'Galaxy Z Flip',
    ],
    'Galaxy Aシリーズ': [
      'Galaxy A51 5G',
      'Galaxy A52 5G',
      'Galaxy A32 5G',
      'Galaxy A23 5G',
      'Galaxy A22',
      'Galaxy A21',
      'Galaxy A20',
      'Galaxy A30',
      'Galaxy A41',
      'Galaxy A53 5G',
      'Galaxy A7',
      'Galaxy A8',
    ],
    その他: [
      'Galaxy Active neo',
      'Galaxy Feel',
      'Galaxy Feel2',
      'Galaxy Fold',
      'GALAXY J',
      'Galaxy M23 5G',
      'SC-01B',
      'X01SC',
    ],
  },
  SHARP: {
    'Android One': [
      'Android One 507SH',
      'Android One S1',
      'Android One S3',
      'Android One S5',
      'Android One S7',
      'Android One X1',
      'Android One X4',
    ],
    'AQUOS CRYSTAL': [
      'AQUOS CRYSTAL',
      'AQUOS CRYSTAL 2',
      'AQUOS CRYSTAL X',
      'AQUOS CRYSTAL Y',
      'AQUOS CRYSTAL Y2',
    ],
    'AQUOS PHONE': [
      'AQUOS PHONE 006SH',
      'AQUOS PHONE 102SH',
      'AQUOS PHONE 102SH II',
      'AQUOS PHONE 103SH',
      'AQUOS PHONE 104SH',
      'AQUOS PHONE CL',
      'AQUOS PHONE ef',
      'AQUOS PHONE es',
      'AQUOS PHONE EX SH-02F',
      'AQUOS PHONE EX SH-04E',
      'AQUOS PHONE f',
      'AQUOS PHONE IS11SH',
      'AQUOS PHONE IS12SH',
      'AQUOS PHONE IS13SH',
      'AQUOS PHONE IS14SH',
      'AQUOS PHONE SERIE',
      'AQUOS PHONE SERIE mini',
      'AQUOS PHONE SERIE SHL21',
      'AQUOS PHONE SERIE SHL22',
      'AQUOS PHONE SERIE SHL23',
      'AQUOS PHONE SH-01D',
      'AQUOS PHONE SH-06D',
      'AQUOS PHONE SH-12C',
      'AQUOS PHONE si SH-01E',
      'AQUOS PHONE si SH-07E',
      'AQUOS PHONE SL',
      'AQUOS PHONE slider',
      'AQUOS PHONE ss',
      'AQUOS PHONE st',
      'AQUOS PHONE sv',
      'AQUOS PHONE THE HYBRID 007SH',
      'AQUOS PHONE THE HYBRID 007SH J',
      'AQUOS PHONE THE HYBRID 101SH',
      'AQUOS PHONE THE PREMIUM',
      'AQUOS PHONE Xx 106SH',
      'AQUOS PHONE Xx 203SH',
      'AQUOS PHONE Xx 206SH',
      'AQUOS PHONE Xx 302SH',
      'AQUOS PHONE Xx mini',
      'AQUOS PHONE ZETA SH-01F',
      'AQUOS PHONE ZETA SH-02E',
      'AQUOS PHONE ZETA SH-06E',
      'AQUOS PHONE ZETA SH-09D',
    ],
    'AQUOS R': [
      'AQUOS R',
      'AQUOS R compact',
      'AQUOS R2',
      'AQUOS R2 compact',
      'AQUOS R3',
      'AQUOS R5G',
      'AQUOS R6',
      'AQUOS R7',
    ],
    'AQUOS sense': [
      'sense7 plus',
      'sense7',
      'AQUOS sense6s',
      'AQUOS sense6',
      'AQUOS sense4 lite',
      'AQUOS sense4',
      'AQUOS sense3 plus サウンド',
      'AQUOS sense3 plus',
      'AQUOS sense3 lite',
      'AQUOS sense3 basic',
      'AQUOS sense3',
      'AQUOS sense2',
      'AQUOS sense plus',
      'AQUOS sense lite',
      'AQUOS sense basic',
      'AQUOS sense 5G',
      'AQUOS sense',
    ],
    'AQUOS Xx': [
      'AQUOS Xx',
      'AQUOS Xx 304SH',
      'AQUOS Xx2',
      'AQUOS Xx2 mini',
      'AQUOS Xx3',
      'AQUOS Xx3 mini',
      'AQUOS Xx-Y',
    ],
    AQUOSその他: [
      'AQUOS wish',
      'AQUOS wish2',
      'AQUOS zero6',
      'AQUOS Compact',
      'AQUOS ea',
      'AQUOS EVER SH-02J',
      'AQUOS EVER SH-04G',
      'AQUOS L2',
      'AQUOS mini',
      'AQUOS SERIE mini SHV31',
      'AQUOS SERIE mini SHV33',
      'AQUOS SERIE mini SHV38',
      'AQUOS SERIE SHL25',
      'AQUOS SERIE SHV32',
      'AQUOS SERIE SHV34',
      'AQUOS SH-M01',
      'AQUOS SH-M02',
      'AQUOS SH-M04',
      'AQUOS SH-M04-A',
      'AQUOS U SHV35',
      'AQUOS U SHV37',
      'AQUOS zero',
      'AQUOS zero2',
      'AQUOS zero7',
      'AQUOS zero5G basic',
      'AQUOS zero5G basic DX',
      'AQUOS ZETA SH-01G',
      'AQUOS ZETA SH-01H',
      'AQUOS ZETA SH-03G',
      'AQUOS ZETA SH-04F',
      'AQUOS ZETA SH-04H',
    ],
    'Disney Mobile': [
      'Disney Mobile DM009SH',
      'Disney Mobile DM010SH',
      'Disney Mobile DM011SH',
      'Disney Mobile DM012SH',
      'Disney Mobile DM-013SH',
      'Disney Mobile DM014SH',
      'Disney Mobile DM016SH',
      'Disney Mobile DM-01H',
      'Disney Mobile DM-01J',
      'Disney Mobile SH-02G',
      'Disney Mobile SH-05F',
    ],
    'かんたん/シンプル/ジュニア': [
      'AQUOS sense2 かんたん',
      'シンプルスマホ 204SH',
      'シンプルスマホ2',
      'シンプルスマホ3',
      'シンプルスマホ4',
      'シンプルスマホ5',
      'シンプルスマホ6',
      'スマートフォン for ジュニア',
      'スマートフォン for ジュニア2',
    ],
    その他: [
      '007SH KT',
      '03 WS020SH',
      'Advanced/W-ZERO3',
      'BASIO2',
      'D4 Ver.L',
      'D4 WS016SH',
      'DM009SH',
      'DM010SH',
      'DM011SH',
      'DM012SH',
      'DM013SH',
      'DM014SH',
      'DM016SH',
      'GALAPAGOS 003SH',
      'GALAPAGOS 005SH',
      'HYBRID W-ZERO3',
      'INFOBAR A01',
      'INFOBAR C01',
      'IS01',
      'IS03',
      'IS05',
      'LYNX 3D',
      'LYNX SH-10B',
      'PANTONE 5',
      'PANTONE 6',
      'Q-pot.Phone',
      'SH-01E Vivienne Westwood',
      'SH-01F DRAGON QUEST',
      'SH-06D NERV',
      'STAR WARS mobile',
      'WILLCOM 03 WS02SH',
      'W-ZERO3',
      'W-ZERO3 Premium version',
      'Yahoo! Phone 009SH Y',
      'ボス電 107SH B',
    ],
  },
  SONY: {
    'Xperia 1/5/8/Ace': [
      'Xperia Ace Ⅲ',
      'Xperia Ace Ⅱ',
      'Xperia Ace',
      'Xperia 8 Lite',
      'Xperia 8',
      'Xperia 5 III',
      'Xperia 5 II',
      'Xperia 5',
      'Xperia 1 Ⅳ',
      'Xperia 1 III',
      'Xperia 1 professional edition',
      'Xperia 1 II',
      'Xperia 1',
    ],
    'Xperia A': [
      'Xperia A',
      'Xperia A2',
      'Xperia A4',
      'Xperia acro',
      'Xperia acro HD',
      'Xperia arc',
      'Xperia AX',
    ],
    'Xperia X': [
      'Xperia X Compact',
      'Xperia X Performance',
      'Xperia XZ',
      'Xperia XZ Premium',
      'Xperia XZ1',
      'Xperia XZ1 Compact',
      'Xperia XZ2',
      'Xperia XZ2 Compact',
      'Xperia XZ2 Premium',
      'Xperia XZ3',
      'Xperia XZs',
    ],
    'Xperia Z': [
      'Xperia Z',
      'Xperia Z Ultra',
      'Xperia Z1',
      'Xperia Z1 f',
      'Xperia Z2',
      'Xperia Z3',
      'Xperia Z3 Compact',
      'Xperia Z4',
      'Xperia Z5',
      'Xperia Z5 Compact',
      'Xperia Z5 Premium',
      'Xperia ZL2',
    ],
    Xperiaその他: [
      'Xperia5IV',
      'Xperia PRO',
      'Xperia PRO-I',
      'Xperia 10 II',
      'Xperia 10 III',
      'Xperia 10 III Lite',
      'Xperia 10 Ⅳ',
      'Xperia feat. HATSUNE MIKU',
      'Xperia GX',
      'Xperia J1 Compact',
      'Xperia NX',
      'Xperia PLAY',
      'Xperia ray',
      'Xperia SO-01B',
      'Xperia SX',
      'Xperia UL',
      'Xperia VL',
    ],
    その他: [
      'Sony Ericsson mini',
    ],
  },
  TCL: {
    IDOL: [
      'Alcatel ONETOUCH IDOL 3',
      'Alcatel PIXI 4',
      'Alcatel SHINE LITE',
    ],
    その他: [
      'Alcatel IDOL 4',
      'Alcatel ONETOUCH IDOL 2',
      'PLEX',
      'TCL 10 Lite',
      'TCL 10 Pro',
      'TCL 10 5G',
    ],
  },
  'THIRD WAVE': {
    Diginnos: [
      'Diginnos Mobile DG-W10M',
    ],
  },
  TJC: {
    StarQ: [
      'StarQ Q5002',
    ],
  },
  TONE: {
    eシリーズ: [
      'e22',
      'e21',
      'e19',
    ],
    mシリーズ: [
      'm15',
      'm17',
    ],
  },
  TOSHIBA: {
    その他: [
      'dynapocket T-01B',
      'dynapocket X02T',
      'IS02',
      'T-01A',
    ],
  },
  Trinity: {
    NuAns: [
      'NuAns NEO',
      'NuAns NEO Reloaded',
    ],
  },
  Unihertz: {
    Jelly: [
      'Jelly 2',
    ],
    その他: [
      'TickTock-E',
    ],
  },
  UPQ: {
    'UPQ Phone': [
      'Phone A02',
      'UPQ Phone A01X',
      'UPQ Phone A01',
    ],
  },
  VAIO: {
    'VAIO Phone': [
      'VAIO Phone A',
      'VAIO Phone Biz',
      'VAIO Phone VA-10J',
    ],
  },
  Wiko: {
    gシリーズ: [
      'g08',
    ],
    Tommy: [
      'Tommy',
      'Tommy 3 plus',
    ],
    VIEW: [
      'VIEW',
    ],
  },
  Xiaomi: {
    'Black Shark': [
      'Black Shark 2',
      'Black Shark 3',
    ],
    Mi: [
      'Mi Note 10',
      'Mi Note 10 Lite',
      'Mi Note 10 Pro',
      'Mi 10 Lite 5G',
      'Mi 11 Lite 5G',
      'Mi Mix 3 5G',
    ],
    Redmi: [
      'Redmi 9T',
      'Redmi Note 9S',
      'Redmi Note 9T 5G',
      'Redmi Note 10 JE',
      'Redmi Note 10 Pro',
      'Redmi Note 10T',
      'Redmi Note 11',
      'Redmi Note 11 Pro 5G',
    ],
    Xiaomi: [
      'Xiaomi 12T Pro',
      'Xiaomi 12T',
      'Xiaomi 11T Pro',
      'Xiaomi 11T',
    ],
    POCO: [
      'POCO F4 GT',
    ],
  },
  ZTE: {
    Blade: [
      'Blade E01',
      'Blade E02',
      'Blade L3 g01',
      'BLADE Q+',
      'Blade S g03',
      'Blade S Lite g02',
      'Blade S6',
      'Blade S7 g05',
      'Blade V580',
      'Blade V6',
      'Blade V770',
      'Blade V7Lite',
      'Blade V7Max',
      'Blade V8',
      'Blade Vec 4G',
      'Blade V40',
      'Blade V40 Pro',
    ],
    Libero: [
      'Libero',
      'Libero 2',
      'Libero 3',
      'Libero S10',
      'Libero 5G',
      'Libero 5G II',
    ],
    その他: [
      'mamorino 4',
      'Axon 10 Pro 5G',
      'AXON 7',
      'AXON 7 mini',
      'AXON mini',
      'M Z-01K',
      'MONO MO-01J',
      'MONO MO-01K',
      'STAR7 009Z',
      'ZTE a1',
      'シンプルスマートフォン 4',
    ],
  },
  楽天モバイル: {
    Mini: [
      'Rakuten Mini',
    ],
    BIG: [
      'Rakuten BIG',
      'Rakuten BIG s',
    ],
    Hand: [
      'Rakuten Hand 5G',
      'Rakuten Hand',
    ],
    その他: [
      'Redmi Note 11 Pro 5G',
    ],
  },
};

export const deviceMakers: string[] = Object.keys(devices);

export function deviceBrands(maker: string): string[] {
  if (maker === '') {
    return [];
  }
  return Object.keys(devices[maker]);
}

export function deviceModels(maker: string, brand: string): string[] {
  if (maker === '' || brand === '') {
    return [];
  }
  return devices[maker][brand];
}
