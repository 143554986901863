import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextInput from './TextInput';
import {
  Input,
  FormFilter,
  OnFormChanged,
  phoneFilter,
  handleChangeFactory,
} from '../utils/formHelpers';

export interface PhoneFormProps {
    phone1: Input<string>;
    phone2: Input<string>;
    validated: boolean;
    onChange: OnFormChanged;
    namePrefix: string;
    filter?: FormFilter;
    onChangeFactory?: typeof handleChangeFactory;
}

export default function PhoneForm({
  phone1,
  phone2,
  validated,
  onChange,
  namePrefix,
  filter = phoneFilter,
  onChangeFactory = handleChangeFactory,
}: PhoneFormProps) {
  const handleChange = onChangeFactory(onChange);
  const hasError: (input: Input<string>) => boolean = (input) => (
    (input.dirty || validated) && input.errors.length > 0
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextInput
          required
          label="電話番号１"
          fullWidth
          placeholder="0312344567"
          name={`${namePrefix}-phone1`}
          value={phone1.value}
          onChange={handleChange('phone1', filter)}
          error={hasError(phone1)}
          helperText={hasError(phone1) ? phone1.errors[0] : 'ハイフン不要です'}
          inputProps={{ inputMode: 'numeric' }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="電話番号２"
          fullWidth
          placeholder="08098765432"
          name={`${namePrefix}-phone2`}
          value={phone2.value}
          onChange={handleChange('phone2', filter)}
          error={hasError(phone2)}
          helperText={hasError(phone2) ? phone2.errors[0] : 'ハイフン不要です'}
          inputProps={{ inputMode: 'numeric' }}
        />
      </Grid>
    </Grid>
  );
}
