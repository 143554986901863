import { ChangeEvent } from 'react';
import { compose } from 'redux';
import { AppState } from '../reducers';

export interface Input<T> {
    value: T;
    errors: string[];
    dirty: boolean;
}

export type ChangeEventHandler = (event: ChangeEvent<HTMLInputElement>) => void;

export type FormKeys = keyof AppState['form']['holder'] | keyof AppState['form']['insured'];

export type FormFilter = (val: string) => string;

export type OnFormChanged = (key: FormKeys, val: string) => void;

export function throughFilter(val: string): string {
  return val;
}

export function allowNumbers(val: string): string {
  return val.replace(/\D/g, '');
}

export function zenkaku2Hankaku(val: string): string {
  return val.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (str: string) => {
    return String.fromCharCode(str.charCodeAt(0) - 0xFEE0);
  });
}

export function dateDelimiter(val: string): string {
  return val.replace(/／/g, '/').replace(/ー/g, '-').replace(/−/g, '-');
}

export function removeSpace(val: string): string {
  return val.replace(/\s/g, '');
}

export function trim(val: string): string {
  return val.trim();
}

export function maxSize(max: number) {
  return function maxSizeFilter(val: string): string {
    return val.length > max ? val.slice(0, max) : val;
  };
}

export function handleChangeFactory(callback: (key: FormKeys, val: string) => void) {
  return function handleChange(key: FormKeys, filter: FormFilter = throughFilter) {
    return function onChange(event: ChangeEvent<HTMLInputElement>) {
      const { target: { value } } = event;
      callback(key, filter(value));
    };
  };
}

export const zipcodeFilter: FormFilter = compose(
  maxSize(7),
  allowNumbers,
  zenkaku2Hankaku,
  trim,
);

export const hankakuFilter: FormFilter = compose(
  zenkaku2Hankaku,
  trim,
);

export const zenkakuFilter: FormFilter = compose(
  trim,
);

export const dateFilter: FormFilter = compose(
  dateDelimiter,
  zenkaku2Hankaku,
  trim,
);

export const phoneFilter: FormFilter = compose(
  maxSize(11),
  allowNumbers,
  zenkaku2Hankaku,
  trim,
);

export const cardNumberFilter: FormFilter = compose(
  maxSize(16),
  allowNumbers,
  zenkaku2Hankaku,
  trim,
);

export const agentCodeFilter: FormFilter = compose(
  maxSize(6),
  allowNumbers,
  zenkaku2Hankaku,
  trim,
);

export const agencyCodeFilter: FormFilter = compose(
  maxSize(5),
  allowNumbers,
  zenkaku2Hankaku,
  trim,
);

// NOTE: 文字列の途中で半角スペースが入る可能性のあるカード名義人はフィルターでtrimできない
export const cardNameFilter: FormFilter = compose(
  zenkaku2Hankaku,
);

export const cardCvcFilter: FormFilter = compose(
  maxSize(4),
  zenkaku2Hankaku,
  trim,
);

export const deviceIMEIFilter: FormFilter = compose(
  zenkaku2Hankaku,
  removeSpace,
);

export const devicePriceFilter: FormFilter = compose(
  zenkaku2Hankaku,
  trim,
);
