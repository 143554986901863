import React from 'react';
import Grid from '@material-ui/core/Grid';
import Instruction from '../components/Instruction';
import Product from '../containers/components/Product';
import Holder from '../containers/components/Holder';
import SummaryAnnounce from '../containers/components/SummaryAnnounce';
import SummaryDevice from '../containers/components/SummaryDevice';
import SummaryTerms from '../components/SummaryTerms';
import StepButtons from '../components/StepButtons';
import { AppState, AppActions } from '../reducers';
import { TERMS } from '../constants';
import { StepBase } from './common';

export type MapStateToProps = {
  loading: AppState['loading'];
  direct: boolean;
};

export type MapDispatchToProps = {
  back: () => AppActions;
  next: () => AppActions;
};

export interface ConfirmationProps extends MapStateToProps, MapDispatchToProps, StepBase {}

export default function Confirmation({
  direct,
  firstStep,
  lastStep,
  loading,
  back,
  next,
}: ConfirmationProps) {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Instruction>
            プラン名、保険料、お支払い方法をご確認ください。
          </Instruction>
          <Product showConcreteValue />
        </Grid>
        <Grid item xs={12}>
          <Instruction>
            契約者様の内容をご確認ください。
          </Instruction>
          <Holder />
        </Grid>
        <Grid item xs={12}>
          <Instruction>
            端末の内容をご確認ください。
          </Instruction>
          <SummaryDevice />
        </Grid>
        {direct && (
          <Grid item xs={12}>
            <Instruction>
              告知内容をご確認ください。
            </Instruction>
            <SummaryAnnounce />
          </Grid>
        )}
        <Grid item xs={12}>
          <Instruction>
            同意事項の内容をご確認ください。
          </Instruction>
          <SummaryTerms
            terms={TERMS.map((term) => `${term.name}の内容を確認して同意しました。`)}
          />
        </Grid>
      </Grid>
      <StepButtons
        onClickBack={back}
        onClickNext={next}
        firstStep={firstStep}
        lastStep={lastStep}
        disableBack={loading}
        disableNext={loading}
      />
    </>
  );
}
