import { connect } from 'react-redux';
import { AppState, AppActions } from '../../reducers';
import { InputMode } from '../../reducers/mode';
import Confirmation from '../../steps/Confirmation';

const mapStateToProps = ({
  mode,
  loading,
}: AppState) => ({
  loading,
  direct: mode === InputMode.Direct,
});

const mapDispatchToProps = {
  back(): AppActions {
    return {
      type: 'STEPS/BACK',
    };
  },

  next(): AppActions {
    return {
      type: 'REGISTRATION/START',
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
