import { connect } from 'react-redux';
import { AppState, AppActions } from '../../reducers';
import HolderForm from '../../components/HolderForm';

type IHolder = AppState['form']['holder'];

type SetHolderPayload = Partial<{
    [T in keyof IHolder]: IHolder[T]['value'];
}>;

const mapStateToProps = ({
  form: { holder },
  loading,
}: AppState) => ({
  ...holder,
  loading,
});

const mapDispatchToProps = {
  fetchAddress(zipcode: string): AppActions {
    return {
      type: 'FORM/FETCH_ADDRESS',
      payload: {
        zipcode,
        form: 'holder',
      },
    };
  },

  setHolder(payload: SetHolderPayload): AppActions {
    return {
      type: 'FORM/SET_HOLDER',
      payload,
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(HolderForm);
