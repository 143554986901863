import { put, call } from 'redux-saga/effects';
import { API } from 'aws-amplify';
import { AppActions, AppAction } from '../reducers';
import { Severity } from '../reducers/alert';
import { IAddress } from '../reducers/addresses';

interface IZipcloudAddress {
    zipcode: string;
    prefcode: string;
    address1: string;
    address2: string;
    address3: string;
    kana1: string;
    kana2: string;
    kana3: string;
}

// ref: http://zipcloud.ibsnet.co.jp/doc/api
export interface IAddressResponse {
    status: number;
    message: string | null;
    results: IZipcloudAddress[] | null;
}

function filterAddress(res: IZipcloudAddress): IAddress {
  return {
    prefecture: res.address1,
    address1: `${res.address2}${res.address3}`,
  };
}

function fetch(zipcode: string): Promise<IAddress[]> {
  const apiName = 'GyaraEntry';
  const path = '/zipcode';
  const init = { queryStringParameters: { zipcode } };

  return API
    .get(apiName, path, init)
    .then((res: IAddressResponse) => {
      if (Array.isArray(res.results) && res.results.length > 0) {
        return res.results.map(filterAddress);
      }
      throw new Error();
    });
}

type FetchAddressParams = AppAction<AppActions, 'FORM/FETCH_ADDRESS'>;

// eslint-disable-next-line import/prefer-default-export
export function* fetchAddress(params: FetchAddressParams) {
  const { payload: { zipcode, form } } = params;
  const actionType = form === 'holder' ? 'FORM/SET_HOLDER' : 'FORM/SET_INSURED';

  yield put({ type: 'LOADING/START' });

  try {
    const res: IAddress[] = yield call(fetch, zipcode);

    if (res.length > 1) {
      yield put({
        type: 'ADDRESSES/OPEN',
        payload: {
          kind: form,
          items: res,
        },
      });
    } else {
      yield put({ type: actionType, payload: res[0] });
    }
  } catch (err) {
    yield put({
      type: 'ALERT/SHOW',
      payload: {
        severity: Severity.Error,
        title: '郵便番号の検索に失敗しました。',
        messages: ['入力された郵便番号をご確認ください。'],
      },
    });
    yield put({
      type: actionType,
      payload: {
        prefecture: '',
        address1: '',
      },
    });
  } finally {
    yield put({ type: 'LOADING/FINISH' });
  }
}
