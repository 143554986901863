import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  container: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
  },
  progress: {
    color: green[500],
  },
}));

export interface LoadingProps {
    loading: boolean;
}

export default function Loading({ loading }: LoadingProps) {
  const classes = useStyles();

  return loading ? (
    <div className={classes.container}>
      <CircularProgress
        size={60}
        thickness={4}
        className={classes.progress}
      />
    </div>
  ) : <div />;
}
