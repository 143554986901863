import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextInput from './TextInput';
import {
  Input,
  FormFilter,
  OnFormChanged,
  zenkakuFilter,
  handleChangeFactory,
} from '../utils/formHelpers';

export interface NameFormProps {
    firstName: Input<string>;
    lastName: Input<string>;
    kanaFirstName: Input<string>;
    kanaLastName: Input<string>;
    validated: boolean;
    onChange: OnFormChanged;
    namePrefix: string;
    filter?: FormFilter;
    onChangeFactory?: typeof handleChangeFactory;
}

export default function NameForm({
  firstName,
  lastName,
  kanaFirstName,
  kanaLastName,
  validated,
  onChange,
  namePrefix,
  filter = zenkakuFilter,
  onChangeFactory = handleChangeFactory,
}: NameFormProps) {
  const handleChange = onChangeFactory(onChange);
  const hasError: (input: Input<string>) => boolean = (input) => (
    (input.dirty || validated) && input.errors.length > 0
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextInput
          required
          fullWidth
          label="姓"
          name={`${namePrefix}-lastName`}
          placeholder="姓を入力してください"
          value={lastName.value}
          onChange={handleChange('lastName', filter)}
          error={hasError(lastName)}
          helperText={hasError(lastName) ? lastName.errors[0] : undefined}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          required
          fullWidth
          label="名"
          name={`${namePrefix}-firstName`}
          placeholder="名を入力してください"
          value={firstName.value}
          onChange={handleChange('firstName', filter)}
          error={hasError(firstName)}
          helperText={hasError(firstName) ? firstName.errors[0] : undefined}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          required
          fullWidth
          label="セイ"
          name={`${namePrefix}-kanaLastName`}
          placeholder="セイを入力してください"
          value={kanaLastName.value}
          onChange={handleChange('kanaLastName', filter)}
          error={hasError(kanaLastName)}
          helperText={hasError(kanaLastName) ? kanaLastName.errors[0] : undefined}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          required
          fullWidth
          label="メイ"
          name={`${namePrefix}-kanaFirstName`}
          placeholder="メイを入力してください"
          value={kanaFirstName.value}
          onChange={handleChange('kanaFirstName', filter)}
          error={hasError(kanaFirstName)}
          helperText={hasError(kanaFirstName) ? kanaFirstName.errors[0] : undefined}
        />
      </Grid>
    </Grid>
  );
}
