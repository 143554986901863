import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Pc from './Pc';
import Mobile from './Mobile';
import { StepContainerProps } from '../common';

export default function Steps(props: StepContainerProps) {
  return (
    <>
      <Hidden smDown>
        <Pc {...props} />
      </Hidden>
      <Hidden mdUp>
        <Mobile {...props} />
      </Hidden>
    </>
  );
}
