import { Action } from 'redux';
import {
  IHolder,
  IAgent,
  IDevice,
  IAnnouce,
  Plan,
} from './form';
import { Input } from '../utils/formHelpers';

type InputContent<T> = T extends Input<infer U> ? U : T;

export interface IRegistrationParams {
  holder: {
    [T in keyof IHolder]: InputContent<IHolder[T]>;
  };
  agent: {
    [U in keyof IAgent]: InputContent<IAgent[U]>;
  },
  device: {
    [S in keyof IDevice]: InputContent<IDevice[S]>;
  };
  announce: {
    [V in keyof IAnnouce]: InputContent<IAnnouce[V]>;
  },
  plan: Plan;
}

interface IState {
  params: IRegistrationParams | null;
  customerNo: string | null;
  contractNo: string | null;
}

export const initialState: IState = {
  params: null,
  customerNo: null,
  contractNo: null,
};

const SET_PARAMS = 'REGISTRATION/SET_PARAMS';
const SET_NUMBERS = 'REGISTRATION/SET_NUMBERS';
const CLEAR_ALL = 'REGISTRATION/CLEAR_ALL';
const START = 'REGISTRATION/START';
const START_COMPLETION = 'REGISTRATION/START_COMPLETION';

interface ISetParamsAction extends Action {
    type: typeof SET_PARAMS;
    payload: IRegistrationParams;
}

interface ISetNumbersAction extends Action {
    type: typeof SET_NUMBERS;
    payload: {
        customerNo: string;
        contractNo: string;
    };
}

interface IClearAllAction extends Action {
    type: typeof CLEAR_ALL;
}

interface IStartAction extends Action {
    type: typeof START;
}

interface IStartCompletionAction extends Action {
    type: typeof START_COMPLETION;
    payload: string | null;
}

export type RegistrationActions = ISetParamsAction
    | ISetNumbersAction
    | IClearAllAction
    | IStartAction
    | IStartCompletionAction;

export default function reducer(state: IState = initialState, action: RegistrationActions): IState {
  switch (action.type) {
    case SET_PARAMS:
      return {
        ...state,
        params: action.payload,
      };

    case SET_NUMBERS:
      return {
        ...state,
        customerNo: action.payload.customerNo,
        contractNo: action.payload.contractNo,
      };

    case CLEAR_ALL:
      return initialState;

    default:
      return state;
  }
}
