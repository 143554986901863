import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(3),
  },
  subTitle: {
    marginTop: theme.spacing(3),
  },
  orderedList: {
    paddingInlineStart: '2rem',
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  width20: {
    width: '20%',
  },
  width30: {
    width: '30%',
  },
  width50: {
    width: '50%',
  },
  width80: {
    width: '80%',
  },
}));

function Title({ children }: { children: string }) {
  const classes = useStyles();

  return (
    <Typography
      variant="h5"
      component="h4"
      color="primary"
      align="center"
      className={classes.title}
    >
      {children}
    </Typography>
  );
}

function SubTitle({ children }: { children: string }) {
  const classes = useStyles();

  return (
    <Typography
      variant="subtitle1"
      component="h5"
      color="primary"
      className={classes.subTitle}
    >
      {children}
    </Typography>
  );
}

function OrderedList({ contents }: { contents: string[] }) {
  const classes = useStyles();

  return (
    <ol className={classes.orderedList}>
      {contents.map((content: string) => (
        <Typography
          variant="body1"
          component="li"
        >
          {content.split('\n').map((sentence: string) => (
            <>
              {sentence}
              <br />
            </>
          ))}
        </Typography>
      ))}
    </ol>
  );
}

function Paragraphs({ contents }: { contents: string[] }) {
  return (
    <>
      {contents.map((content) => (
        <Typography variant="body1" component="p">
          {content}
        </Typography>
      ))}
    </>
  );
}

function Remark({ content }: { content: string }) {
  return (
    <Typography
      variant="caption"
      component="p"
    >
      {content}
    </Typography>
  );
}

function ServiceTableRow({
  title,
  contents,
}: {
  title: string,
  contents: string[],
}) {
  return (
    <TableRow>
      <TableCell>
        {title}
      </TableCell>
      <TableCell>
        {contents.map((content) => (
          <>
            {content}
            <br />
          </>
        ))}
      </TableCell>
    </TableRow>
  );
}

function ServiceTable() {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.width20}>
              区分
            </TableCell>
            <TableCell className={classes.width80}>
              提出必要書類
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ServiceTableRow
            title="修理費用を負担した場合"
            contents={[
              '(a)当社所定の補償金請求書',
              '(b)修理に関するメーカーまたは店舗等のレポート、修理領収書・見積書等、対象端末の一部故障・一部破損およびその修理金額を証明できるもの',
              '(c)損害状況・損害品の写真（画面に IMEI 表示）',
              '(d)その他当社が求めた書類、写真',
            ]}
          />
          <ServiceTableRow
            title="修理不能（全損）の場合"
            contents={[
              '(a)当社所定の補償金請求書',
              '(b)修理に関するメーカーまたは店舗等のレポート、修理領収書・見積書等、対象端末が修理不能（全損）であることを証明できるもの',
              '(c)損害状況、損害品の写真（画面に IMEI 表示）',
              '(d)対象端末と同一機種の端末を新規購入した際の領収書等の新規購入したことが証明できるもの',
              '(e)その他当社が求めた書類、写真',
            ]}
          />
          <ServiceTableRow
            title="データ復旧費用を負担した場合"
            contents={[
              '(a)当社所定の補償金請求書',
              '(b)データ復旧に関するメーカーまたは店舗等のレポート等、対象端末に保存したデータの全部消滅・一部消滅およびその復旧費用を証明できるもの',
              '(c)損害状況・損害品の写真（画面に IMEI 表示）',
              '(d)その他当社が求めた書類、写真',
            ]}
          />
          <ServiceTableRow
            title="盗難または紛失の場合"
            contents={[
              '(a)当社所定の補償金請求書',
              '(b)所轄警察署の盗難または紛失の届出証明書',
              '(c)対象端末と同一機種の端末を新規購入した際の領収書等の新規購入したことが証明できるもの',
              '(d)その他当社が求めた書類、写真',
            ]}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function CoverageTableCell({ content, colSpan }: { content: string, colSpan?: number }) {
  return (
    <TableCell colSpan={colSpan || 1}>
      {content.split('\n').map((sentence) => (
        <>
          {sentence}
          <br />
        </>
      ))}
    </TableCell>
  );
}

function CoverageTableRow({
  contents,
  merge,
}: {
  contents: string[],
  merge: boolean,
}) {
  const [first, second, third, fourth] = contents;

  return (
    <TableRow>
      <TableCell>{first}</TableCell>
      <CoverageTableCell content={second} />
      {merge ? (
        <CoverageTableCell content={third} colSpan={2} />
      ) : (
        <>
          <CoverageTableCell content={third} />
          <CoverageTableCell content={fourth} />
        </>
      )}
    </TableRow>
  );
}

function CoverageTable() {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.width20}>
              単勝端末
            </TableCell>
            <TableCell className={classes.width30}>
              スマートフォン
            </TableCell>
            <TableCell className={classes.width50} colSpan={2}>
              スマートフォン、タブレット端末、モバイルルーター等
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <CoverageTableRow
            contents={['プラン', 'ライト', 'スタンダード']}
            merge
          />
          <CoverageTableRow
            contents={['料金', '550 円', '900 円']}
            merge
          />
          <CoverageTableRow
            contents={['補償台数', '１契約１台', '1 契約５台\n主端末※１台\n副端末４台\n※要登録']}
            merge
          />
          <CoverageTableRow
            contents={['年間補償回数', '無制限', '無制限']}
            merge
          />
          <CoverageTableRow
            contents={['年間補償限度額', '100,000 円', '100,000 円']}
            merge
          />
          <CoverageTableRow
            contents={[
              '修理費用補償額',
              '破損、汚損、故障水濡れ、盗難または紛失により負担した修理費用から自己負担額を差し引いた額\nただし、1 回の修理費用5 万円限度。',
              '主端末は破損、汚損、故障、水濡れ、盗難または紛失により負担した修理費用から自己負担額を差し引いた額。\nただし、10 万円限度。',
              '副端末は破損、汚損、故障、水濡れまたは盗難により負担した修理費用から自己負担額を差し引いた額。\nただし、30,000円を限度。',
            ]}
            merge={false}
          />
          <CoverageTableRow
            contents={[
              'データ復旧費用補償額',
              '破損、汚損、故障または水濡れにより負担したデータ復旧費用から自己負担額を差し引いた額\nただし、1 回の修理費用5 万円限度。',
              '主端末は破損、汚損、故障または水濡れにより負担したデータ復旧費用から自己負担額を差し引いた額。\nただし、10 万円限度。',
              '副端末は補償されません。',
            ]}
            merge={false}
          />
          <CoverageTableRow
            contents={[
              '修理不能補償額',
              '最大25,000円を限度に新規購入した端末の購入価格と登録端末価格の低い方の金額を補償',
              '最大25,000円を限度に新規購入した端末の購入価格と登録端末価格の低い方の金額を補償',
              '最大12,500円を限度に補償、登録端末価格が限度額より低い場合は登録端末価格を上限に補償',
            ]}
            merge={false}
          />
          <CoverageTableRow
            contents={[
              '盗難補償額',
              '最大25,000円を限度に新規購入した端末の購入価格と登録端末価格の低い方の金額を補償',
              '最大25,000円を限度に新規購入した端末の購入価格と登録端末価格の低い方の金額を補償',
              '最大12,500円を限度に補償、登録端末価格が限度額より低い場合は登録端末価格を上限に補償',
            ]}
            merge={false}
          />
          <CoverageTableRow
            contents={[
              '紛失補償額',
              '最大25,000円を限度に新規購入した端末の購入価格と登録端末価格の低い方の金額を補償',
              '最大25,000円を限度に新規購入した端末の購入価格と登録端末価格の低い方の金額を補償',
              '副端末は補償されません。',
            ]}
            merge={false}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function TermsOfUse() {
  const classes = useStyles();

  return (
    <div>
      <Title>
        利用規約
      </Title>
      <SubTitle>第１条（規約の適用）</SubTitle>
      <OrderedList
        contents={[
          '「スマホの保険証」ご利用規約（以下「本規約」といいます。）は、株式会社ギア（以下「当社」といいます。）が提供する「スマホの保険証」（以下「本サービス」といいます。）の利用に適用されるものとします。',
          '本サービスに関し、本規約に定める内容と当社が別途個別規定に定める内容が異なる場合には、個別規定に定める内容が優先して適用されるものとします。',
        ]}
      />

      <SubTitle>第２条（本規約の変更）</SubTitle>
      <Paragraphs
        contents={[
          '当社は、当社所定の方法にて通知または公表することにより、本規約の全部または一部を変更することができるものとします。この場合、変更後の規約の通知または公表時において変更の効力が生じ、以後変更後の規約が適用されるものとします。',
        ]}
      />
      <SubTitle>第３条（本サービス）</SubTitle>
      <Paragraphs
        contents={[
          '本サービスは、契約者（注１）が所有または使用する対象端末（以下「対象端末」といいます。）に関して、補償期間中に故障・破損・水濡れ・盗難・紛失（以下、「補償事故」といいます。）により生じた損害に対して、次のいずれかに該当した場合に、契約者に補償金を給付するサービス（以下「補償」といいます。）をいいます。ただし、選択プランまたは対象端末区分により補償事故の範囲および給付する補償金が異なります。',
          '（１）修理費用を負担した場合（注２）',
          '（２）修理不能（全損）（注３）となった場合であって、対象端末と同機種の新しい端末を購入した場合',
          '（３）データ復旧費用（注４）を負担した場合',
        ]}
      />
      <Remark
        content="（注１）当社と本規約に基づく契約（以下「本契約」といいます。）を締結し、規約上の様々な権利や義務を有する者であって、高校生を除く満 18 歳以上の者をいいます。"
      />
      <Remark
        content="（注２）盗難または紛失により対象端末が発見または回収された場合において、回収されるまでの間に生じた破損・汚損等による損害に対する修理費用を含みます。"
      />
      <Remark
        content="（注３）盗難または紛失により対象端末が発見または回収されなかった場合を含みます。ただし、補償金を給付する前に盗難または紛失した当該対象端末が回収された場合を除きます。"
      />
      <Remark
        content="（注４）補償事故（盗難または紛失を除きます。）により対象端末に保存しているデータ（対象端末本体に挿入して使用する補助記憶装置のデータを含みます。）の全部または一部が消滅したため、そのデータの復旧を行った場合（実際にデータの復旧が出来たか否かは問いません。）の費用をいいます。"
      />

      <SubTitle>第４条（契約の単位）</SubTitle>
      <Paragraphs
        contents={[
          '当社は、対象端末ごとに本契約を締結するものとします。',
        ]}
      />

      <SubTitle>第５条（契約申し込みの方法）</SubTitle>
      <Paragraphs
        contents={[
          '本サービスの申込みは、本規約に同意の上、当社所定の方法により行うものとします。',
        ]}
      />

      <SubTitle>第６条（契約申し込みの承諾）</SubTitle>
      <OrderedList
        contents={[
          '当社は、契約者に対して、当社の定める基準に基づき、審査の上、本サービスの申し込みを承諾するものとします。',
          '当社は、前項の規定にかかわらず、契約者が次のいずれかに該当すると当社が判断した場合、申し込みを承諾しないことがあります。\n（１）契約者に本サービスを提供することが運用上著しく困難なとき。\n（２）契約者が当社の提供するその他サービスの料金の支払を現に怠り、または怠るおそれがあるとき。\n（３）契約者が申し込みの際に虚偽の事項を申告したとき。\n（４）当社が不適切と判断したとき。\n（５）その他当社の業務遂行上著しく支障があるとき。\n（６）過去に契約者が、本契約または当社の提供するその他サービスに係る契約を当社により解除されたことがあったとき。',
        ]}
      />

      <SubTitle>第７条（契約期間）</SubTitle>
      <Paragraphs
        contents={[
          '当社は、本サービスの申し込みを承諾する場合、当社所定の方法により契約者に対して本サービスの利用開始日を通知するものとし、本サービスにおける契約期間は当該利用開始日より起算されるものとします。',
        ]}
      />

      <SubTitle>第８条（本サービスの利用手続き）</SubTitle>
      <OrderedList
        contents={[
          '契約者が本サービスにおける補償の請求を行うときは、本規約の別紙（以下「別紙」といいます。）に定める方法により、当社に申請していただきます。',
          '当社は、契約者から補償の請求を受けたときは、当社所定の方法により、対象端末の補償事故等の事実を調査します。',
          '前項に定める当社が行う調査において、契約者から各種情報の提供をしていただく可能性があります。係る協力が得られない場合には、本サービスにおける補償の履行が遅延または不可と判断される場合があります。',
        ]}
      />

      <SubTitle>第９条（補償の実施）</SubTitle>
      <OrderedList
        contents={[
          '当社は、前条に定める手続き完了後、速やかに補償を実施します。ただし、別紙に特段の定めがある場合はこの限りではありません。',
          '本サービスにおける補償の実施方法については、当社所定の方法により行うこととし、契約者へ別紙に定める補償金を給付することにより完了するものとします。',
        ]}
      />

      <SubTitle>第１０条（営業活動の禁止）</SubTitle>
      <Paragraphs
        contents={[
          '契約者は、本サービスを使用して、有償、無償を問わず、営業活動、営利を目的とした利用、付加価値サービスの提供またはその準備を目的とした利用をすることはできません。',
        ]}
      />

      <SubTitle>第１１条（提供停止）</SubTitle>
      <OrderedList
        contents={[
          '当社は、次の場合には、本サービスの提供を停止・変更することがあります。\n（１）天災・戦争・動乱などにより本サービスの継続が困難であると判断したとき。\n（２）当社にて本サービスの提供が困難であると判断したとき。\n（３）その他当社が本サービスの運用を停止することが望ましいと判断したとき。',
          '当社は、前項の規定により本サービスの提供を中止するときは、当社が指定するホームページ等により、その旨の周知を行います。ただし、緊急やむを得ない場合は、この限りではありません。',
        ]}
      />

      <SubTitle>第１２条（利用停止）</SubTitle>
      <OrderedList
        contents={[
          '当社は、契約者が次のいずれかに該当するときには、本サービスの利用を停止することがあります。\n（１）当社が指定するサービスの料金その他の債務について、支払期日を経過してもなお支払いがないとき。\n（２）当社が指定するサービスの料金その他の債務の決済に使用するクレジットカードの利用が認められないとき。\n（３）当社が指定するサービスの解約がなされたとき。\n（４）本契約に関連して虚偽の事項を通知したことが判明したとき。\n（５）第１０条（営業活動の禁止）および第２３条（利用に係る契約者の義務）の規定に違反する行為を行ったと当社が認めたとき。\n（６）当社の名誉若しくは信用を毀損したとき。\n（７）その他本規約に反する行為であって、本サービスに関する当社の業務の遂行に支障を及ぼし、または及ぼすおそれがある行為をしたとき。\n（８）その他当社または当社の委託により本サービスに関する契約事務を行う者に損害を与える行為を行ったと当社が認めたとき。',
          '当社は、何ら催告なく、前項の規定により本サービスの利用停止をすることができるものとします。',
        ]}
      />

      <SubTitle>第１３条（本サービス提供の終了）</SubTitle>
      <OrderedList
        contents={[
          '当社は、当社またはその他委託事業者の事情により本サービスを継続的かつ安定的に提供することが著しく困難な場合は、本サービスの提供を終了することがあります。',
          '前項の規定により、当社が本サービスの提供を終了し、本サービスの提供の終了に伴い本契約を解除する場合は、当社が指定するホームページ等によりその旨周知を行います。ただし、緊急やむを得ない場合は、この限りではありません。',
          '前項により当社がサービスの提供を終了した場合、当社は契約者に対し、何ら責任を負わないものとします。',
        ]}
      />

      <SubTitle>第１４条（契約者による契約解除）</SubTitle>
      <OrderedList
        contents={[
          '契約者は、いつでもこの契約を解約することができます。',
          '契約者が解約を希望する場合は、当社所定の方法で請求することとします。',
          '保険契約者が解約請求した場合、解約請求日を解約日とし、保険契約は解約日の属するの翌月 1 日から将来に向かって効力を失います。',
        ]}
      />

      <SubTitle>第１５条（当社による契約解除）</SubTitle>
      <OrderedList
        contents={[
          '当社は、第１２条（利用停止）の規定により本サービスの利用を停止された契約者が、なおその事実を解消しないとき、本契約を解除することがあります。ただし、第１２条第１項のいずれかに該当する場合に、その事実が当社の業務に著しい支障を及ぼすと判断したときは、何ら催告なく本サービスの利用停止をしないで本契約を解除できるものとします。',
          '当社は、契約者が第２３条（利用に係る契約者の義務）に違反する行為を行った場合、とくに当該行為の解消に係る催告を要せず、直ちに、本契約を解除することがあります。',
          '当社は、契約者に次に定める事由のいずれかが発生したとき、何ら催告なく本契約を解除できるものとします。\n（１）支払停止状態に陥った場合その他財産状態が悪化しまたはそのおそれがあると認められる相当の理由がある場合\n（２）手形交換所の取引停止処分を受けた場合\n（３）差押、仮差押、仮処分、競売、租税滞納処分の申立を受けた場合\n（４）破産、民事再生手続開始、会社更生手続開始若しくは特別清算開始の申立を受け、または自ら申立をした場合\n（５）暴力団、暴力団員、暴力団構成員、暴力団関係企業、総会屋、社会運動標榜ゴロ、特殊知能暴力団またはこれらに準ずる反社会的な集団または個人(以下、暴力団等)、公共の福祉に反する活動を行う団体、およびその行為者である場合、または、反社会的勢力であったと判明した場合。',
          '当社は、第１３条（本サービス提供の終了）に基づく本サービスの終了に伴い、本契約を解除することがあります。',
        ]}
      />

      <SubTitle>第１６条（料金）</SubTitle>
      <Paragraphs
        contents={[
          '当社が提供する本サービスの料金は、別紙に定めるところによります。',
        ]}
      />

      <SubTitle>第１７条（料金の支払義務）</SubTitle>
      <OrderedList
        contents={[
          '契約者は、契約を当社が承諾した日と、承諾日の属する月の翌月以降の毎月末日に、別 紙で規定する月額料金の支払いを要します。',
          '前項の期間において、第１２条（利用停止）の利用停止その他契約者の責に帰すべき事由により本サービスを利用することができない状態が生じた場合においても、契約者は、その期間中の月額料金の支払いを要します。',
        ]}
      />

      <SubTitle>第１８条（料金等の支払）</SubTitle>
      <Paragraphs
        contents={[
          '契約者は、本契約に定める料金について、当社が定める支払期日までに、当社が指定する金融機関等において支払っていただきます。この場合において、支払いに要する振込み手数料等は契約者の負担とします。',
        ]}
      />

      <SubTitle>第１９条（消費税相当額の加算）</SubTitle>
      <Paragraphs
        contents={[
          '本契約に基づき料金の支払いを要するものとされている金額は、消費税相当額を加算した額とします。なお、消費税率の変動があった場合には変動後の税率を適用し加算します。',
        ]}
      />

      <SubTitle>第２０条（免責事項）</SubTitle>
      <Paragraphs
        contents={[
          '契約者が本規約に違反したことによって生じた損害について、当社は一切の責任を負わないもとします。',
        ]}
      />

      <SubTitle>第２１条（補償の対象外）</SubTitle>
      <OrderedList
        contents={[
          '当社は、次の各号のいずれかの事由に該当する場合は、補償を行いません。\n'
          + '（１）契約者、もしくは契約者（これらの者を含む）の法定代理人の故意、重大な過失、法令違反に起因する補償事故\n'
          + '（２）契約者の親族、使用人、同居人の故意、重大な過失、法令違反に起因する補償事故\n'
          + '（３）補償金を詐取する目的において補償履行の請求をした場合、または本契約を締結した場合\n'
          + '（４）補償事故発生日から 3 年間を経過した請求\n'
          + '（５）第２０条（免責事項）に定める本サービスの免責期間中、または利用開始日前に発生した補償事故\n'
          + '（６）本契約の解除、終了した後に発生した補償事故\n'
          + '（７）契約者が契約者の資格を有していないときに発生した補償事故\n'
          + '（８）第３条（２）に定める修理不能時において、同機種以外の対象端末を新規購入した場合（製造中止、販売中止の端末については、その一部を除きます。）\n'
          + '（９）本サービスの利用停止中に発生した補償事故\n'
          + '（１０）対象端末が当社指定の端末でなかった場合（対象端末は別紙）\n'
          + '（１１）別紙に定めるプラン「スタンダード」の適用時であって、対象端末が副端末の場合における、対象端末の紛失。\n'
          + '（１２）当社指定の書類の提出が当社にて確認できない場合\n'
          + '（１３）公的機関その他債権者による差し押さえ、その他保全処分、執行等の法令上の手続に起因する補償事故\n'
          + '（１４）地震、噴火、風水災、その他の自然災害に起因する補償事故\n'
          + '（１５）戦争、外国の武力行使、革命、政権奪取、内乱、武装反乱その他これらに類似の事変または暴動（群集または多数の者の集団の行動によって、全国または一部の地区において著しく平穏が害され、治安維持上重大な事変と認められる状態をいいます。）に起因する補償事故\n'
          + '（１６）契約者が本契約の定めに違反した場合、または当該違反により本契約が解除された場合\n'
          + '（１７）前各号の原因等について虚偽の報告、その他不当に補償金を取得しようとしたこと、または取得したことが明らかになった場合\n'
          + '（１８）その他当社が補償金の給付を不適切と判断した場合\n'
          + '（１９）対象端末が、日本国内で販売されたメーカー純正の製品以外の場合（携帯電話通信会社で販売した製品または日本法人を設立しているメーカーの純正製品は除きます。）\n'
          + '（２０）対象端末を知人・オークション等から購入・譲受した場合\n'
          + '（２１）第３条（２）に定める修理不能時において、対象端末と同機種の新しい端末を知人・オークション等から購入・譲受した場合\n'
          + '（２２）対象端末を契約者自ら加工・改造した場合\n'
          + '（２３）対象端末の付属品・、バッテリー等の消耗品、またはソフトウェア・周辺機器等の、故障、破損、または交換の場合\n'
          + '（２４）バックパネルに関わる修理の場合\n'
          + '（２５）対象端末の欠陥・瑕疵による故障等の場合（初期不良を含みます。）\n'
          + '（２６）すり傷、汚れ、しみ、焦げ等対象端末の本体機能に直接関係のない外形上の損傷\n'
          + '（２７）対象端末にかかった、修理費用以外の費用に関する請求（見積り取得に関する費用・送料・Apple エクスプレス等交換サービス利用料など）\n'
          + '（２８）対象端末の自然の消耗もしくは劣化または性質によるさび、かび、変質、変色、蒸れ、腐敗、腐食、ひび割れ、剥がれその他類似の事由または自然発熱の損害\n'
          + '（２９）対象端末に対する修理、清掃、解体、据付、組立、点検、検査、試験または調整等の作業上の過失または技術の拙劣\n'
          + '（３０）副端末において加入日から５年以上前に発売された端末。ただし、加入日から１年以内に対象端末を購入したことが証明できればその限りではない。',
          '前項に規定するほか、次の各号のいずれかの事由に該当する場合は、盗難による損害に対する補償を行いません。\n（１）契約者の親族、使用人、同居人が自ら行いまたは加担した盗難\n（２）盗難発生後６０日以内に盗難の事実を発見することができなかった盗難',
          '第１項に規定するほか、次の各号のいずれかの事由に該当する場合は、契約者が負担したデータ復旧費用の補償を行いません。\n（１）契約者および契約者の親族、使用人、同居人の対象端末の誤使用・誤操作\n（２）契約者の許諾を得て対象端末を使用した者の誤使用・誤操作',
        ]}
      />

      <SubTitle>
        第２２条（料金の増額または補償すべき金額の減額もしくは補償金の削減）
      </SubTitle>
      <OrderedList
        contents={[
          '当社は、当社の収支に著しく影響を及ぼす事態が発生した場合は、契約期間の途中において、当社の定めるところにより、料金の増額または補償すべき金額の減額を行うことがあります。また、当社の定めるところにより、補償金の削減給付を行うことがあります。',
          '当社は、前項の適用を行う場合は、速やかに保険契約者にその旨を通知します。',
        ]}
      />

      <SubTitle>第２３条（利用に係る契約者の義務）</SubTitle>
      <OrderedList
        contents={[
          '契約者は、本サービスにおいて補償の実施を請求するにあたり、次の各号に定める条件を満たしていただきます。ただし、契約者が次の条件を満たしている場合であっても、契約者のご利用状況によっては補償の実施が提供できない場合があります。\n（１）契約者自身による本サービスの補償の実施の請求であること。\n（２）本サービスの実施に必要なお客様番号等の契約者情報等が用意されていること。',
          '前項の規定のほか、契約者は次のことを守っていただきます。\n（１）本サービスを違法な目的で利用しないこと。\n（２）本契約の定めに違反しないこと。\n（３）第三者になりすまして本サービスを利用する行為をしないこと。\n（４）本サービスおよびその他当社の事業の運営に支障をきたすおそれのある行為をしないこと。\n（５）法令、公序良俗に反する行為、当社若しくは第三者の信用を毀損する行為、または当社若しくは第三者に不利益を与える行為をしないこと。\n（６）その他前各号に該当する恐れのある行為またはこれに類する行為を行わないこと。\n（７）盗取されたまたは紛失した対象端末を発見または回収した場合は、直ちにその旨を当社に通知すること。',
        ]}
      />

      <SubTitle>第２４条（契約者の申込情報等の変更）</SubTitle>
      <OrderedList
        contents={[
          '契約者は、当社へ届け出ている住所、電話番号、クレジットカード番号、その有効期限に変更があるときは、事前に当社所定の変更手続きを行うものとします。',
          '前項の手続が行われなかったこともしくは手続の遅滞により、契約者が通信不能等の不利益を被ったとしても、当社は一切の責任を負わないものとします。',
        ]}
      />

      <SubTitle>第２５条（自己責任の原則）</SubTitle>
      <OrderedList
        contents={[
          '契約者は、契約者による本サービスの利用とその利用によりなされた一切の行為とその結果について一切の責任を負うものとします。',
          '契約者は、本サービスの利用に伴い、第三者に対して損害を与えた場合、または第三者からクレームが通知された場合、自己の責任と費用をもって処理解決するものとし、当社に対しいかなる責任も負担させないものとします。契約者が本サービスの利用に伴い、第三者から損害を受けた場合または第三者に対しクレームを通知する場合においても同様とします。',
          '契約者は、第三者の行為に対する要望、疑問もしくはクレームがある場合は、当該第三者に対し、直接その旨を通知するものとし、その結果については、自己の責任と費用をもって処理解決するものとします。',
          '当社は、契約者がその故意または過失により当社に損害を被らせたときは、契約者に当該損害の賠償を請求することができるものとし、契約者は当社の請求に基づき、直ちに当該損害を賠償するものとします。',
        ]}
      />

      <SubTitle>第２６条（必要書類等の準備）</SubTitle>
      <Paragraphs
        contents={[
          '契約者は、自己の責任において、本サービスを利用するために必要な情報等を保持し管理するものとします。',
        ]}
      />

      <SubTitle>第２７条（個人情報の取り扱い）</SubTitle>
      <Paragraphs
        contents={[
          '当社は、本サービスにおいて取得した個人情報については、本契約締結時に契約書に同意を得ました、当社「プライバシーポリシー」に基づき取り扱うものとします。',
        ]}
      />

      <SubTitle>第２８条（法令に規定する事項）</SubTitle>
      <Paragraphs
        contents={[
          '本サービスの提供または利用にあたり、法令の定めがある事項については、その定めるところによります。',
        ]}
      />

      <SubTitle>第２９条（合意管轄）</SubTitle>
      <Paragraphs
        contents={[
          '当社は、契約者と当社の間で本規約に関して訴訟の必要が生じた場合には、訴額に応じて、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。',
        ]}
      />

      <SubTitle>附則（実施期日）</SubTitle>
      <Paragraphs
        contents={[
          '本規約は、令和２年１２月０１日から実施します。',
          '令和４年０３月０１日一部改定',
          '令和４年１２月０１日一部改定',
        ]}
      />

      <Title>
        別 紙
      </Title>

      <SubTitle>対象端末の表示</SubTitle>
      <Paragraphs
        contents={[
          '契約者が所有または使用する日本国内で販売されたインターネットに接続することができる機能を持つ製造物（（製造物責任法（平成六年七月一日法律第八十五号）に定める定義をいいます。）製造業者等が製造、加工または輸入した製造物）。ただし、機器によっては別途当社の任意の判断により対象端末と判断されない場合があります。',
        ]}
      />

      <SubTitle>本サービスの利用方法</SubTitle>
      <Paragraphs
        contents={[
          '本サービスの、利用方法は、以下の通りとなります。',
          '本サービスの利用方法に関するご相談は、「スマホの保険証コールセンター」へご連絡ください。',
          '受付時間：10：00～19：00（土日祝日、年末年始を除く）',
        ]}
      />

      <ol className={classes.orderedList}>
        <Typography variant="body1" component="li">
          本サービスにおける補償の実施においては、当社所定の申込書、および審査の過程で必要となる以下の書類を提出していただきます。
        </Typography>

        <ServiceTable />

        <Typography variant="body1" component="li">
          前項に定める他、当社は本サービスにおける補償の実施にあたり、以下の事実確認を行
          います。なお、警察、消防等の公の機関に対して当該期間の指定する方法による照会が
          必要な場合、以下の事実確認後、補償の実施請求を受けた日から 60 日を経過する日ま
          でに契約者に補償金を給付します。
          <br />
          (a)補償事故に該当する事実の有無
          <br />
          (b)損害の額および補償事故との因果関係
          <br />
          (c)補償事故発生原因（契約者の関与の有無、その他の事情）
          <br />
          (d)補償事故の発生後の契約者その他関係者の対応方法
        </Typography>
      </ol>

      <SubTitle>本サービス適用条件</SubTitle>
      <ol className={classes.orderedList}>
        <Typography variant="body1" component="li">
          補償支払対象 ： 1 契約につき 1 台～最大 5 台
        </Typography>
        <Typography variant="body1" component="li">
          月額料金 ： 550 円、900 円 ※選択プランによるものとします。
        </Typography>
        <Typography variant="body1" component="li">
          補償責任開始日 ： 保険契約の申込を当社が承諾し、初回保険料が決済された日の翌日とします。※副端末は登録完了日より1ヶ月経過日から補償開始となります。
        </Typography>
        <Typography variant="body1" component="li">
          補償期間：補償期間開始日（保険契約の申込を当社が承諾し、初回保険料が決済された日の属する月の翌月 1 日をいいます。）から１年（自動更新）とします。
        </Typography>
        <Typography variant="body1" component="li">
          補償額および商品内容
        </Typography>
        <CoverageTable />
        <Typography variant="body1" component="li">
          1 年間の支払い上限額
          <br />
          １年間の支払い上限額は１０万円まで（※プランによって１事故あたりの支払い上限額は変わります。）
        </Typography>
        <Typography variant="body1" component="li">
          自己負担額
          <br />
          補償金の給付にあたって差し引かれる金額であって、契約者が負担した修理費用またはデータ復旧費用の３０％とします。ただし、１０,０００円を上限とします。
        </Typography>
        <Typography variant="body1" component="li">
          他の補償契約がある場合の補償金の給付額
          <br />
          対象端末に生じた補償事故による損害に対して他の補償契約等（他の類似の補償制度および保険契約等をいい、その名称・種類・内容等の如何を問いません。）から既に補償金
          ・保険金等の給付を受けている場合には、当社から給付する補償金からその金額を控除した金額を控除します。
        </Typography>
        <Typography variant="body1" component="li">
          正規修理店での交換修理は本体交換となりますので、修理見舞金扱いとなります。
        </Typography>
        <Typography variant="body1" component="li">
          対象端末
          <br />
          スマートフォン、タブレット端末、スマートウォッチ、モバイルゲーム機、モバイル音楽プレーヤー、
          パソコン、Wi-Fi ルーター、デジタルカメラ、ワイヤレスイヤフォン
          ※Apple Pencil、Air Pods、iPad シリーズ、Nintendo Switch、AIBO なども対象
        </Typography>
      </ol>
    </div>
  );
}
