import { connect } from 'react-redux';
import { AppState, AppActions } from '../../reducers';
import { Plan } from '../../reducers/form';
import PlanForm from '../../components/PlanForm';

const mapStateToProps = ({ form: { plan } }: AppState) => ({ plan });

const mapDispatchToProps = {
  onChange(payload: Plan): AppActions {
    return {
      type: 'FORM/SET_PLAN',
      payload,
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanForm);
