import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(2),
  },
}));

export default function SummaryText({ children }: { children: string | string[] }) {
  const classes = useStyles();

  return (
    <Typography className={classes.text}>
      {children}
    </Typography>
  );
}
