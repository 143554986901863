import React from 'react';
import Label from './SummaryLabel';
import Text from './SummaryText';

export interface SummaryItemProps {
    label: string;
    text: string;
}

export default function SummaryItem({ label, text }: SummaryItemProps) {
  return (
    <>
      <Label>{label}</Label>
      <Text>{text}</Text>
    </>
  );
}
