import { connect } from 'react-redux';
import { AppState, AppActions } from '../../reducers';
import { Severity } from '../../reducers/alert';
import DeviceForm from '../../components/DeviceForm';

type IDevice = AppState['form']['device'];

type SetDevicePayload = Partial<{
    [T in keyof IDevice]: IDevice[T]['value'];
}>;

const mapStateToProps = ({
  form: { device },
}: AppState) => ({
  ...device,
});

const mapDispatchToProps = {
  setDevice(payload: SetDevicePayload): AppActions {
    return {
      type: 'FORM/SET_DEVICE',
      payload,
    };
  },

  showErrAlert(title: string, messages: string[]): AppActions {
    return {
      type: 'ALERT/SHOW',
      payload: {
        severity: Severity.Error,
        title,
        messages,
      },
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceForm);
