import { connect } from 'react-redux';
import { AppState, AppActions } from '../../reducers';
import AnnounceForm, { AnnouncePayload } from '../../components/AnnounceForm';

const mapStateToProps = ({
  form: { announce },
}: AppState) => ({
  ...announce,
});

const mapDispatchToProps = {
  setAnnounce(payload: AnnouncePayload): AppActions {
    return {
      type: 'FORM/SET_ANNOUNCE',
      payload,
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnounceForm);
