import { Action } from 'redux';

const START = 'LOADING/START';
const FINISH = 'LOADING/FINISH';

interface IStartAction extends Action {
    type: typeof START;
}

interface IFinishAction extends Action {
    type: typeof FINISH;
}

export type LoadingActions = IStartAction | IFinishAction;

export default function reducer(
  state: boolean = false,
  action: LoadingActions,
): boolean {
  switch (action.type) {
    case START:
      return true;
    case FINISH:
      return false;
    default:
      return state;
  }
}
