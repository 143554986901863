import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneIcon from '@material-ui/icons/Done';
import { green } from '@material-ui/core/colors';
import Label from './SummaryLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  text: {
    marginBottom: theme.spacing(2),
  },
}));

export interface SummaryAnnounceProps {
  damaged: boolean;
  remark: string;
}

const ANNOUNCE_WORK = '端末は正常に動作します';
const ANNOUNCE_NOT_DAMAGED = '端末に損傷はありません';
const ANNOUNCE_DAMAGED = '端末に損傷があります';

export default function SummaryAnnounce({
  damaged,
  remark,
}: SummaryAnnounceProps) {
  const classes = useStyles();

  const announces = damaged
    ? [ANNOUNCE_WORK, ANNOUNCE_NOT_DAMAGED]
    : [ANNOUNCE_WORK, ANNOUNCE_DAMAGED];

  return (
    <Card className={classes.root}>
      <CardContent>
        <List>
          {announces.map((term) => (
            <ListItem key={term}>
              <ListItemIcon>
                <DoneIcon
                  style={{
                    color: green[500],
                    fontSize: 30,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={term}
              />
            </ListItem>
          ))}
        </List>
        {!damaged && (
          <>
            <Label>端末の状態</Label>
            <Typography paragraph className={classes.text}>
              {remark}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
}
