import addMonths from 'date-fns/addMonths';
import setDate from 'date-fns/setDate';
import endOfMonth from 'date-fns/endOfMonth';

interface DateComponents {
    year: number;
    month: number;
    day: number;
}

export function defaultBirthday(baseDate = new Date()): Date {
  const month = baseDate.getMonth();
  const date = baseDate.getDate();
  return new Date(1995, month, date);
}

export function dateComponents(val: string): DateComponents {
  let year: string;
  let month: string;
  let day: string;

  if (val.indexOf('/') === -1 && val.indexOf('-') === -1) {
    year = val.slice(0, 4);
    month = val.slice(4, 6);
    day = val.slice(6);
  } else {
    [year, month, day] = val.replace(/-/g, '/').split('/');
  }

  return {
    year: /\D/.test(year) ? Number.NaN : Number.parseInt(year, 10),
    month: /\D/.test(month) ? Number.NaN : Number.parseInt(month, 10) - 1,
    day: /\D/.test(day) ? Number.NaN : Number.parseInt(day, 10),
  };
}

export function isDate(val: string): boolean {
  if (val.length < 8) return false;

  const { year, month, day } = dateComponents(val);
  if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
    return false;
  }

  if (month < 0 || month > 11) {
    return false;
  }

  if (day < 1) {
    return false;
  }

  // NOTE: for April, June, September, November
  if ([3, 5, 8, 10].includes(month)) {
    return day < 31;
  }

  // NOTE: for January, March, May, July, August, October, December
  if ([0, 2, 4, 6, 7, 9, 11].includes(month)) {
    return day < 32;
  }

  // NOTE: for Febrary
  if (year % 4 === 0) {
    return day < 30;
  }

  return day < 29;
}

export function toDate(val: string): Date {
  const { year, month, day } = dateComponents(val);
  return new Date(year, month, day);
}

export function formFormat(val: string): string {
  const { year, month, day } = dateComponents(val);
  return `${year}-${(month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

export function startDate(baseDate: Date = new Date()): Date {
  const nextMonth = addMonths(baseDate, 1);
  return setDate(nextMonth, 1);
}

export function endDate(baseDate: Date): Date {
  const startAt = startDate(baseDate);
  return endOfMonth(addMonths(startAt, 11));
}
