import React, { useState, useCallback, RefObject } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Instruction from '../components/Instruction';
import Checkbox from '../components/Checkbox';
import PlanForm from '../containers/components/PlanForm';
import StepButtons from '../components/StepButtons';
import TermsOfUse from '../components/TermsOfUse';
import { AppState, AppActions } from '../reducers';
import { Plan } from '../reducers/form';
import { StepBase } from './common';

const useStyles = makeStyles((theme) => ({
  buttons: {
    margin: theme.spacing(2),
  },
  termsOfUse: {
    marginTop: theme.spacing(3),
    maxHeight: '40vh',
    overflow: 'scroll',
  },
}));

export type MapStateToProps = AppState['terms'];

type TermKeys = keyof AppState['terms'];

export type MapDispatchToProps = {
  read: (key: TermKeys) => AppActions;
  confirm: (key: TermKeys, checked: boolean) => AppActions;
  back: () => AppActions,
  next: () => AppActions;
  showErrAlert: (title: string, messages: string[]) => AppActions;
};

export interface TermsProps extends MapStateToProps, MapDispatchToProps, StepBase {}

export default function Terms({
  firstStep,
  lastStep,
  term1,
  read,
  confirm,
  back,
  next,
  showErrAlert,
}: TermsProps) {
  const classes = useStyles();
  const [validated, setValidated] = useState(false);
  const handleRead = useCallback(() => {
    read('term1');
  }, [read]);

  const handleChecked = (key: TermKeys) => (checked: boolean) => {
    confirm(key, checked);
  };

  const handleClickNext = () => {
    setValidated(true);

    if (term1.confirm.errors.length === 0) {
      next();
    } else {
      showErrAlert(
        '同意していただけていない項目があります。',
        ['すべてのチェックボックスにチェックをいれてください。'],
      );
    }
  };

  const containerRef: RefObject<HTMLDivElement> = useBottomScrollListener(handleRead);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Instruction>
            商品の内容をご確認ください。
          </Instruction>
          <PlanForm plans={[Plan.Standard, Plan.Light]} />
        </Grid>
        <Grid item xs={12}>
          <Instruction>
            利用規約をご確認ください。
          </Instruction>
          <Typography
            component="p"
            variant="body1"
          >
            利用規約をご確認いただき、内容に同意して頂ける場合はチェックをしてください。
          </Typography>
          <Typography
            paragraph
            component="p"
            variant="body2"
          >
            ※最下部までスクロールしますと、チェックが可能になります。
          </Typography>
          <Checkbox
            label="私は利用規約の内容に同意します。"
            name="term1"
            checked={term1.confirm.value}
            disabled={!term1.read}
            onChecked={handleChecked('term1')}
            validated={validated}
            errors={term1.confirm.errors}
          />
          <Grid container justify="center" spacing={3}>
            <Grid item xs={10} sm={8}>
              <div className={classes.termsOfUse} ref={containerRef}>
                <TermsOfUse />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StepButtons
        onClickBack={back}
        onClickNext={handleClickNext}
        firstStep={firstStep}
        lastStep={lastStep}
      />
    </>
  );
}
