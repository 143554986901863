import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { OnFormChanged, ChangeEventHandler, Input } from '../utils/formHelpers';
import { Gender } from '../reducers/form';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
}));

export interface GenderFormProps {
    genders: Gender[];
    gender: Input<Gender | null>;
    validated: boolean;
    onChange: OnFormChanged;
    name: string;
}

export default function GenderForm({
  genders,
  gender,
  validated,
  onChange,
  name,
}: GenderFormProps) {
  const classes = useStyles();

  const handleChange: ChangeEventHandler = ({ target: { value } }) => {
    onChange('gender', value);
  };

  const hasError = (gender.dirty || validated) && gender.errors.length > 0;

  return (
    <FormControl
      className={classes.formControl}
      component="fieldset"
      error={hasError}
      name={name}
    >
      <FormLabel
        className="MuiInputLabel-shrink"
        required
        component="legend"
      >
        性別
      </FormLabel>
      <RadioGroup
        row
        aria-label="gender"
        value={gender.value}
        onChange={handleChange}
      >
        {genders.map((g) => (
          <FormControlLabel
            key={g}
            value={g}
            control={<Radio color="primary" />}
            label={g}
          />
        ))}
      </RadioGroup>
      {hasError && (
        <FormHelperText>{gender.errors[0]}</FormHelperText>
      )}
    </FormControl>
  );
}
