import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function SummaryLabel({ children }: { children: string }) {
  return (
    <Typography color="textSecondary" variant="body2" gutterBottom>
      {children}
    </Typography>
  );
}
