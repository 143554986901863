import { Action } from 'redux';
import { Input } from '../utils/formHelpers';
import { validators } from '../utils/validators';

export enum Gender {
  Male = '男性',
  FeMale = '女性',
  Other = 'その他',
}

export enum PaymentMethod {
  CreditCard = 'クレジットカード',
  BankTransfer = '口座振込',
}

export enum Plan {
  Standard = 'スタンダード',
  Light = 'ライト',
}

export interface IPerson {
  firstName: Input<string>;
  lastName: Input<string>;
  zipcode: Input<string>;
  prefecture: Input<string>;
  address1: Input<string>;
  address2: Input<string>;
  phone1: Input<string>;
  phone2: Input<string>;
  gender: Input<Gender | null>;
  birthday: Input<string>;
  kanaFirstName: Input<string>;
  kanaLastName: Input<string>;
}

export interface IHolder extends IPerson {
  email: Input<string>;
  emailConfirmation: Input<string>;
}

export interface IInsured extends IPerson {
  sameAllWithHolder: boolean;
}

export interface IAgent {
  agentCode: Input<string>;
  agencyCode: Input<string>;
}

export interface IDevice {
  maker: Input<string>;
  brand: Input<string>;
  model: Input<string>;
  carrier: Input<string>;
  imei: Input<string>;
  price: Input<string>;
  imageFront: Input<string>;
  imageBack: Input<string>;
}

export interface IAnnouce {
  work: Input<boolean>;
  damaged: Input<boolean>;
  remark: Input<string>;
}

interface IState {
  holder: IHolder;
  insured: IInsured;
  agent: IAgent;
  paymentMethod: PaymentMethod;
  plan: Plan,
  device: IDevice,
  announce: IAnnouce,
}

export const initialState: IState = {
  holder: {
    firstName: validators.kanjiName('', false),
    lastName: validators.kanjiName('', false),
    zipcode: validators.zipcode('', false),
    prefecture: validators.prefecture('', false),
    address1: validators.address1('', false),
    address2: validators.address2('', false),
    phone1: validators.phone1('', false),
    phone2: validators.phone2('', false),
    gender: validators.gender(null, false),
    email: validators.email('', false),
    emailConfirmation: { value: '', errors: [], dirty: false },
    birthday: validators.birthday('', false),
    kanaFirstName: validators.kanaName('', false),
    kanaLastName: validators.kanaName('', false),
  },
  insured: {
    sameAllWithHolder: true,
    firstName: validators.kanjiName('', false),
    lastName: validators.kanjiName('', false),
    zipcode: validators.zipcode('', false),
    prefecture: validators.prefecture('', false),
    address1: validators.address1('', false),
    address2: validators.address2('', false),
    phone1: validators.phone1('', false),
    phone2: validators.phone2('', false),
    gender: validators.gender(null, false),
    birthday: validators.birthday('', false),
    kanaFirstName: validators.kanaName('', false),
    kanaLastName: validators.kanaName('', false),
  },
  agent: {
    agentCode: validators.agentCode('', false),
    agencyCode: validators.agencyCode('', false),
  },
  paymentMethod: PaymentMethod.CreditCard,
  plan: Plan.Standard,
  device: {
    maker: validators.device('', false),
    brand: validators.device('', false),
    model: validators.device('', false),
    carrier: validators.device('', false),
    imei: validators.deviceIMEI('', false),
    price: validators.devicePrice('', false),
    imageFront: validators.deviceImageFront('', false),
    imageBack: validators.deviceImageBack('', false),
  },
  announce: {
    work: validators.deviceWork(false, false),
    damaged: validators.deviceDamaged(false, false),
    remark: validators.deviceRemark('', false),
  },
};

const SET_HOLDER = 'FORM/SET_HOLDER';
const SET_INSURED = 'FORM/SET_INSURED';
const SET_AGENT = 'FORM/SET_AGENT';
const SET_PAYMENT = 'FORM/SET_PAYMENT';
const FETCH_ADDRESS = 'FORM/FETCH_ADDRESS';
const SAME_ALL_WITH_HOLDER = 'FORM/SAME_ALL_WITH_HOLDER';
const SET_PLAN = 'FORM/SET_PLAN';
const SET_DEVICE = 'FORM/SET_DEVICE';
const SET_ANNOUNCE = 'FORM/SET_ANNOUNCE';

interface ISetHolderAction extends Action {
  type: typeof SET_HOLDER;
  payload: Partial<{
      [T in keyof IHolder]: IHolder[T]['value'];
  }>;
}

interface ISetInsuredAction extends Action {
  type: typeof SET_INSURED;
  payload: Partial<{
      [T in keyof IInsured]: IInsured[T] extends Input<infer S> ? S : IInsured[T];
  }>;
}

interface ISetAgentAction extends Action {
  type: typeof SET_AGENT;
  payload: Partial<{
      [T in keyof IAgent]: IAgent[T]['value'];
  }>;
}

interface ISetPaymentAction extends Action {
  type: typeof SET_PAYMENT;
  payload: PaymentMethod;
}

interface IFetchAddressAction extends Action {
  type: typeof FETCH_ADDRESS;
  payload: {
      form: keyof IState;
      zipcode: string;
  };
}

interface ISameAllWithHolderAction extends Action {
  type: typeof SAME_ALL_WITH_HOLDER;
}

interface ISetPlanAction extends Action {
  type: typeof SET_PLAN;
  payload: Plan,
}

interface ISetDeviceAction extends Action {
  type: typeof SET_DEVICE;
  payload: Partial<{
      [T in keyof IDevice]: IDevice[T]['value'];
  }>;
}

interface ISetAnnouceAction extends Action {
  type: typeof SET_ANNOUNCE;
  payload: Partial<{
      [T in keyof IAnnouce]: IAnnouce[T]['value'];
  }>;
}

export type FormActions = ISetHolderAction
  | ISetInsuredAction
  | ISetAgentAction
  | ISetPaymentAction
  | IFetchAddressAction
  | ISameAllWithHolderAction
  | ISetPlanAction
  | ISetDeviceAction
  | ISetAnnouceAction;

export default function reducer(state: IState = initialState, action: FormActions): IState {
  switch (action.type) {
    case SET_HOLDER: {
      const {
        firstName,
        lastName,
        zipcode,
        prefecture,
        address1,
        address2,
        phone1,
        phone2,
        gender,
        birthday,
        kanaFirstName,
        kanaLastName,
        email,
        emailConfirmation,
      } = action.payload;

      const { holder } = state;

      const currentEmail = email !== undefined
        ? email : holder.email.value;
      const currentConfirmation = emailConfirmation !== undefined
        ? emailConfirmation : holder.emailConfirmation.value;

      const newHolder: IHolder = {
        firstName: firstName !== undefined
          ? validators.kanjiName(firstName, true) : holder.firstName,

        lastName: lastName !== undefined
          ? validators.kanjiName(lastName, true) : holder.lastName,

        zipcode: zipcode !== undefined
          ? validators.zipcode(zipcode, true) : holder.zipcode,

        prefecture: prefecture !== undefined
          ? validators.prefecture(prefecture, true) : holder.prefecture,

        address1: address1 !== undefined
          ? validators.address1(address1, true) : holder.address1,

        address2: address2 !== undefined
          ? validators.address2(address2, true) : holder.address2,

        phone1: phone1 !== undefined
          ? validators.phone1(phone1, true) : holder.phone1,

        phone2: phone2 !== undefined
          ? validators.phone2(phone2, true) : holder.phone2,

        gender: gender !== undefined
          ? validators.gender(gender, true) : holder.gender,

        birthday: birthday !== undefined
          ? validators.birthday(birthday, true) : holder.birthday,

        kanaFirstName: kanaFirstName !== undefined
          ? validators.kanaName(kanaFirstName, true) : holder.kanaFirstName,

        kanaLastName: kanaLastName !== undefined
          ? validators.kanaName(kanaLastName, true) : holder.kanaLastName,

        email: email !== undefined
          ? validators.email(email, true) : holder.email,

        emailConfirmation: currentEmail === currentConfirmation
          ? { value: currentConfirmation, errors: [], dirty: true }
          : { value: currentConfirmation, errors: ['メールアドレスが一致しません'], dirty: true },
      };

      return {
        ...state,
        holder: newHolder,
      };
    }

    case SET_INSURED: {
      const {
        firstName,
        lastName,
        zipcode,
        prefecture,
        address1,
        address2,
        phone1,
        phone2,
        gender,
        birthday,
        kanaFirstName,
        kanaLastName,
        sameAllWithHolder,
      } = action.payload;

      const { insured } = state;

      const newInsured: IInsured = {
        firstName: firstName !== undefined
          ? validators.kanjiName(firstName, true) : insured.firstName,

        lastName: lastName !== undefined
          ? validators.kanjiName(lastName, true) : insured.lastName,

        zipcode: zipcode !== undefined
          ? validators.zipcode(zipcode, true) : insured.zipcode,

        prefecture: prefecture !== undefined
          ? validators.prefecture(prefecture, true) : insured.prefecture,

        address1: address1 !== undefined
          ? validators.address1(address1, true) : insured.address1,

        address2: address2 !== undefined
          ? validators.address2(address2, true) : insured.address2,

        phone1: phone1 !== undefined
          ? validators.phone1(phone1, true) : insured.phone1,

        phone2: phone2 !== undefined
          ? validators.phone2(phone2, true) : insured.phone2,

        gender: gender !== undefined
          ? validators.gender(gender, true) : insured.gender,

        birthday: birthday !== undefined
          ? validators.birthday(birthday, true) : insured.birthday,

        kanaFirstName: kanaFirstName !== undefined
          ? validators.kanaName(kanaFirstName, true) : insured.kanaFirstName,

        kanaLastName: kanaLastName !== undefined
          ? validators.kanaName(kanaLastName, true) : insured.kanaLastName,

        sameAllWithHolder: sameAllWithHolder !== undefined
          ? sameAllWithHolder : insured.sameAllWithHolder,
      };

      return {
        ...state,
        insured: newInsured,
      };
    }

    case SET_AGENT: {
      const { agentCode, agencyCode } = action.payload;
      const { agent } = state;

      const newAgent: IAgent = {
        agentCode: agentCode !== undefined
          ? validators.agentCode(agentCode, true) : agent.agentCode,

        agencyCode: agencyCode !== undefined
          ? validators.agencyCode(agencyCode, true) : agent.agencyCode,
      };

      return {
        ...state,
        agent: newAgent,
      };
    }

    case SAME_ALL_WITH_HOLDER:
      return {
        ...state,
        insured: {
          ...initialState.insured,
          sameAllWithHolder: true,
        },
      };

    case SET_PAYMENT:
      return {
        ...state,
        paymentMethod: action.payload,
      };

    case SET_PLAN:
      return {
        ...state,
        plan: action.payload,
      };

    case SET_DEVICE: {
      const {
        maker,
        brand,
        model,
        carrier,
        imei,
        price,
        imageFront,
        imageBack,
      } = action.payload;

      const { device } = state;

      const newDevice: IDevice = {
        maker: maker !== undefined
          ? validators.device(maker, true) : device.maker,

        brand: brand !== undefined
          ? validators.device(brand, true) : device.brand,

        model: model !== undefined
          ? validators.device(model, true) : device.model,

        carrier: carrier !== undefined
          ? validators.device(carrier, true) : device.carrier,

        imei: imei !== undefined
          ? validators.deviceIMEI(imei, true) : device.imei,

        price: price !== undefined
          ? validators.devicePrice(price, true) : device.price,

        imageFront: imageFront !== undefined
          ? validators.deviceImageFront(imageFront, true) : device.imageFront,

        imageBack: imageBack !== undefined
          ? validators.deviceImageBack(imageBack, true) : device.imageBack,
      };

      return {
        ...state,
        device: newDevice,
      };
    }

    case SET_ANNOUNCE: {
      const { work, damaged, remark } = action.payload;
      const { announce } = state;

      const newAnnounce: IAnnouce = {
        work: work !== undefined
          ? validators.deviceWork(work, true) : announce.work,

        damaged: damaged !== undefined
          ? validators.deviceDamaged(damaged, true) : announce.damaged,

        remark: remark !== undefined
          ? validators.deviceRemark(remark, true) : announce.remark,
      };

      return {
        ...state,
        announce: newAnnounce,
      };
    }

    default:
      return state;
  }
}
