import React, { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextInput from './TextInput';
import {
  Input,
  FormFilter,
  OnFormChanged,
  zipcodeFilter as zipFilter,
  zenkakuFilter,
  handleChangeFactory,
} from '../utils/formHelpers';
import { PREFECTURES } from '../constants';

const useStyles = makeStyles((theme) => ({
  searchButton: {
    fontWeight: 'bold',
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  prefectureLabel: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 1),
  },
}));

export type FetchAddress = (zipcode: string) => void;

export interface AddressFormProps {
  zipcode: Input<string>;
  prefecture: Input<string>;
  address1: Input<string>;
  address2: Input<string>;
  onChange: OnFormChanged;
  fetchAddress: FetchAddress;
  validated: boolean;
  namePrefix: string;
  textFilter?: FormFilter;
  zipcodeFilter?: FormFilter;
  onChangeFactory?: typeof handleChangeFactory;
  disabled?: boolean;
}

export default function AddressForm({
  zipcode,
  prefecture,
  address1,
  address2,
  onChange,
  fetchAddress,
  validated,
  namePrefix,
  textFilter = zenkakuFilter,
  zipcodeFilter = zipFilter,
  onChangeFactory = handleChangeFactory,
  disabled = false,
}: AddressFormProps) {
  const classes = useStyles();
  const handleChange = onChangeFactory(onChange);

  const hasError: (input: Input<string>) => boolean = (input) => (
    (input.dirty || validated) && input.errors.length > 0
  );

  const handleChangePrefecture = (event: ChangeEvent<{ value: unknown }>) => {
    onChange('prefecture', event.target.value as string);
  };

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12} md={6}>
        <Grid item xs={6}>
          <TextInput
            required
            label="郵便番号"
            placeholder="1600022"
            fullWidth
            name={`${namePrefix}-zipcode`}
            disabled={disabled}
            value={zipcode.value}
            onChange={handleChange('zipcode', zipcodeFilter)}
            helperText={hasError(zipcode) ? zipcode.errors[0] : 'ハイフン不要です'}
            error={hasError(zipcode)}
            inputProps={{ inputMode: 'numeric' }}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            name={`${namePrefix}-search-address`}
            startIcon={<SearchIcon />}
            className={classes.searchButton}
            disabled={zipcode.value.length !== 7 || disabled}
            onClick={() => fetchAddress(zipcode.value)}
          >
            住所検索
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl
          variant="outlined"
          error={hasError(prefecture)}
          disabled={disabled}
          fullWidth
        >
          <InputLabel
            shrink
            required
            className={classes.prefectureLabel}
          >
            都道府県
          </InputLabel>
          <Select
            value={prefecture.value}
            onChange={handleChangePrefecture}
            displayEmpty
            name={`${namePrefix}-prefecture`}
          >
            <MenuItem value="" disabled>選択してください</MenuItem>
            {PREFECTURES.map((val) => (
              <MenuItem key={val} value={val}>{val}</MenuItem>
            ))}
          </Select>
          {hasError(prefecture) && (
          <FormHelperText>{prefecture.errors[0]}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          required
          label="住所１"
          fullWidth
          name={`${namePrefix}-address1`}
          placeholder="東京都新宿区新宿３−３５−３"
          disabled={disabled}
          value={address1.value}
          onChange={handleChange('address1', textFilter)}
          helperText={hasError(address1) ? address1.errors[0] : undefined}
          error={hasError(address1)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          label="住所２"
          fullWidth
          name={`${namePrefix}-address2`}
          placeholder="森治ビル３F"
          value={address2.value}
          onChange={handleChange('address2', textFilter)}
          helperText={hasError(address2) ? address2.errors[0] : undefined}
          error={hasError(address2)}
        />
      </Grid>
    </Grid>
  );
}
