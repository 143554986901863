import { Action } from 'redux';

export enum InputMode {
  Normal,
  Direct,
}

const SET = 'MODE/SET';

interface ISetAction extends Action {
  type: typeof SET;
  payload: InputMode;
}

export type ModeActions = ISetAction;

export const initialState = InputMode.Normal;

export default function reducer(
  state: InputMode = initialState,
  action: ModeActions,
): InputMode {
  switch (action.type) {
    case SET:
      return action.payload;
    default:
      return state;
  }
}
