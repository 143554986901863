import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import format from 'date-fns/format';
import Item from './SummaryItem';
import { Gender } from '../reducers/form';
import { formFormat } from '../utils/date';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
  },
}));

export interface SummaryPersonProps {
    firstName: string;
    lastName: string;
    zipcode: string;
    prefecture: string;
    address1: string;
    address2: string;
    phone1: string;
    phone2: string;
    gender: Gender | null;
    birthday: string;
    kanaFirstName: string;
    kanaLastName: string;
    email?: string;
}

export default function SummaryPerson({
  firstName,
  lastName,
  zipcode,
  prefecture,
  address1,
  address2,
  phone1,
  phone2,
  gender,
  birthday,
  kanaFirstName,
  kanaLastName,
  email = '',
}: SummaryPersonProps) {
  const classes = useStyles();
  const hasEmail = email !== '';
  const hasPhone2 = phone2 !== '';
  const formatedBirthday = birthday !== ''
    ? format(new Date(formFormat(birthday)), 'yyyy/M/d')
    : '';

  return (
    <Card className={classes.root}>
      <CardContent>
        <Item
          label="氏名"
          text={`${lastName}  ${firstName}`}
        />
        <Item
          label="カナ氏名"
          text={`${kanaLastName}  ${kanaFirstName}`}
        />
        <Item
          label="性別"
          text={gender || ''}
        />
        <Item
          label="生年月日"
          text={formatedBirthday}
        />
        <Item
          label="郵便番号"
          text={`${zipcode.slice(0, 3)}-${zipcode.slice(3)}`}
        />
        <Item
          label="住所"
          text={`${prefecture}${address1}${address2}`.trim()}
        />
        {hasEmail && (
        <Item
          label="メールアドレス"
          text={email}
        />
        )}
        <Item
          label={hasPhone2 ? '電話番号１' : '電話番号'}
          text={phone1}
        />
        {hasPhone2 && (
        <Item
          label="電話番号２"
          text={phone2}
        />
        )}
      </CardContent>
    </Card>
  );
}
