import DateFnsUtils from '@date-io/date-fns';

export default class JaLocaleUtils extends DateFnsUtils {
  public getCalendarHeaderText(date: Date) {
    return this.format(date, 'yyyy年 M月');
  }

  public getDatePickerHeaderText(date: Date) {
    return this.format(date, 'M/d');
  }
}
