import { Action } from 'redux';

export type AddressKind = 'holder' | 'insured';

export interface IAddress {
    prefecture: string;
    address1: string;
}

interface IState {
    open: boolean;
    kind: AddressKind;
    items: IAddress[];
}

export const initialState: IState = {
  open: false,
  kind: 'holder',
  items: [],
};

const OPEN = 'ADDRESSES/OPEN';
const CLOSE = 'ADDRESSES/CLOSE';

interface IOpenAction extends Action {
    type: typeof OPEN;
    payload: {
        kind: AddressKind;
        items: IAddress[];
    };
}

interface ICloseAction extends Action {
    type: typeof CLOSE;
}

export type AddressesActions = IOpenAction | ICloseAction;

export default function reducer(
  state: IState = initialState,
  action: AddressesActions,
): IState {
  switch (action.type) {
    case OPEN:
      return {
        open: true,
        kind: action.payload.kind,
        items: action.payload.items,
      };
    case CLOSE:
      return initialState;
    default:
      return state;
  }
}
