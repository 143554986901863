import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Label from './SummaryLabel';
import Text from './SummaryText';
import { AppState, AppActions } from '../reducers';
import { Plan } from '../reducers/form';
import { planinfo } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  notSelected: {
    backgroundColor: theme.palette.grey[200],
  },
}));

export type MapStateToProps = {
  plan: AppState['form']['plan'];
};

export type MapDispatchToProps = {
  onChange: (payload: Plan) => AppActions;
};

export interface PlanFormProps extends MapStateToProps, MapDispatchToProps {
  plans: Plan[];
}

export default function PlanForm({
  plan,
  plans,
  onChange,
}: PlanFormProps) {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={5} alignItems="flex-end">
      {plans.map((p) => {
        const info = planinfo(p);
        const selected = plan === p;

        return (
          <Grid item key={info.name} xs={12} md={6}>
            <Card>
              <CardHeader
                title={info.name}
                titleTypographyProps={{ align: 'center' }}
                className={selected ? classes.selected : classes.notSelected}
              />
              <CardContent>
                <Label>保険期間</Label>
                <Text>{info.duration}</Text>
                <Label>保険料</Label>
                <Text>
                  {info.price.toString()}
                  円/月
                </Text>
                <Label>お支払い方法</Label>
                <Text>クレジットカード</Text>
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={selected ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={() => onChange(p)}
                >
                  {selected ? '選択中' : '選択する'}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}
