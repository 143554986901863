import { connect } from 'react-redux';
import { AppState, AppActions } from '../../reducers';
import { Severity } from '../../reducers/alert';
import Card from '../../steps/Card';

type CreditCardKeys = keyof AppState['card'];

type SetCardPayload = Partial<{
    [T in CreditCardKeys]: AppState['card'][T] extends { value: infer S } ? S : never;
}>;

const mapStateToProps = ({ card, loading, form: { paymentMethod } }: AppState) => ({
  ...card,
  loading,
  paymentMethod,
});

const mapDispatchToProps = {
  back(): AppActions {
    return {
      type: 'STEPS/BACK',
    };
  },

  setCard(payload: SetCardPayload): AppActions {
    return {
      type: 'CARD/SET_CARD',
      payload,
    };
  },

  next(): AppActions {
    return {
      type: 'CARD/REGISTER',
    };
  },

  showErrAlert(title: string, messages: string[]): AppActions {
    return {
      type: 'ALERT/SHOW',
      payload: {
        severity: Severity.Error,
        title,
        messages,
      },
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Card);
