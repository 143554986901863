import React, { useEffect } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { AppState } from '../reducers';
import { planinfo } from '../constants';
import { Location } from '../utils/location';

declare global {
  interface Window {
    af110_pgid: string;
    af110_oid: string;
    af110_s: string;
    af110_m: string;
  }
}

const { REACT_APP_AF110_SCRIPT } = process.env;
export const sendAffiliate = typeof REACT_APP_AF110_SCRIPT === 'string' && REACT_APP_AF110_SCRIPT.toUpperCase() === 'ON';

export function getSessionId(location: Location): string {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  if ('s' in query && typeof query.s === 'string') {
    return query.s;
  }

  return '';
}

export type MapStateToProps = {
  plan: AppState['form']['plan'];
};

export interface AffiliateProps extends MapStateToProps {
  contractNo: string;
}

export function DirectAffiliate() {
  return (
    <script type="text/javascript" src="https://js.crossees.com/csslp.js" async />
  );
}

export default function Affiliate({ plan, contractNo }: AffiliateProps) {
  const location = useLocation();
  const sessionId = getSessionId(location);
  const price = planinfo(plan).price.toString();

  window.af110_pgid = '3142';
  window.af110_oid = contractNo;
  window.af110_s = sessionId;
  window.af110_m = price;

  // MEMO: bodyの直下に配置させるために以下の処理を行う。
  useEffect(() => {
    const cvTag = document.createElement('script');
    cvTag.src = `https://js.felmat.net/fmcv.js?adid=Q5986X&lp=sumaho-hoken.jp&uqid=${contractNo}&item=${price}.1.0`;
    cvTag.type = 'text/javascript';

    const beforeNode = document.getElementById('noscript');
    const bodyTag = beforeNode?.parentNode;
    bodyTag?.insertBefore(cvTag, beforeNode);
  }, []);

  return (
    <>
      <noscript>
        <img
          src={`//af-110.com/t/cv.png?pgid=${window.af110_pgid}&s=${window.af110_s}&oid=${window.af110_oid}&m=${window.af110_m}&jse=0`}
          width="1"
          height="1"
          alt=""
        />
      </noscript>
      <script type="text/javascript" src="//af-110.com/js/cvm.js" />
    </>
  );
}
