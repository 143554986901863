import { put, call, select } from 'redux-saga/effects';
import { API } from 'aws-amplify';
import deepEqual from 'deep-equal';
import { AppState, AppActions, AppAction } from '../reducers';
import { Severity } from '../reducers/alert';
import { IRegistrationParams } from '../reducers/registration';
import { mapInputsToParams } from '../utils/registrationHelpers';

interface IRegistrationResponse {
  contractNo: string;
  customerNo: string;
}

interface ICompleteParams extends IRegistrationResponse {
  token: string | null;
  email: string;
}

function registrationRequest(params: IRegistrationParams): Promise<IRegistrationResponse> {
  return API.post('GyaraEntry', '/registration', {
    headers: { 'Content-Type': 'application/json' },
    body: params,
  });
}

function completeRequest(params: ICompleteParams) {
  return API.put('GyaraEntry', '/registration', {
    headers: { 'Content-Type': 'application/json' },
    body: params,
  });
}

export function* register() {
  const params: IRegistrationParams = yield select(mapInputsToParams);
  const registration: AppState['registration'] = yield select((state: AppState) => state.registration);

  if (
    registration.customerNo !== null
    && registration.contractNo !== null
    && deepEqual(params, registration.params)
  ) {
    yield put({ type: 'STEPS/NEXT' });
    return;
  }

  yield put({ type: 'LOADING/START' });

  yield put({ type: 'REGISTRATION/SET_PARAMS', payload: params });

  try {
    const result: IRegistrationResponse = yield call(registrationRequest, params);
    yield put({ type: 'REGISTRATION/SET_NUMBERS', payload: result });
    yield put({ type: 'STEPS/NEXT' });
  } catch (err: any) {
    yield put({ type: 'REGISTRATION/CLEAR_ALL' });

    const messages = err.response?.data?.messages
      || ['入力された内容をご確認のうえ、やり直してください。'];

    yield put({
      type: 'ALERT/SHOW',
      payload: {
        severity: Severity.Error,
        title: '申込み受付処理に失敗しました。',
        messages,
      },
    });
  } finally {
    yield put({ type: 'LOADING/FINISH' });
  }
}

type CompleteParams = AppAction<AppActions, 'REGISTRATION/START_COMPLETION'>;

export function* complete(params: CompleteParams) {
  const { payload: token } = params;
  const { contractNo, customerNo } = yield select(({ registration }: AppState) => registration);
  const { holder: { email } } = yield select(mapInputsToParams);

  try {
    yield call(completeRequest, {
      token,
      contractNo,
      customerNo,
      email,
    });

    yield put({ type: 'STEPS/NEXT' });
  } catch (err: any) {
    yield put({ type: 'REGISTRATION/CLEAR_ALL' });

    const messages = err.response?.data?.messages
      || ['入力された内容をご確認のうえ、やり直してください。'];

    yield put({
      type: 'ALERT/SHOW',
      payload: {
        severity: Severity.Error,
        title: '申込み確定処理に失敗しました。',
        messages,
      },
    });

    yield put({ type: 'STEPS/BACK' });
  } finally {
    yield put({ type: 'LOADING/FINISH' });
  }
}
