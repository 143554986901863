import { connect } from 'react-redux';
import { AppState, AppActions } from '../../reducers';
import { Severity } from '../../reducers/alert';
import Terms from '../../steps/Terms';

type TermKeys = keyof AppState['terms'];

const mapStateToProps = ({ terms }: AppState) => ({ ...terms });

const mapDispatchToProps = {
  read(key: TermKeys): AppActions {
    return {
      type: 'TERMS/READ',
      payload: { key },
    };
  },

  confirm(key: TermKeys, checked: boolean): AppActions {
    return {
      type: 'TERMS/CONFIRM',
      payload: { key, checked },
    };
  },

  back(): AppActions {
    return {
      type: 'STEPS/BACK',
    };
  },

  next(): AppActions {
    return {
      type: 'STEPS/NEXT',
    };
  },

  showErrAlert(title: string, messages: string[]): AppActions {
    return {
      type: 'ALERT/SHOW',
      payload: {
        severity: Severity.Error,
        title,
        messages,
      },
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
