import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CreditCard from 'react-credit-cards';
import Instruction from '../components/Instruction';
import CardForm from '../components/CardForm';
import StepButtons from '../components/StepButtons';
import { AppState, AppActions } from '../reducers';
import { PaymentMethod } from '../reducers/form';
import { StepBase } from './common';

import 'react-credit-cards/lib/styles.scss';

const useStyles = makeStyles((theme) => ({
  paragraph: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));

type CreditCardKeys = keyof AppState['card'];

type SetCardPayload = Partial<{
    [T in CreditCardKeys]: AppState['card'][T] extends { value: infer S } ? S : never;
}>;

export type MapStateToProps = AppState['card'] & {
  loading: AppState['loading'];
  paymentMethod: AppState['form']['paymentMethod'];
};

export type MapDispatchToProps = {
  setCard: (payload: SetCardPayload) => AppActions;
  back: () => AppActions;
  next: () => AppActions;
  showErrAlert: (title: string, messages: string[]) => AppActions;
};

export interface CardProps extends MapStateToProps, MapDispatchToProps, StepBase {}

export default function Card({
  firstStep,
  lastStep,
  number,
  name,
  expiry,
  cvc,
  setCard,
  back,
  next,
  loading,
  paymentMethod,
  showErrAlert,
}: CardProps) {
  const classes = useStyles();
  const [focus, setFocus] = useState<CreditCardKeys | undefined>();
  const [validated, setValidated] = useState(false);

  const handleChangeText = (key: CreditCardKeys, value: string) => {
    setCard({ [key]: value });
  };

  const handleClickNext = () => {
    setValidated(true);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (isValid({
      number,
      name,
      expiry,
      cvc,
    })) {
      next();
    } else {
      showErrAlert(
        '未入力または入力の間違っている項目があります。',
        ['入力された内容をご確認ください。'],
      );
    }
  };

  return (
    <>
      {paymentMethod === PaymentMethod.CreditCard ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Instruction>
              クレジットカードの情報を入力してください。
            </Instruction>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CreditCard
              cvc={cvc.value}
              expiry={expiry.value}
              focused={focus}
              name={name.value}
              number={number.value}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardForm
              number={number}
              name={name}
              expiry={expiry}
              cvc={cvc}
              onChangeText={handleChangeText}
              onFocus={setFocus}
              validated={validated}
            />
          </Grid>
        </Grid>
      ) : (
        <Typography
          variant="h6"
          component="p"
          align="center"
          className={classes.paragraph}
        >
          カード情報の入力は不要です。
        </Typography>
      )}
      <StepButtons
        onClickBack={back}
        onClickNext={handleClickNext}
        firstStep={firstStep}
        lastStep={lastStep}
        disableBack={loading}
        disableNext={loading}
      />
    </>
  );
}

function isValid({
  number,
  name,
  expiry,
  cvc,
}: AppState['card']): boolean {
  return number.errors.length === 0
    && name.errors.length === 0
    && expiry.errors.length === 0
    && cvc.errors.length === 0;
}
