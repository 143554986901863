import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import SummaryAnnounce from '../../components/SummaryAnnounce';

const mapStateToProps = ({ form: { announce } }: AppState) => {
  const {
    damaged: { value: damaged },
    remark: { value: remark },
  } = announce;

  return {
    damaged,
    remark,
  };
};

export default connect(mapStateToProps)(SummaryAnnounce);
