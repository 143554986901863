import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Label from './SummaryLabel';
import Item from './SummaryItem';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  image: {
    marginTop: theme.spacing(1),
    maxWidth: 200,
    display: 'block',
  },
}));

export interface SummaryDeviceProps {
  maker: string,
  brand: string,
  model: string,
  carrier: string,
  price: string,
  imei: string,
  imageFront: string,
  imageBack: string,
}

export default function SummaryDevice({
  maker,
  brand,
  model,
  carrier,
  price,
  imei,
  imageFront,
  imageBack,
}: SummaryDeviceProps) {
  const classes = useStyles();
  const [imageFrontUrl, setImageFrontUrl] = useState('');
  const [imageBackUrl, setImageBackUrl] = useState('');

  useEffect(() => {
    if (imageFront.length > 0) {
      Storage
        .get(imageFront, { expires: 300 })
        .then((url) => {
          if (typeof url === 'string') {
            setImageFrontUrl(url);
          }
        })
        .catch(() => {});
    }
  }, [imageFront, setImageFrontUrl]);

  useEffect(() => {
    if (imageBack.length > 0) {
      Storage
        .get(imageBack, { expires: 300 })
        .then((url) => {
          if (typeof url === 'string') {
            setImageBackUrl(url);
          }
        })
        .catch(() => {});
    }
  }, [imageBack, setImageBackUrl]);

  const displayPrice = price.length > 0 ? `${price} 円` : '未設定';

  return (
    <Card className={classes.root}>
      <CardContent>
        <Item
          label="メーカー"
          text={maker}
        />
        <Item
          label="ブランド"
          text={brand}
        />
        <Item
          label="機種名"
          text={model}
        />
        <Item
          label="キャリア"
          text={carrier}
        />
        <Item
          label="IMEI番号"
          text={imei}
        />
        <Item
          label="購入価格"
          text={displayPrice}
        />
        {imageFrontUrl.length > 0 && (
          <>
            <Label>端末画像（表）</Label>
            <img
              className={classes.image}
              src={imageFrontUrl}
              alt="端末画像（表）"
            />
          </>
        )}
        {imageBackUrl.length > 0 && (
          <>
            <Label>端末画像（裏）</Label>
            <img
              className={classes.image}
              src={imageBackUrl}
              alt="端末画像（裏）"
            />
          </>
        )}
      </CardContent>
    </Card>
  );
}
