import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiCheckbox from '@material-ui/core/Checkbox';

export interface CheckboxProps {
    name: string;
    label: string;
    validated: boolean;
    errors: string[];
    checked?: boolean;
    disabled?: boolean;
    onChecked?: (checked: boolean) => void;
}

export default function Checkbox({
  name,
  label,
  validated,
  errors,
  checked = false,
  disabled = false,
  onChecked = () => {},
}: CheckboxProps) {
  const handleChecked = () => {
    const state = !checked;
    onChecked(state);
  };

  const hasError = validated && errors.length > 0;

  return (
    <FormControl error={hasError}>
      <FormControlLabel
        control={(
          <MuiCheckbox
            checked={checked}
            onChange={handleChecked}
            name={name}
            color="primary"
            disabled={disabled}
          />
                )}
        label={label}
      />
      {hasError && (
        <FormHelperText>{errors[0]}</FormHelperText>
      )}
    </FormControl>
  );
}
