import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '100vw',
  },
  pageTitle: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  logo: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <Grid container spacing={3} justify="center" className={classes.container}>
      <Grid item xs={10} sm={8}>
        <img
          className={classes.logo}
          alt="logo"
          src="/img_logo.png"
        />
      </Grid>
    </Grid>
  );
}
