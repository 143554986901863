import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Terms from '../containers/steps/Terms';
import Form from '../containers/steps/Form';
import Confirmation from '../containers/steps/Confirmation';
import ConfirmEmail from '../containers/steps/ConfirmEmail';
import Card from '../containers/steps/Card';
import StepContainer from '../steps/layouts';
import { StepContainerBase } from '../steps/common';
import { AppActions } from '../reducers';
import { getAgentCodes, AgentCodes, Location } from '../utils/location';
import { DirectAffiliate, sendAffiliate } from '../components/Affiliate';

const stepTitles = [
  '意向確認',
  '申込み内容入力',
  'メールアドレス確認',
  '申込み内容確認',
  'カード情報入力',
];

export function content(step: number) {
  switch (step) {
    case 0:
      return <Terms firstStep />;
    case 1:
      return <Form />;
    case 2:
      return <ConfirmEmail />;
    case 3:
      return <Confirmation />;
    case 4:
      return <Card lastStep />;
    default:
      throw new Error('Unknown step');
  }
}

export interface HomeProps extends StepContainerBase {
  setAgentCodes: (codes: AgentCodes) => AppActions;
  setDirectMode: () => AppActions;
}

function isDirectMode(location: Location): boolean {
  return location.pathname === '/direct';
}

function emptyParams(codes: AgentCodes): boolean {
  return codes.agencyCode === '' || codes.agentCode === '';
}

export default function Home({
  activeStep,
  contractNo,
  plan,
  setAgentCodes,
  setDirectMode,
  steps = stepTitles,
  stepContent = content,
}: HomeProps) {
  const location = useLocation();
  const history = useHistory();
  const codes = getAgentCodes(location);

  useEffect(() => {
    if (!isDirectMode(location) && emptyParams(codes)) {
      history.push('/direct');
      return;
    }
    setAgentCodes(codes);
  }, [setAgentCodes, codes, history, location]);

  useEffect(() => {
    if (isDirectMode(location)) {
      setDirectMode();
    }
  }, [location, setDirectMode]);

  return (
    <>
      <StepContainer
        activeStep={activeStep}
        contractNo={contractNo}
        plan={plan}
        steps={steps}
        stepContent={stepContent}
      />
      {isDirectMode(location) && sendAffiliate && <DirectAffiliate />}
    </>
  );
}
