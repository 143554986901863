import { Action } from 'redux';

export enum Severity {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
}

interface IState {
    show: boolean;
    severity: Severity;
    title: string;
    messages: string[];
}

export const initialState: IState = {
  show: false,
  severity: Severity.Info,
  title: '',
  messages: [],
};

const SHOW = 'ALERT/SHOW';
const CLOSE = 'ALERT/CLOSE';

interface IShowAction extends Action {
    type: typeof SHOW;
    payload: {
        severity: Severity;
        title: string;
        messages: string[];
    };
}

interface ICloseAction extends Action {
    type: typeof CLOSE;
}

export type AlertActions = IShowAction | ICloseAction;

export default function reducer(
  state: IState = initialState,
  action: AlertActions,
): IState {
  switch (action.type) {
    case SHOW: {
      const { payload: { title, severity, messages } } = action;
      return {
        show: true,
        severity,
        title,
        messages,
      };
    }
    case CLOSE:
      return {
        ...state,
        show: false,
        title: '',
        messages: [],
      };
    default:
      return state;
  }
}
