import React, { useState, useEffect, ChangeEvent } from 'react';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import { AppActions } from '../reducers';

const useStyles = makeStyles((theme) => ({
  fileInput: {
    display: 'none',
  },
  image: {
    marginTop: theme.spacing(1),
    maxWidth: '100%',
    display: 'block',
  },
}));

export type MapDispatchToProps = {
    showErrAlert: (title: string, messages: string[]) => AppActions;
};

export interface UploadFormProps extends MapDispatchToProps {
  label: string;
  htmlFor: string;
  imageKey: string;
  limitMB: number;
  setImageKey: (key: string) => void;
  validated: boolean;
  errors: string[];
}

export default function UploadForm({
  label,
  htmlFor,
  imageKey,
  limitMB,
  setImageKey,
  validated,
  errors,
  showErrAlert,
}: UploadFormProps) {
  const classes = useStyles();
  const hasError = validated && errors.length > 0;
  const [imageUrl, setImageUrl] = useState('');

  const handleChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { files } } = event;
    if (!files) return;

    const file = files[0];

    if (file.size > limitMB) {
      showErrAlert(
        'ファイルのアップロードに失敗しました。',
        [`${limitMB}MB以上のファイルはアップロードできません`],
      );
      return;
    }

    const filename = `${uuidv4().replace(/-/g, '')}.jpg`;

    Storage
      .put(filename, file, { contentType: 'image/jpeg' })
      .then((result: any) => {
        if ('key' in result && typeof result.key === 'string') {
          setImageKey(result.key);
        }
      })
      .catch(() => {
        showErrAlert(
          'ファイルのアップロードに失敗しました。',
          ['お手数ですが再度お試しください。'],
        );
      });
  };

  useEffect(() => {
    if (imageKey.length > 0) {
      Storage
        .get(imageKey, { expires: 300 })
        .then((url) => {
          if (typeof url === 'string') {
            setImageUrl(url);
          }
        })
        .catch(() => {});
    }
  }, [imageKey, setImageKey]);

  return (
    <FormControl error={hasError}>
      <label htmlFor={htmlFor}>
        <input
          accept="image/jpeg"
          className={classes.fileInput}
          id={htmlFor}
          type="file"
          onChange={handleChangeImage}
        />
        <Button variant="contained" color="secondary" component="span">
          {label}
        </Button>
        {hasError && (
          <FormHelperText>{errors[0]}</FormHelperText>
        )}
      </label>
      {imageUrl.length > 0 && (
        <img className={classes.image} src={imageUrl} alt={label} />
      )}
    </FormControl>
  );
}
