import isEmail from 'validator/lib/isEmail';
import { compose } from 'redux';
import differenceInYears from 'date-fns/differenceInYears';
import { Input } from './formHelpers';
import { Gender } from '../reducers/form';
import {
  isDate,
  dateComponents,
  startDate,
} from './date';

export const MESSAGES = {
  REQUIRE: '必須項目です',
  REQUIRE_ACCEPTANCE: '同意してください',
  REQUIRE_SELECTION: '選択してください',
  VALID_EMAIL: '正しいメールアドレスを入力してください。半角英数字と一部の記号以外の文字は使用できません。（使用可能な記号: !,#,$,%,&,\',*,+,-,/,=,?,^,_,`,{,|,},~）',
  NUMBERS: '数値のみで入力してください',
  ZENKAKU: '全角文字で入力してください',
  ZENKAKU_KANA: '全角カナ文字で入力してください',
  LETTERS_FOR_CARDNAME: '半角英字、半角スペースおよび , . - / のみで設定してください',
  ZERO_AT_SECOND: '2桁目は0以外の数値を入力してください',
  VALID_DATE: '正しい日付を入力してください',
  VALID_EXPIRED: '月（2桁）/西暦（下2桁）で入力してください',
  IS_FUTURE_MONTH: '未来の日付を入力してください',
  IS_MATURED: '保険加入可能年齢に達していません',
};

export type Validator<T> = (input: Input<T>) => Input<T>;

export function required<T = any>(msg: string = MESSAGES.REQUIRE) {
  return function validator(input: Input<T>): Input<T> {
    const { value, errors } = input;

    if (value === null || value === undefined) {
      return { ...input, errors: [...errors, msg] };
    }

    if (typeof value === 'string' && value.trim() === '') {
      return { ...input, errors: [...errors, msg] };
    }

    return input;
  };
}

export function maxLength(max: number, msg?: string) {
  return function validator(input: Input<string>): Input<string> {
    const { value, errors } = input;

    if (value.length > max) {
      return {
        ...input,
        errors: [...errors, msg || `${max}文字以下で入力してください`],
      };
    }

    return input;
  };
}

export function minLength(min: number, msg?: string) {
  return function validator(input: Input<string>): Input<string> {
    const { value, errors } = input;

    if (value.length < min && value !== '') {
      return {
        ...input,
        errors: [...errors, msg || `${min}文字以上入力してください`],
      };
    }

    return input;
  };
}

export function constLength(len: number, msg?: string) {
  return function validator(input: Input<string>): Input<string> {
    const { value, errors } = input;

    if (value.length !== len && value !== '') {
      return {
        ...input,
        errors: [...errors, msg || `${len}文字入力してください`],
      };
    }

    return input;
  };
}

export function acceptance(msg: string = MESSAGES.REQUIRE_ACCEPTANCE) {
  return function validation(input: Input<boolean>): Input<boolean> {
    const { value, errors } = input;

    if (!value) {
      return { ...input, errors: [...errors, msg] };
    }

    return input;
  };
}

export function email(msg: string = MESSAGES.VALID_EMAIL) {
  return function validator(input: Input<string>): Input<string> {
    const { value, errors } = input;

    if (!isEmail(value, { allow_utf8_local_part: false }) && value !== '') {
      return { ...input, errors: [...errors, msg] };
    }

    return input;
  };
}

export function zenkaku(msg: string = MESSAGES.ZENKAKU) {
  return function validator(input: Input<string>): Input<string> {
    const { value, errors } = input;

    // eslint-disable-next-line no-control-regex
    if (/[\x01-\x7E\uFF65-\uFF9F]/.test(value)) {
      return { ...input, errors: [...errors, msg] };
    }

    return input;
  };
}

export function zenkana(msg: string = MESSAGES.ZENKAKU_KANA) {
  return function validator(input: Input<string>): Input<string> {
    const { value, errors } = input;

    // eslint-disable-next-line no-irregular-whitespace
    if (!/^[ァ-ヶー　]*$/.test(value)) {
      return { ...input, errors: [...errors, msg] };
    }

    return input;
  };
}

export function lettersForCardname(msg: string = MESSAGES.LETTERS_FOR_CARDNAME) {
  return function validator(input: Input<string>): Input<string> {
    const { value, errors } = input;

    if (/[^ ,.\-/a-zA-Z]/.test(value)) {
      return { ...input, errors: [...errors, msg] };
    }

    return input;
  };
}

export function onlyNumbers(msg: string = MESSAGES.NUMBERS) {
  return function validator(input: Input<string>): Input<string> {
    const { value, errors } = input;

    if (!/^\d*$/.test(value)) {
      return { ...input, errors: [...errors, msg] };
    }

    return input;
  };
}

export function zeroAtSecond(msg: string = MESSAGES.ZERO_AT_SECOND) {
  return function validator(input: Input<string>): Input<string> {
    const { value, errors } = input;

    if (value.length > 1 && value.charAt(1) === '0') {
      return { ...input, errors: [...errors, msg] };
    }

    return input;
  };
}

export function validDate(msg: string = MESSAGES.VALID_DATE) {
  return function validator(input: Input<string>): Input<string> {
    const { value, errors } = input;
    if (value === '') return input;

    if (!isDate(value)) {
      return { ...input, errors: [...errors, msg] };
    }

    return input;
  };
}

const VALID_MONTH_REG = /^\d{2}\/\d{2}$/;

export function validExpired(msg: string = MESSAGES.VALID_EXPIRED) {
  return function validator(input: Input<string>): Input<string> {
    const { value, errors } = input;
    if (value === '') return input;

    if (VALID_MONTH_REG.test(value)) {
      return input;
    }

    return { ...input, errors: [...errors, msg] };
  };
}

export function isFutureMonth(msg: string = MESSAGES.IS_FUTURE_MONTH) {
  return function validator(input: Input<string>): Input<string> {
    const { value, errors } = input;
    if (value === '' || !VALID_MONTH_REG.test(value)) return input;

    const error = { ...input, errors: [...errors, msg] };
    const today = new Date();
    const thisYear = today.getFullYear();
    const thisMonth = today.getMonth() + 1;

    const year = Number.parseInt(`20${value.slice(3, 5)}`, 10);
    const month = Number.parseInt(value.slice(0, 2), 10);

    if (year < thisYear) return error;
    if (year > thisYear) return input;
    if (month < thisMonth) return error;
    return input;
  };
}

export function isMatured(
  age: number,
  baseDate: Date = startDate(),
  msg: string = MESSAGES.IS_MATURED,
) {
  return function validator(input: Input<string>): Input<string> {
    const { value, errors } = input;
    if (value === '' || !isDate(value)) return input;

    const { year, month, day } = dateComponents(value);
    const date = new Date(`${year}-${month + 1}-${day}`);

    if (differenceInYears(baseDate, date) < age) {
      return { ...input, errors: [...errors, msg] };
    }

    return input;
  };
}

export const validators = {
  kanjiName(value: string, dirty: boolean): Input<string> {
    return compose(zenkaku(), maxLength(64), required<string>())({
      value,
      dirty,
      errors: [],
    });
  },

  kanaName(value: string, dirty: boolean): Input<string> {
    return compose(zenkana(), maxLength(64), required<string>())({
      value,
      errors: [],
      dirty,
    });
  },

  zipcode(value: string, dirty: boolean): Input<string> {
    return compose(constLength(7), required<string>())({
      value,
      errors: [],
      dirty,
    });
  },

  prefecture(value: string, dirty: boolean): Input<string> {
    return required<string>()({
      value,
      errors: [],
      dirty,
    });
  },

  address1(value: string, dirty: boolean): Input<string> {
    return compose(maxLength(64), required<string>())({
      value,
      errors: [],
      dirty,
    });
  },

  address2(value: string, dirty: boolean): Input<string> {
    return maxLength(64)({
      value,
      errors: [],
      dirty,
    });
  },

  phone1(value: string, dirty: boolean): Input<string> {
    return compose(maxLength(11), minLength(10), zeroAtSecond(), required<string>())({
      value,
      errors: [],
      dirty,
    });
  },

  phone2(value: string, dirty: boolean): Input<string> {
    return compose(maxLength(11), minLength(10), zeroAtSecond())({
      value,
      errors: [],
      dirty,
    });
  },

  birthday(value: string, dirty: boolean): Input<string> {
    return compose(isMatured(18), validDate(), required<string>())({
      value,
      errors: [],
      dirty,
    });
  },

  gender(value: Gender | null, dirty: boolean): Input<Gender | null> {
    return required<Gender | null>(MESSAGES.REQUIRE_SELECTION)({
      value,
      errors: [],
      dirty,
    });
  },

  email(value: string, dirty: boolean): Input<string> {
    return compose(email(), maxLength(128), required<string>())({
      value,
      errors: [],
      dirty,
    });
  },

  term(value: boolean, dirty: boolean): Input<boolean> {
    return acceptance()({
      value,
      errors: [],
      dirty,
    });
  },

  cardNumber(value: string, dirty: boolean): Input<string> {
    return compose(maxLength(16), minLength(14), required<string>())({
      value,
      errors: [],
      dirty,
    });
  },

  cardName(value: string, dirty: boolean): Input<string> {
    return compose(
      maxLength(50),
      lettersForCardname(),
      required<string>(),
    )({ value, errors: [], dirty });
  },

  cardExpiry(value: string, dirty: boolean): Input<string> {
    return compose(isFutureMonth(), validExpired(), required<string>())({
      value,
      errors: [],
      dirty,
    });
  },

  cardCvc(value: string, dirty: boolean): Input<string> {
    return compose(maxLength(4), minLength(3), required<string>())({
      value,
      errors: [],
      dirty,
    });
  },

  agentCode(value: string, dirty: boolean): Input<string> {
    return compose(constLength(6), required<string>())({
      value,
      errors: [],
      dirty,
    });
  },

  agencyCode(value: string, dirty: boolean): Input<string> {
    return compose(constLength(5), required<string>())({
      value,
      errors: [],
      dirty,
    });
  },

  device(value: string, dirty: boolean): Input<string> {
    return compose(maxLength(64), required<string>())({
      value,
      errors: [],
      dirty,
    });
  },

  deviceIMEI(value: string, dirty: boolean): Input<string> {
    return compose(constLength(15), onlyNumbers(), required<string>())({
      value,
      errors: [],
      dirty,
    });
  },

  devicePrice(value: string, dirty: boolean): Input<string> {
    return compose(maxLength(7), onlyNumbers())({
      value,
      errors: [],
      dirty,
    });
  },

  deviceWork(value: boolean, dirty: boolean): Input<boolean> {
    return acceptance('正常に動作しない端末を登録することはできません')({
      value,
      errors: [],
      dirty,
    });
  },

  deviceDamaged(value: boolean, dirty: boolean): Input<boolean> {
    return {
      value,
      errors: [],
      dirty,
    };
  },

  deviceRemark(value: string, dirty: boolean): Input<string> {
    return maxLength(1024)({
      value,
      errors: [],
      dirty,
    });
  },

  deviceImageFront(value: string, dirty: boolean): Input<string> {
    return required('端末画像（表）をアップロードしてください')({
      value,
      errors: [],
      dirty,
    });
  },

  deviceImageBack(value: string, dirty: boolean): Input<string> {
    return required('端末画像（裏）をアップロードしてください')({
      value,
      errors: [],
      dirty,
    });
  },
};
