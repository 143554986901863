import { connect } from 'react-redux';
import { AppState, AppActions } from '../../reducers';
import Toast from '../../components/Toast';

const mapStateToProps = ({ alert }: AppState) => ({ ...alert });

const mapDispatchToProps = {
  hide(): AppActions {
    return {
      type: 'ALERT/CLOSE',
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
