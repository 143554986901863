import { Action } from 'redux';

type IState = number;

export const initialState: IState = 0;

const NEXT = 'STEPS/NEXT';
const BACK = 'STEPS/BACK';

interface INextAction extends Action {
    type: typeof NEXT;
}

interface IBackAction extends Action {
    type: typeof BACK;
}

export type StepsActions = INextAction | IBackAction;

export default function reducer(
  state: IState = initialState,
  action: StepsActions,
): IState {
  switch (action.type) {
    case NEXT: return state + 1;
    case BACK: return state - 1;
    default: return state;
  }
}
