import { Component, ErrorInfo } from 'react';
import { connect } from 'react-redux';
import { API } from 'aws-amplify';
import { AppActions } from './reducers';
import { Severity } from './reducers/alert';

type MapDispatchToProps = {
  showError: (title: string, message: string) => AppActions;
};

const mapDispatchToProps: MapDispatchToProps = {
  showError: (title, message) => ({
    type: 'ALERT/SHOW',
    payload: {
      severity: Severity.Error,
      title,
      messages: [message],
    },
  }),
};

interface IProps extends MapDispatchToProps {
  children: JSX.Element | JSX.Element[];
}

export class ErrorBoundary extends Component<IProps> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { showError } = this.props;
    showError('エラーが発生しました', 'お手数ですがもう一度やり直してください。');

    const stack: string[] = ('stack' in error) && typeof error.stack === 'string'
      ? error.stack.split('\n') : [];

    const apiName = 'GyaraEntry';
    const path = '/report';
    const init = {
      header: { 'Content-Type': 'application/json' },
      body: {
        userAgent: navigator.userAgent,
        datetime: new Date().toLocaleString('ja-JP'),
        name: error.name,
        message: error.message,
        stack,
        errorInfo,
      },
    };

    API.post(apiName, path, init).catch(() => {});
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default connect(null, mapDispatchToProps)(ErrorBoundary);
