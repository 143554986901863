import { connect } from 'react-redux';
import { AppState, AppActions } from '../../reducers';
import { InputMode } from '../../reducers/mode';
import Home from '../../pages/Home';
import { AgentCodes } from '../../utils/location';

const mapStateToProps = ({
  steps: activeStep,
  registration: { contractNo },
  form: { plan },
}: AppState) => ({ activeStep, contractNo, plan });

const mapDispatchToProps = {
  setAgentCodes({ agencyCode, agentCode }: AgentCodes): AppActions {
    return {
      type: 'FORM/SET_AGENT',
      payload: {
        agencyCode,
        agentCode,
      },
    };
  },

  setDirectMode(): AppActions {
    return {
      type: 'MODE/SET',
      payload: InputMode.Direct,
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
