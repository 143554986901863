import { connect } from 'react-redux';
import { AppState, AppActions } from '../../reducers';
import ConfirmEmail from '../../steps/ConfirmEmail';

const mapStateToProps = ({
  form: { holder: { email } },
  emailConfirmation,
  loading,
}: AppState) => ({
  email: email.value,
  confirmed: emailConfirmation.email,
  loading,
});

const mapDispatchToProps = {
  back(): AppActions {
    return {
      type: 'STEPS/BACK',
    };
  },

  next(): AppActions {
    return {
      type: 'STEPS/NEXT',
    };
  },

  sendEmail(): AppActions {
    return {
      type: 'EMAIL_CONFIRMATION/SEND_EMAIL',
    };
  },

  confirm(code: string): AppActions {
    return {
      type: 'EMAIL_CONFIRMATION/CONFIRM_EMAIL',
      payload: code,
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);
