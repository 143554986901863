import { Action } from 'redux';

interface IState {
  email: string;
}

export const initialState: IState = {
  email: '',
};

export const SET_VALUE = 'EMAIL_CONFIRMATION/SET_VALUE';
export const SEND_EMAIL = 'EMAIL_CONFIRMATION/SEND_EMAIL';
export const CONFIRM = 'EMAIL_CONFIRMATION/CONFIRM_EMAIL';

interface ISetValueAction extends Action {
  type: typeof SET_VALUE;
  payload: string;
}

interface ISendEmailAction extends Action {
  type: typeof SEND_EMAIL;
}

interface IConfirmEmailAction extends Action {
  type: typeof CONFIRM;
  payload: string;
}

export type EmailConfirmationActions = ISetValueAction
  | ISendEmailAction
  | IConfirmEmailAction;

export default function reducer(
  state: IState = initialState,
  action: EmailConfirmationActions,
): IState {
  switch (action.type) {
    case SET_VALUE:
      return {
        ...state,
        email: action.payload,
      };

    default:
      return state;
  }
}
