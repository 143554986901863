import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Label from './SummaryLabel';
import Text from './SummaryText';
import { AppState } from '../reducers';
import { planinfo } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  content: {
    marginBottom: theme.spacing(2),
  },
}));

export type MapStateToProps = {
  plan: AppState['form']['plan'];
};

export interface ProductProps extends MapStateToProps {
  baseDate?: Date; // eslint-disable-line react/no-unused-prop-types
  showConcreteValue?: boolean;
}

export default function Product({
  plan,
  showConcreteValue = false,
}: ProductProps) {
  const classes = useStyles();
  const planContent = planinfo(plan);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Label>
          プラン名
        </Label>
        <Typography variant="h6" component="h4" className={classes.content}>
          {planContent.name}
        </Typography>
        {!showConcreteValue && (
          <>
            <Label>
              保険期間
            </Label>
            <Text>
              {planContent.duration}
            </Text>
          </>
        )}
        <Label>
          保険料
        </Label>
        <Text>
          {planContent.price.toString()}
          円
        </Text>
        <Label>
          お支払い方法
        </Label>
        <Text>
          クレジットカード
        </Text>
      </CardContent>
    </Card>
  );
}
