import { connect } from 'react-redux';
import { AppState, AppActions } from '../../reducers';
import { Severity } from '../../reducers/alert';
import { InputMode } from '../../reducers/mode';
import Form from '../../steps/Form';

const mapStateToProps = ({
  mode,
  loading,
  form,
}: AppState) => ({
  direct: mode === InputMode.Direct,
  loading,
  state: form,
});

const mapDispatchToProps = {
  back(): AppActions {
    return {
      type: 'STEPS/BACK',
    };
  },

  next(): AppActions {
    return {
      type: 'STEPS/NEXT',
    };
  },

  showErrAlert(title: string, messages: string[]): AppActions {
    return {
      type: 'ALERT/SHOW',
      payload: {
        severity: Severity.Error,
        title,
        messages,
      },
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
