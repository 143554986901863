import { Store } from 'redux';
import { AppState, AppActions } from '../reducers';
import { Severity } from '../reducers/alert';

declare global {
  interface Window {
    Multipayment: {
      init: (apiKey?: string) => void;
      getToken: (params: ICardTokenParams, callback: IMultiPaymentCallback) => void;
    };

    MultipaymentCallback: IMultiPaymentCallback;
  }
}

if (window.Multipayment) {
  window.Multipayment.init(process.env.REACT_APP_GMO_SHOP_ID);
}

export interface ICardTokenParams {
  cardno: string;
  expire: string;
  securitycode: string;
  holdername: string;
}

export interface ICardTokenResponse {
  resultCode: string;
  tokenObject: {
    token: string | string[];
    toBeExpiredAt: string;
    maskedCardNo: string;
    isSecurityCodeSet: boolean;
  };
}

export interface IMultiPaymentCallback {
  (res: ICardTokenResponse): void;
}

let store: Store<AppState, AppActions>;

// CAUTION: windowオブジェクトから呼び出せてしまうので入力値のチェックなどは厳密に行う。
export function MultipaymentCallback(res: ICardTokenResponse) {
  const { resultCode, tokenObject } = res;

  if (resultCode !== '000') {
    store.dispatch({
      type: 'ALERT/SHOW',
      payload: {
        severity: Severity.Error,
        title: 'カード情報の登録に失敗しました。',
        messages: ['ご入力された内容をもう一度ご確認ください。'],
      },
    });

    store.dispatch({ type: 'LOADING/FINISH' });

    return;
  }

  const token = Array.isArray(tokenObject.token)
    ? tokenObject.token[0] : tokenObject.token;

  store.dispatch({
    type: 'REGISTRATION/START_COMPLETION',
    payload: token,
  });
}

export function registerGMOModule(s: Store) {
  store = s;
}
