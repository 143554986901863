import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import SummaryDevice from '../../components/SummaryDevice';

const mapStateToProps = ({ form: { device } }: AppState) => {
  const {
    maker: { value: maker },
    brand: { value: brand },
    model: { value: model },
    carrier: { value: carrier },
    price: { value: price },
    imei: { value: imei },
    imageFront: { value: imageFront },
    imageBack: { value: imageBack },
  } = device;

  return {
    maker,
    brand,
    model,
    carrier,
    price,
    imei,
    imageFront,
    imageBack,
  };
};

export default connect(mapStateToProps)(SummaryDevice);
