import React, { useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { HOME_SITE } from '../../constants';
import Affiliate from '../../containers/components/Affiliate';
import { sendAffiliate } from '../../components/Affiliate';
import { StepContainerProps } from '../common';
import { a8Send } from '../../utils/a8';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  logo: {
    width: '100%',
    maxWidth: 500,
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  contractNo: {
    fontWeight: 'bold',
  },
  finished: {
    padding: theme.spacing(0, 10),
  },
  homeLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function PcSteps({
  activeStep,
  contractNo,
  plan,
  steps,
  stepContent,
}: StepContainerProps) {
  const classes = useStyles();

  const isLastStep = useCallback(
    () => activeStep === steps.length,
    [activeStep, steps],
  );

  useEffect(() => {
    const anchor = document.querySelector('#page-top');
    if (anchor) {
      anchor.scrollIntoView({ block: 'center' });
    }
  }, [activeStep]);

  useEffect(() => {
    if (contractNo && isLastStep()) {
      a8Send(plan, contractNo);
    }
  }, [isLastStep, contractNo, plan]);

  return (
    <Container maxWidth="md">
      <div id="page-top" />
      <Paper className={classes.paper}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={8} lg={6}>
            <img
              className={classes.logo}
              alt="logo"
              src="/img_logo.png"
            />
          </Grid>
        </Grid>
        <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {isLastStep() ? (
          <div className={classes.finished}>
            <Typography
              variant="h5"
              paragraph
              align="center"
              gutterBottom
              className={classes.title}
              id="complete-registration"
            >
              申込み完了
            </Typography>
            <Typography paragraph>
              お申込みいただきありがとうございました。ただ今より審査を開始いたします。
            </Typography>
            <Typography paragraph>
              審査終了後、結果を保険契約者様のメールアドレス宛にお知らせいたします。
              3営業日を経過しても連絡がない場合やご不明な点がございましたら、お手数ですが弊社HPよりお問い合わせください。
            </Typography>
            <Typography paragraph>
              ご連絡の際は以下のお申込番号をお伝えください。
            </Typography>
            <Typography paragraph align="center">
              お申込番号:&nbsp;
              <Typography gutterBottom component="em" className={classes.contractNo}>
                {contractNo}
              </Typography>
            </Typography>
            <Typography paragraph align="center">
              <Link
                href={HOME_SITE.TOP}
                rel="noreferrer"
                variant="body1"
                className={classes.homeLink}
              >
                <ArrowForwardIosIcon />
                スマホの保険証トップページへ
              </Link>
              {sendAffiliate && contractNo && <Affiliate contractNo={contractNo} />}
            </Typography>
          </div>
        ) : (
          <>
            {stepContent(activeStep)}
          </>
        )}
      </Paper>
    </Container>
  );
}
