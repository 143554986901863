import { Plan } from '../reducers/form';

declare global {
  interface Window {
    a8sales:(params: IA8Sales) => void;
  }
}

interface IA8Sales {
  pid: string;
  order_number: string;
  currency: string;
  items: {
    code: string;
    price: number;
    quantity: number;
  }[];
  total_price: number;
}

const pid = 's00000014833004';

function a8Params(plan: Plan): { code: string, price: number } {
  switch (plan) {
    case Plan.Standard:
      return { code: 'standard', price: 2700 };
    case Plan.Light:
      return { code: 'light', price: 1300 };
    default:
      throw new Error(`unknown plan: ${plan}`);
  }
}

function allowSending(): boolean {
  return typeof process.env.REACT_APP_A8_SCRIPT === 'string'
    && process.env.REACT_APP_A8_SCRIPT.toUpperCase() === 'ON'
    && typeof window.a8sales === 'function';
}

// eslint-disable-next-line import/prefer-default-export
export function a8Send(plan: Plan, contractNo: string) {
  const { code, price } = a8Params(plan);

  if (allowSending()) {
    window.a8sales({
      pid,
      order_number: contractNo,
      currency: 'JPY',
      items: [{
        code,
        price,
        quantity: 1,
      }],
      total_price: price,
    });
  }
}
