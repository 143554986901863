import { connect } from 'react-redux';
import { AppState, AppActions } from '../../reducers';
import { AddressKind } from '../../reducers/addresses';
import AddressDialog from '../../components/AddressDialog';

const mapStateToProps = ({ addresses }: AppState) => ({
  ...addresses,
});

const mapDispatchToProps = {
  onClose(): AppActions {
    return {
      type: 'ADDRESSES/CLOSE',
    };
  },

  setAddress(kind: AddressKind, prefecture: string, address1: string): AppActions {
    if (kind === 'holder') {
      return {
        type: 'FORM/SET_HOLDER',
        payload: {
          prefecture,
          address1,
        },
      };
    }

    return {
      type: 'FORM/SET_INSURED',
      payload: {
        prefecture,
        address1,
      },
    };
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressDialog);
