import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Instruction from '../components/Instruction';
import HolderForm from '../containers/components/HolderForm';
import DeviceForm from '../containers/components/DeviceForm';
import AnnounceForm from '../containers/components/AnnounceForm';
import AddressDialog from '../containers/components/AddressDialog';
import StepButtons from '../components/StepButtons';
import { AppState, AppActions } from '../reducers';
import { StepBase } from './common';

const useStyles = makeStyles((theme) => ({
  paragraph: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

function InputRemark() {
  const classes = useStyles();

  return (
    <Typography className={classes.paragraph} component="p" variant="body1">
      ※は入力必須
    </Typography>
  );
}

export type MapStateToProps = {
  direct: boolean;
  loading: AppState['loading'];
  state: AppState['form'];
};

export type MapDispatchToProps = {
  back: () => AppActions;
  next: () => AppActions;
  showErrAlert: (title: string, messages: string[]) => AppActions;
};

export interface FormProps extends MapStateToProps, MapDispatchToProps, StepBase {}

export default function Form({
  direct,
  firstStep,
  lastStep,
  loading,
  back,
  next,
  state,
  showErrAlert,
}: FormProps) {
  const [validated, setValidated] = useState(false);
  const handleClickNext = () => {
    setValidated(true);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (isValidState(direct, state)) {
      next();
    } else {
      showErrAlert(
        '未入力または入力の間違っている項目があります。',
        ['入力された内容をご確認ください。'],
      );
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Instruction>
            保険契約者の情報を入力してください。
          </Instruction>
          <InputRemark />
          <HolderForm validated={validated} />
        </Grid>
        <Grid item xs={12}>
          <Instruction>
            端末の内容を入力してください。
          </Instruction>
          <InputRemark />
          <DeviceForm direct={direct} validated={validated} />
        </Grid>
        {direct && (
          <Grid item xs={12}>
            <Instruction>
              告知内容を入力してください。
            </Instruction>
            <InputRemark />
            <AnnounceForm validated={validated} />
          </Grid>
        )}
      </Grid>
      <AddressDialog />
      <StepButtons
        onClickBack={back}
        onClickNext={handleClickNext}
        firstStep={firstStep}
        lastStep={lastStep}
        disableBack={loading}
        disableNext={loading}
      />
    </>
  );
}

function isValidHolder({ holder }: AppState['form']): boolean {
  const {
    firstName: { errors: firstNameErrors },
    lastName: { errors: lastNameErrors },
    zipcode: { errors: zipcodeErrors },
    prefecture: { errors: prefectureErrors },
    address1: { errors: address1Errors },
    address2: { errors: address2Errors },
    phone1: { errors: phone1Errors },
    phone2: { errors: phone2Errors },
    gender: { errors: genderErrors },
    birthday: { errors: birthdayErrors },
    kanaFirstName: { errors: kanaFirstNameErrors },
    kanaLastName: { errors: kanaLastNameErrors },
    email: { errors: emailErrors },
    emailConfirmation: { errors: confirmationErrors },
  } = holder;

  return [
    ...firstNameErrors,
    ...lastNameErrors,
    ...zipcodeErrors,
    ...prefectureErrors,
    ...address1Errors,
    ...address2Errors,
    ...phone1Errors,
    ...phone2Errors,
    ...genderErrors,
    ...birthdayErrors,
    ...kanaFirstNameErrors,
    ...kanaLastNameErrors,
    ...emailErrors,
    ...confirmationErrors,
  ].length === 0;
}

function isValidDevice(direct: boolean, { device }: AppState['form']): boolean {
  const {
    maker: { errors: makerErrors },
    brand: { errors: brandErrors },
    model: { errors: modelErrors },
    carrier: { errors: carrierErrors },
    imei: { errors: imeiErrors },
    price: { errors: priceErrors },
    imageFront: { errors: imageFrontErrors },
    imageBack: { errors: imageBackErrors },
  } = device;

  const valid = [
    ...makerErrors,
    ...brandErrors,
    ...modelErrors,
    ...carrierErrors,
    ...imeiErrors,
    ...priceErrors,
  ].length === 0;

  if (direct) {
    return valid
      && imageFrontErrors.length === 0
      && imageBackErrors.length === 0;
  }
  return valid;
}

function isValidAnnounce({ announce }: AppState['form']): boolean {
  const {
    work,
    damaged,
    remark,
  } = announce;

  if (work.errors.length > 0) {
    return false;
  }

  if (!damaged.value) {
    return remark.value.length > 0 && remark.errors.length === 0;
  }

  return true;
}

function isValidState(direct: boolean, state: AppState['form']): boolean {
  const valid = isValidHolder(state) && isValidDevice(direct, state);
  if (direct) {
    return valid && isValidAnnounce(state);
  }
  return valid;
}
