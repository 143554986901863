import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneIcon from '@material-ui/icons/Done';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export interface SummaryTermsProps {
    terms: string[];
}

export default function SummaryTerms({ terms }: SummaryTermsProps) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <List>
          {terms.map((term) => (
            <ListItem key={term}>
              <ListItemIcon>
                <DoneIcon
                  style={{
                    color: green[500],
                    fontSize: 30,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={term}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
