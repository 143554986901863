import { takeLatest } from 'redux-saga/effects';
import { fetchAddress } from './address';
import { registerGMO } from './gmo';
import { register, complete } from './registration';
import { sendConfirmationEmail, confirm } from './emailConfirmation';

// eslint-disable-next-line import/prefer-default-export
export function* rootSaga() {
  yield takeLatest('FORM/FETCH_ADDRESS', fetchAddress);
  yield takeLatest('CARD/REGISTER', registerGMO);
  yield takeLatest('REGISTRATION/START', register);
  yield takeLatest('REGISTRATION/START_COMPLETION', complete);
  yield takeLatest('EMAIL_CONFIRMATION/SEND_EMAIL', sendConfirmationEmail);
  yield takeLatest('EMAIL_CONFIRMATION/CONFIRM_EMAIL', confirm);
}
