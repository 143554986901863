import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { AppState, AppActions } from '../reducers';
import { IAddress, AddressKind } from '../reducers/addresses';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 300,
    maxWidth: '100vw',
  },
}));

export type MapStateToProps = AppState['addresses'];

export type MapDispatchToProps = {
    onClose: () => AppActions;
    setAddress: (kind: AddressKind, prefecture: string, address1: string) => AppActions;
};

export interface AddressDialogProps extends MapStateToProps, MapDispatchToProps {}

export default function AddressDialog({
  open,
  kind,
  items,
  onClose,
  setAddress,
}: AddressDialogProps) {
  const classes = useStyles();

  const handleClose = () => {
    setAddress(kind, '', '');
    onClose();
  };

  const handleListItemClick = (item: IAddress) => {
    setAddress(kind, item.prefecture, item.address1);
    onClose();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="addresses-dialog-title"
      onClose={handleClose}
      className={classes.root}
    >
      <DialogTitle id="addresses-dialog-title">
        住所を選択してください
      </DialogTitle>
      <List>
        {items.map((item) => (
          <ListItem
            key={`${item.prefecture}${item.address1}`}
            button
            onClick={() => handleListItemClick(item)}
          >
            <ListItemText primary={`${item.prefecture}${item.address1}`} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
